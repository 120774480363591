import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { catchError, map, Observable, timeout } from 'rxjs';
import { ApiClientConfig } from '../api-client.config';
import { PollAPI, PollDataResponseModel, VoteUnVoteRequest } from './api.poll.model';

export class PollApi {
  private apiUrl = environment.baseURL;

  POLL = '/poll';
  RESULT = '/result';
  OVERVIEW = '/overview';
  ACTIVITIES = '/activities';
  QUESTION = '/question';
  VOTE = '/vote';
  UN_VOTE = '/un-vote';

  constructor(
    public readonly http: HttpClient,
    public config: ApiClientConfig
  ) {}

  getPollData(pollId: string): Observable<PollDataResponseModel> {
    return this.http.get<PollDataResponseModel>(`${this.apiUrl}${this.POLL}${this.QUESTION}/${pollId}`).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getPollDataToUpdate(pollId: string): Observable<PollDataResponseModel> {
    return this.http.get<any>(`${this.apiUrl}${this.POLL}${this.QUESTION}/${pollId}/fetch-data-to-update`).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  endPoll(pollId: string) {
    return this.http.delete<any>(`${this.apiUrl}${this.POLL}${this.QUESTION}/${pollId}`).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  votePoll(body: VoteUnVoteRequest) {
    return this.http.post<any>(`${this.apiUrl}${this.POLL}${this.RESULT}${this.VOTE}/${body.poll_id}`, body).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  unVotePoll(body: VoteUnVoteRequest) {
    return this.http.post<any>(`${this.apiUrl}${this.POLL}${this.RESULT}${this.UN_VOTE}/${body.poll_id}`, body).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getPollOverview(pollId: string | null): Observable<PollAPI.PollOverviewResponse> {
    return this.http
      .get<PollAPI.PollOverviewResponse>(`${this.apiUrl}${this.POLL}${this.RESULT}${this.OVERVIEW}/${pollId}`)
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getPollActivities(
    pollId: string | null,
    answerId: string | null,
    params: { pageNum: number; pageSize: number }
  ): Observable<PollAPI.PollActivitiesResponse> {
    return this.http
      .get<PollAPI.PollActivitiesResponse>(
        `${this.apiUrl}${this.POLL}${this.RESULT}${this.ACTIVITIES}/${pollId}/${answerId}`,
        { params }
      )
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response?.data as any;
          // return POLL_ACTIVITIES.data as any;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  createPoll(params: any): Observable<any> {
    return this.http.post<PollAPI.PollOverviewResponse>(`${this.apiUrl}${this.POLL}${this.QUESTION}`, params).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  editPoll(params: any, poll_id: string): Observable<any> {
    return this.http
      .patch<PollAPI.PollOverviewResponse>(`${this.apiUrl}${this.POLL}${this.QUESTION}/${poll_id}`, params)
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  voteByCoin(payload: PollAPI.VoteByCoinPayload): Observable<any> {
    const updatedPayload = {
      poll_id: payload?.pollId,
      answers: [
        {
          answer_id: payload?.answerId,
          value: payload?.value
        }
      ]
    };

    return this.http
      .post<any>(`${this.apiUrl}${this.POLL}${this.RESULT}${this.VOTE}/${payload?.pollId}`, updatedPayload)
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  updatePriorityAnswer(pollId: string, answerId: string): Observable<any> {
    let params = {};
    if (answerId) params = { answer_id: answerId };
    return this.http.put<any>(`${this.apiUrl}${this.POLL}${this.QUESTION}/${pollId}/priority-answer`, params).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  startPoll(pollId: string): Observable<any> {
    return this.http.put<any>(`${this.apiUrl}${this.POLL}${this.QUESTION}/${pollId}/start`, {}).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }
}
