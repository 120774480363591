import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiClient } from "@app/lib/api/api-client";
import { BRAND_SOCTRIP_MASTER, BRAND_SOCTRIP_STAR } from "@app/shared/models/user-badge.model";
import { environment } from "@env/environment";
import { Observable } from "rxjs";
import { UserAPI } from '@app/lib/api/user/api.user.model';

@Injectable({
    providedIn: 'root'
  })
  export class UserBadgeService {
    private api: ApiClient;
  
    constructor(private http: HttpClient) {
      this.api = new ApiClient(this.http, { responseTimeout: environment.API_TIMEOUT });
    }
    
    getUserBadge(type: any){
        return type.user_brands?.map((res: any) => {
            switch(res.brand){
                case 'SOCTRIP_STAR':
                    return BRAND_SOCTRIP_STAR.find(ele => ele.type === res.rank_brand);
                case 'SOCTRIP_MASTER':
                    return BRAND_SOCTRIP_MASTER.find(ele => ele.type === res.rank_brand);
                default:
                    return res;

            }
        });
    }

    getBrand(id: string ):Observable<UserAPI.BrandsInfo>{      
        return this.api.user.getUserBrand(id);
    }
  }