import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'roundedDecimalNumber'
})
export class RoundDecimalNumberPipe implements PipeTransform {
  transform(value: number, digits: number = 2): string {
    // Return the integer value as a string if it's an integer
    // input: 1 => output: 1
    if (Number.isInteger(value)) {
      return value.toString();
    }

    const [, decimalPart] = value.toString().split('.');

    if (decimalPart && decimalPart.length > 1) {
      // Round the decimal number to the specified number of digits
      // digits = 2; input: 1.2222 => output: 1.22
      return value.toFixed(digits);
    }

    // input: 1.1 => output: 1.1
    return value.toString();
  }
}
