import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '@app/core/services/common.service';
import { FriendService } from '@app/core/services/friend.service';
import { TranslationService } from '@app/core/services/translation.service';
import { UtilityService } from '@app/core/services/utility.service';
import { PageAPI } from '@app/lib/api/page/api.page.model';
import { CATEGORY_LIST } from '@app/lib/api/page/category-mapping';
import { FanpageService } from '@app/modules/main/fanpage/service/fanpage.service';
import {
  FANPAGE_NAME_MAX_LENGTH,
  FANPAGE_NAME_MIN_LENGTH,
  GROUP_PRIVACY_CONTEXTS,
  KEYBOARD_EVENT_CODES,
  PAGE_NUM_DEFAULT,
  PAGE_SIZE_DEFAULT,
  SEARCH_DEBOUNCE_TIME
} from '@app/shared/constant';
import { CreateGroupFanpageData } from '@app/shared/models';
import { environment } from '@env/environment';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';

interface PrivacyOption {
  label: string;
  value: string;
  icon: string;
}

@Component({
  selector: 'create-group-fanpage',
  templateUrl: './create-group-fanpage.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./create-group-fanpage.component.scss']
})
export class CreateGroupFanpageComponent implements OnInit, OnChanges {
  @Input() isLoading = false;
  @Input() isSubmitSuccess = false;
  @Input() groupStyle = false;
  @Input() visible = false;
  @Output() visibleChange = new EventEmitter();
  @Output() loadMore = new EventEmitter();
  @Output() submit = new EventEmitter();

  formGroup: FormGroup;
  fanpageNameMaxLength = FANPAGE_NAME_MAX_LENGTH;
  fanpageNameMinLength = FANPAGE_NAME_MIN_LENGTH;
  groupPrivacyOptions = Object.values(GROUP_PRIVACY_CONTEXTS);
  selectedPrivacy: PrivacyOption = this.groupPrivacyOptions[0];
  baseUrl = environment.baseURL;
  inviteFriendList: any;
  selectedFriends: any[] = [];
  selectedFriendsTemp: any[] = [];
  friendCheckboxes: boolean[] = [];
  multipleChanges = false;
  isLoadingFriends = false;
  friendListPageNum = 0;
  friendListLastPage = false;
  isLoadingMoreFriends = false;
  searchFriendKeyword = '';
  searchKeyword = '';
  searchFriends = new Subject<string>();
  loadMoreFriends = new Subject<string>();
  selectedCategories: PageAPI.PageCategory[] = [];
  initialSearchKeyword = '';

  get name() {
    return this.formGroup.get('name');
  }

  get privacy() {
    return this.formGroup.get('privacy');
  }

  get categories() {
    return this.formGroup.get('categories');
  }

  @ViewChild('friendListPanel') friendListPanel: OverlayPanel;

  //show dialog when disable dialogContent
  visibleDialogConfirm = false;
  initialFormValue: any;
  actionDialog = {
    titleHeaderGroup: 'POST.DISCARD_CREATE_GROUP',
    titleHeaderFanpage: 'POST.DISCARD_CREATE_FANPAGE',
    descriptionGroup: 'POST.DISCARD_POST_CONFIRMATION_DETAILS_GROUP',
    descriptionFanpage: 'POST.DISCARD_POST_CONFIRMATION_DETAILS_FANPAGE'
  };

  constructor(
    private formBuilder: FormBuilder,
    private friendService: FriendService,
    public utilityService: UtilityService,
    private commonService: CommonService,
    public fanpageService: FanpageService,
    private translateService: TranslationService
  ) {
    this.searchFriends.pipe(debounceTime(SEARCH_DEBOUNCE_TIME)).subscribe(value => {
      this.searchFriendKeyword = value;
      this.onSearchFriends();
    });
    this.loadMoreFriends.pipe(debounceTime(SEARCH_DEBOUNCE_TIME)).subscribe(() => {
      this.onLoadMoreFriends();
    });
  }

  ngOnInit(): void {
    this.setUpForm();

    this.translateService.language$.pipe(distinctUntilChanged()).subscribe(() => {
      if (this.fanpageService.translatedCategoryList) {
        this.fanpageService.translatedCategoryList.forEach((category: any) => {
          const categoryName = CATEGORY_LIST.get(category.id);
          const translatedName = this.translateService.getTranslation(`CATEGORIES.${categoryName}`);
          if (category.category_name !== translatedName) {
            category.category_name = translatedName;
          }
        });
      }
    });
    this.initialSearchKeyword = this.searchFriendKeyword;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['isLoading'] && this.formGroup) {
      if (this.isLoading) {
        this.formGroup.disable();
      } else {
        this.formGroup.enable();
      }
    }

    if (changes['visible'] && changes['visible'].currentValue) {
      if (!this.groupStyle) {
        this.fanpageService.getTranslatedCategories();
      }
    }
  }

  onShowDialog(): void {
    this.searchFriendKeyword = '';
    this.onSearchFriends();
    window.addEventListener('keydown', this.handleKeyDown.bind(this));
  }

  setUpForm(): void {
    const basicForm = {
      name: [
        '',
        [
          Validators.required,
          Validators.minLength(FANPAGE_NAME_MIN_LENGTH),
          Validators.maxLength(FANPAGE_NAME_MAX_LENGTH)
        ]
      ],
      notification: [false]
    };
    const customForm = this.groupStyle
      ? { privacy: ['Public', [Validators.required]] }
      : { categories: [null, [Validators.required]] };
    this.formGroup = this.formBuilder.group({ ...basicForm, ...customForm });
    this.initialFormValue = this.formGroup.getRawValue();
    this.formGroup.markAsPristine();
  }

  getImgUrl(imgUrl: string | undefined): string {
    if (imgUrl?.indexOf('/storage/files/web/') !== -1) {
      return `${this.baseUrl}${imgUrl}`;
    }
    return this.commonService.getImageUrl(imgUrl);
  }

  onBlurInput(value: string, control: AbstractControl | null): void {
    const trimmedValue = value.trim();
    control && control.setValue(trimmedValue);
  }

  onCloseDialog(): void {
    if (this.isLoading || this.isSubmitSuccess) {
      return;
    }

    if (this.hasFormChanged()) {
      this.visibleDialogConfirm = true;
    } else {
      this.resetForm();
    }

    if (this.friendListPanel) {
      this.friendListPanel.hide();
    }
    window.removeEventListener('keydown', this.handleKeyDown.bind(this));
  }

  resetForm(): void {
    this.formGroup.reset();
    this.initialFormValue = this.formGroup.getRawValue();
    this.selectedFriends = [];
    this.selectedFriendsTemp = [];
    this.searchKeyword = '';
    this.selectedPrivacy = this.groupPrivacyOptions[0];
    this.multipleChanges = false;
    this.visibleChange.emit(false);
    this.visibleDialogConfirm = false;
  }

  onChangeSearchFriends(keyword: string): void {
    this.searchFriends.next(keyword);
  }

  onSearchFriends(): void {
    this.friendListPageNum = 0;
    this.friendListLastPage = false;
    const selectedUserIds = this.selectedFriends.map(item => item.id);
    this.isLoadingFriends = true;
    this.friendService
      .getOrSearchFriend(this.searchFriendKeyword, PAGE_SIZE_DEFAULT, PAGE_NUM_DEFAULT)
      .subscribe((res: any) => {
        if (res && res.success && res.data && res.data.content) {
          const friendList = this.utilityService.removeDuplicatesFromArrayOfObjects(res.data.content, 'id');
          this.inviteFriendList = friendList;
          this.friendListLastPage = res.data.is_last_page || !res.data.content.length;
          let friendCheckboxesInit = new Array(friendList.length).fill(false);
          friendList.forEach((item: any, index: number) => {
            friendCheckboxesInit[index] = selectedUserIds.includes(item.id);
          });
          this.friendCheckboxes = friendCheckboxesInit;
        }
        this.isLoadingFriends = false;
      });
  }

  onLoadMoreFriends(): void {
    if (!this.isLoadingMoreFriends && !this.friendListLastPage && !this.isLoadingFriends) {
      this.isLoadingMoreFriends = true;
      this.friendService
        .getOrSearchFriend(this.searchFriendKeyword, PAGE_SIZE_DEFAULT, this.friendListPageNum + 1)
        .subscribe((res: any) => {
          if (res && res.success && res.data && res.data.content) {
            this.friendListPageNum++;
            this.friendListLastPage = res.data.is_last_page || !res.data.content.length;
            const oldLength = this.inviteFriendList.length;
            const friendList = this.utilityService.removeDuplicatesFromArrayOfObjects(
              [...this.inviteFriendList, ...res.data.content],
              'id'
            );
            this.inviteFriendList = friendList;
            this.friendCheckboxes = [
              ...this.friendCheckboxes,
              ...new Array(this.inviteFriendList.length - oldLength).fill(false)
            ];
          }
          this.isLoadingMoreFriends = false;
        });
    }
  }

  onSelectedFriend(friend: any, index: number, value?: boolean): void {
    this.multipleChanges = true;
    this.friendCheckboxes[index] = typeof value === 'boolean' ? value : !this.friendCheckboxes[index];
    const selectedFriendIds = this.selectedFriends.map(item => item.id);
    if (selectedFriendIds.includes(friend.id)) {
      this.selectedFriends = this.selectedFriends.filter(item => item.id !== friend.id);
      if (!this.selectedFriends.length) {
        this.multipleChanges = false;
      }
    } else {
      friend['index'] = index;
      this.selectedFriends.unshift(friend);
    }
  }

  onRemoveSelectedFriend(friend: any): void {
    this.friendCheckboxes[friend.index] = false;
    this.selectedFriends = this.selectedFriends.filter(item => item.id !== friend.id);
    this.selectedFriendsTemp = this.selectedFriends.map(item => ({ ...item }));
    if (!this.selectedFriends.length) {
      this.multipleChanges = false;
    }
  }

  onMultipleChanges(): void {
    if (this.multipleChanges) {
      this.friendCheckboxes.fill(true);
      for (let i = 0; i < this.inviteFriendList.length; i++) {
        this.onSelectedFriend(this.inviteFriendList[i], i, true);
      }
    } else {
      this.friendCheckboxes.fill(false);
      this.selectedFriends = [];
    }
  }

  onScrolledToBottom(): void {
    this.loadMoreFriends.next('');
  }

  onHideOverlayPanel(): void {
    this.selectedFriendsTemp = this.selectedFriends.map(item => ({ ...item }));
  }

  onRemoveSelectedCategory(category: PageAPI.PageCategory): void {
    this.selectedCategories = this.selectedCategories.filter(item => item.id !== category.id);
  }

  onSubmit(): void {
    if (this.formGroup.valid) {
      const formData = this.formGroup.getRawValue();
      const data: CreateGroupFanpageData = {
        name: formData.name,
        privacy: this.groupStyle ? formData.privacy?.value : '',
        categories: this.groupStyle ? [] : formData.categories,
        notification: !!formData.notification,
        inviteUserList: this.selectedFriends.map(item => item.id)
      };
      this.submit.emit(data);
      this.visibleDialogConfirm = false;
    }
  }

  handleKeyDown(event: KeyboardEvent): void {
    if (event.key === KEYBOARD_EVENT_CODES.Escape) {
      this.onCloseDialog();
    }
  }

  hasFormChanged(): boolean {
    const initialValue = this.initialFormValue;
    const currentValue = this.formGroup.getRawValue();
    const searchFieldChanged = this.searchFriendKeyword !== this.initialSearchKeyword;
    const formFieldsChanged = JSON.stringify(initialValue) !== JSON.stringify(currentValue);
    return formFieldsChanged || searchFieldChanged;
  }

  getTitleHeaderDialog() {
    return this.groupStyle ? this.actionDialog.titleHeaderGroup : this.actionDialog.titleHeaderFanpage;
  }

  getDescriptionDialog() {
    return this.groupStyle ? this.actionDialog.descriptionGroup : this.actionDialog.descriptionFanpage;
  }

  onKeepEditing(): void {
    this.visibleDialogConfirm = false;
    this.visible = true;
  }
}
