<div class="pt-2">
  <comment-content
    [commentData]="comment"
    (isShowCommentForm)="showCommentForm($event)"
    (deletedCommentId)="deleteComment($event)"
    [loginUser]="loginUser"
    [postOwnerId]="postOwnerId"
    [isWidthFull]="isWidthFull"
    [hideReply]="hideReply"
    (showReportPopup)="handleShowReportPopup(comment.id)"
    (hideComment)="handleHideComment(comment.id)"
    [isStory]="isStory"
    [isPostView]="isPostView"
    [selectedProfile]="selectedProfile"
    [pageReactionInfo]="pageReactionInfo"></comment-content>
  <div class="pt-2" *ngIf="childrenComment.length > 0 || isShowCommentForm">
    <div *ngIf="!isHideComment(comment.id)" class="flex flex-col pl-12 gap-2">
      <ng-container *ngFor="let item of childrenComment">
        <comment-content
          *ngIf="!isHideComment(item.id)"
          [isChild]="true"
          [commentData]="item"
          (isShowCommentForm)="showCommentForm($event)"
          (deletedCommentId)="deleteComment($event)"
          [loginUser]="loginUser"
          [postOwnerId]="postOwnerId"
          [isWidthFull]="isWidthFull"
          [isStory]="isStory"
          (showReportPopup)="handleShowReportPopup(item.id)"
          (hideComment)="handleHideComment(item.id)"
          [isPostView]="isPostView"
          [selectedProfile]="selectedProfile"></comment-content>
      </ng-container>
      <ng-container *ngIf="isLoadingComments" [ngTemplateOutlet]="skeleton"></ng-container>
      <ng-template #skeleton>
        <div class="py-4 px-0 rounded-lg bg-palette-base-white border-palette-gray-200 gap-4 mb-2">
          <loading-skeleton rounded></loading-skeleton>
        </div>
      </ng-template>
      <comment-form
        *ngIf="isShowCommentForm"
        [isReplyForm]="true"
        [loginUser]="loginUser"
        [isStory]="isStory"
        [replyTarget]="replyTarget"
        [selectedProfile]="selectedProfile"
        (valueComment)="addComment($event)"></comment-form>
    </div>
  </div>

  <div
    class="cursor-pointer text-sm font-semibold text-branding-primary-700 hover:underline pl-12"
    *ngIf="totalComment > childrenComment.length && !isLoadingComments"
    (click)="expandReplies()">
    {{ 'COMMENT.VIEW_MORE_COMMENTS_REPLIES' | translate }}
    <div [ngClass]="isGettingCommentReplies ? 'pi-spin pi sctr-icon-loading-02' : ''"></div>
  </div>
</div>

<lib-report-popup
  [(isLoading)]="isLoadingReport"
  [reportTarget]="getReportTarget()"
  [reportVisibility]="isShowReport"
  [reportCategories]="reportCategories$ | async"
  (closeModal)="this.isShowReport = $event"
  (confirmReport)="handleCommentReport($event)"></lib-report-popup>
