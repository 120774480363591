import { Directive, Input } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { PollService } from '@app/core/services/poll.service';
import { checkUrl, FILE_TYPE_URL } from '../models/post';

@Directive({
  selector: '[seoInfo]'
})
export class SeoInfoDirective {
  @Input() seoData: any;
  @Input() seoType: string;

  constructor(
    private metaService: Meta,
    private pollService: PollService
  ) {}

  ngOnInit(): void {
    switch (this.seoType) {
      case 'personal-profile':
        this.updateMeta(this.seoData.user_object.full_name, this.seoData.user_object.full_name, this.seoData.user_object.avatar_thumbnail_url || '');
        break;
      case 'fanpage':
        this.updateMeta(this.seoData.page_name, this.seoData.description ? this.seoData.description : this.seoData.page_name, this.seoData.avatar || '');
        break;
      case 'group':
        this.updateMeta(
          this.seoData.data.group_name, 
          this.seoData.data.description ? this.seoData.description : this.seoData.data.group_name, 
          this.seoData.data.icon_image_uri || '');
        break;
      case 'livestream-view':
        this.updateMeta(this.seoData.title, this.seoData.description ? this.seoData.description : this.seoData.title, this.seoData.thumbnail_image || '');
        break;
      case 'post':
        if (this.seoData.poll_id) {
          this.pollService.getPollData(this.seoData.poll_id).subscribe(res => {
            if (res.success) {
              return this.updateMeta(res.data.title, res.data.description ? res.data.description : res.data.title, res.data.background_image || '');
            }
          });
          return;
        }
        this.updateMeta(this.seoData.content, this.seoData.content, this.seoData.media_urls?.media[0]?.thumbnail);
        break;
      case 'stories':
        const storiesImgUrl = this.seoData[0].story_media;
        this.updateMeta('', '', this.seoData[0].story_type === 'IMAGE' ? storiesImgUrl.image_url : storiesImgUrl.video_url);
        break;
    }
  }

  updateMeta(title: string, description: string, imgUrl: string) {
    if (title && title !== '') {
      this.metaService.updateTag({ name: 'title', content: this.getFirstString(title) });
    }
    if (description && description !== '') {
      this.metaService.updateTag({ name: 'description', content: this.getFirstString(description) });
      this.metaService.updateTag({ property: 'og:description', content: this.getFirstString(description)});
      this.metaService.updateTag({ name: 'twitter:description', content: this.getFirstString(description)});
    }
    if (imgUrl) {
      this.metaService.updateTag({ name: 'image', content: this.getImageUrl(imgUrl) });
      this.metaService.updateTag({ property: 'og:image', content: this.getImageUrl(imgUrl) });
      this.metaService.updateTag({ property: 'og:image:secure_url', content: this.getImageUrl(imgUrl) });
      this.metaService.updateTag({ name: 'twitter:image', content: this.getImageUrl(imgUrl) });
      this.metaService.updateTag({ property: 'og:image:type', content: 'image/png' });
    }
  }

  getImageUrl(imgId: string) {
    return `${checkUrl(imgId, FILE_TYPE_URL.thumbnail)}.png`;
  }

  getFirstString(description: string): string {
    if (!description) return '';
    if (description.split(' ').length > 10) {
      const wordsArray = description.split(/\s+/);  // Regular expression for splitting by spaces
      return wordsArray.slice(0, 10).join(' ');
    }
    return description;
  }
}
