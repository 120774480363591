import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiClient } from '@app/lib/api/api-client';
import { Viewer } from '@app/lib/api/reactions/api.reactions.model';
import { MyReaction, ReactionInfo } from '@app/shared/models/post';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReactionsService {
  private api: ApiClient;
  constructor(private http: HttpClient) {
    this.api = new ApiClient(this.http, { responseTimeout: environment.API_TIMEOUT });
  }

  getReactionsByEntityId(entityId: string, reactionType = '', pageNum = 0, pageSize = 10): Observable<ReactionInfo[]> {
    return this.api.reactions.getReactionsByEntityId(entityId, reactionType, pageNum, pageSize);
  }

  getMyReactions(): Observable<MyReaction[]> {
    return this.api.reactions.getMyReactions();
  }

  getMyReactionsByEntityId(entityId: string): Observable<MyReaction> {
    return this.api.reactions.getMyReactionsByEntityId(entityId);
  }

  postReactions(body: any): Observable<ReactionInfo> {
    return this.api.reactions.postReactions(body);
  }

  deleteReactions(reactionId: string) {
    return this.api.reactions.deleteReactions(reactionId);
  }

  getViewer(storyId: string): Observable<Viewer[]> {
    return this.api.reactions.getViewer(storyId);
  }

  getPageReaction(pageId: string): Observable<any> {
    return this.api.reactions.getPageReaction(pageId);
  }
}
