import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FanpageInterfaceState } from './reducer';

// fanpage follower
const feature = createFeatureSelector<FanpageInterfaceState>('fanpageFollower');
export const selectFanpagePosts = createSelector(feature, state => state.fanpagePosts);
export const selectFanpagePendingPosts = createSelector(feature, state => state.fanpagePendingPosts);
export const selectPostResponse = createSelector(feature, state => state.postResponse);
export const selectIsLoading = createSelector(feature, state => state.isLoading);
export const selectIsLoadingPost = createSelector(feature, state => state.isLoadingPost);
export const selectFanpageRole = createSelector(feature, state => state.fanpageRole);
export const selectFanpageUserFollowStatus = createSelector(feature, state => state.fanpageUserFollowStatus);
export const selectIsLoadingUserRole = createSelector(feature, state => state.isLoadingUserRole);
export const selectFanpageFollower = createSelector(feature, state => state.fanpageFollower);
export const selectLoadingFollower = createSelector(feature, state => state.loadingFollower);
export const selectFanpageAboutOverview = createSelector(feature, state => state.fanpageOverview);
export const selectFanpageAboutContact = createSelector(feature, state => state.fanpageContactInfo);
export const selectFanpageInfo = createSelector(feature, state => state.fanpageInfo);
export const selectLoadingFanpageInfo = createSelector(feature, state => state.loadingFanpageInfo);
export const selectBlockList = createSelector(feature, state => state.fanpageBlockList);
export const selectFollowerRoles = createSelector(feature, state => state.followerRoles);
export const selectUserList = createSelector(feature, state => state.userList);
export const selectCategory = createSelector(feature, state => state.category);
export const selectPinedPost = createSelector(feature, state => state.pinnedPost);
export const selectIsLoadingBlockUsers = createSelector(feature, state => state.isLoadingBlockUsers);
export const selectAllCategories = createSelector(feature, state => state.allCategories);
export const selectPageProfiles = createSelector(feature, state => state.currentPageProfiles);
export const selectHasPageProfiles = createSelector(feature, state => state.hasPageProfiles);
export const selectLoadingProfiles = createSelector(feature, state => state.isLoadingProfile);
