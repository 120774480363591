<p-dialog
  header="{{ 'POLL.CREATE_POLL' | translate }}"
  [(visible)]="visiblePoll"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [dismissableMask]="true"
  (onHide)="onHideDialog()"
  styleClass="dialog-poll w-[590px] rounded-2xl border border-palette-gray-200 bg-palette-base-white shadow-sm p-0">
  <div *ngIf="visiblePoll">
    <form [formGroup]="formPollQuestion" *ngIf="formPollQuestion" class="flex flex-col gap-3">
      <div>
        <span class="text-lg text-palette-base-black"
          >{{ 'POLL.POLL_MODE' | translate }} <span class="text-palette-red-500">*</span></span
        >
        <p-dropdown
          [disabled]="isSubmitting || isEdit"
          appendTo="body"
          [options]="pollModeOptions"
          formControlName="poll_mode"
          optionLabel="type"
          (onChange)="updateLimitVotesPerTimeValidators()">
          <ng-template pTemplate="selectedItem">
            <span class="font-normal"> {{ formPollQuestion.get('poll_mode')?.value?.label | translate }}</span>
          </ng-template>
          <ng-template let-type pTemplate="item">
            <span class="font-normal"> {{ type.label | translate }}</span>
          </ng-template>
        </p-dropdown>
      </div>
      <div>
        <div class="text-lg text-palette-base-black">
          {{ 'LIVESTREAM.TITLE' | translate }} <span class="text-palette-red-500">*</span>
        </div>
        <input
          class="w-full rounded-lg"
          formControlName="title"
          type="text"
          placeholder="{{ 'LIVESTREAM.ENTER_TITLE' | translate }}"
          pInputText />
      </div>

      <div>
        <span class="text-lg text-palette-base-black"
          >{{ 'GROUP.DESCRIPTION' | translate }}
          <span class="text-palette-gray-500">({{ descriptionLength }}/120)</span></span
        >
        <textarea
          formControlName="description"
          class="w-full h-full rounded-lg"
          rows="5"
          maxlength="120"
          placeholder="{{ 'GROUP.DESCRIPTION' | translate }}"
          [autoResize]="true"
          pInputTextarea></textarea>
      </div>

      <div>
        <span class="text-lg text-palette-base-black"
          >{{ 'POLL.BACK_GROUND' | translate }}
          <span *ngIf="isPresentation()" class="text-palette-red-500">*</span></span
        >
        <ng-container *ngIf="!backgroundUrl; else showBackground">
          <div class="w-40 h-40">
            <div class="flex w-full h-full flex-col items-start">
              <label
                class="h-full w-full flex flex-col items-center justify-center gap-1 px-2 rounded-xl md:border md:border-dashed md:border-palette-gray-300 cursor-pointer bg-palette-base-white hover:border-branding-primary-600">
                <div
                  class="flex h-8 w-8 items-center justify-center p-2 bg-[#D1E9FF] rounded-[28px] border-[4px] border-solid border-palette-gray-50">
                  <i class="sctr-icon-upload-cloud-02 w-4 h-4 text-branding-primary-600"></i>
                </div>
                <div class="flex flex-col items-center gap-1">
                  <div class="hidden md:block text-sm font-medium">
                    <label for="file" class="text-branding-primary-600 cursor-pointer">{{
                      'LIBRARY.UPLOAD_IMAGE' | translate
                    }}</label>
                  </div>
                  <span class="font-normal text-xs text-palette-gray-500 text-center">{{
                    'LIBRARY.ACCEPT_JPG_PNG' | translate
                  }}</span>
                </div>
                <input accept="image/*" (change)="handleUploadBackground($event)" id="file" type="file" hidden />
              </label>
            </div>
          </div>
        </ng-container>
        <ng-template #showBackground>
          <div class="flex flex-row items-start gap-6 w-full">
            <div class="flex flex-row items-start gap-2">
              <img
                *ngIf="backgroundUrl"
                [src]="backgroundUrl"
                class="w-[200px] object-cover rounded-md"
                alt="Uploaded Image" />
            </div>
            <div class="flex flex-col gap-2">
              <span *ngIf="uploadedBackgroundName" class="text-sm text-gray-600">{{ uploadedBackgroundName }}</span>

              <div class="flex gap-2">
                <button
                  type="button"
                  [disabled]="isSubmitting"
                  class="px-4 py-2 border rounded-md text-gray-700 hover:bg-gray-100"
                  (click)="removeBackgroundImage()">
                  {{ 'COMMON.BUTTON_ACTION.REMOVE' | translate }}
                </button>

                <button
                  type="button"
                  [disabled]="isSubmitting"
                  class="px-4 py-2 border rounded-md text-gray-700 hover:bg-gray-100"
                  (click)="triggerUpload()">
                  {{ 'COMMON.BUTTON_ACTION.UPLOAD' | translate }}
                </button>
              </div>
              <span class="text-xs p-3 bg-[#F2F4F7] rounded-md text-palette-base-black">{{
                'LIBRARY.RECOMMEND_UPLOAD_SIZE' | translate
              }}</span>
            </div>

            <input type="file" id="file" hidden (change)="handleUploadBackground($event)" accept="image/*" />
          </div>
        </ng-template>
      </div>

      <ng-container *ngIf="!isPresentation()">
        <div>
          <span class="text-lg text-palette-base-black"
            >{{ 'EVENT.START_TIME' | translate }} <span class="text-palette-red-500">*</span></span
          >
          <div class="flex w-full flex-row gap-2">
            <div class="w-1/2">
              <calendar
                [disabled]="isSubmitting || (isEdit && editData?.status === pollStatus.ONGOING)"
                (datePicker)="handleCheckDatePickerStartDate($event)"
                [minStartDate]="minStartDate"
                [selectedDate]="startDate">
              </calendar>
            </div>
            <div>
              <time-picker
                class="flex"
                [disabled]="isSubmitting || (isEdit && editData?.status === pollStatus.ONGOING)"
                [(visibleTimePicker)]="startTimePickerVisibility"
                (visibleTimePickerChange)="onVisibleTimePickerChange($event, 1)"
                (pickedTime)="handleStartTime($event)"
                [setMinutes]="1"
                [isShowNowBtn]="false"
                [prefillTime]="startTime">
              </time-picker>
              <div *ngIf="isShowErrorTime" class="text-system-error-600 text-xs">
                {{ 'EVENT.SELECT_A_TIME_AFTER' | translate }}
              </div>
            </div>
          </div>
        </div>

        <div>
          <span class="text-lg text-palette-base-black"
            >{{ 'EVENT.END_TIME' | translate }} <span class="text-palette-red-500">*</span></span
          >
          <div class="flex w-full flex-row gap-2">
            <div class="w-1/2">
              <calendar
                [disabled]="isSubmitting"
                (datePicker)="handleCheckDatePickerEndDate($event)"
                [selectedDate]="endDate"
                [minStartDate]="startDate ? startDate : invalidDate">
              </calendar>
            </div>
            <div>
              <time-picker
                class="flex"
                [disabled]="isSubmitting"
                [(visibleTimePicker)]="endTimePickerVisibility"
                (visibleTimePickerChange)="onVisibleTimePickerChange($event, 2)"
                (pickedTime)="handleEndTime($event)"
                [setMinutes]="1"
                [isShowNowBtn]="false"
                [prefillTime]="endTime">
              </time-picker>
              <div *ngIf="isShowErrorEndTime" class="text-system-error-600 text-xs">
                {{ 'EVENT.MESSAGE_TIME_MUST' | translate }}
              </div>
              <div *ngIf="isEndTimeBeforeStartTime" class="text-system-error-600 text-xs">
                {{ 'EVENT.MESSAGE_TIME_BEFORE_START_TIME' | translate }}
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <div>
        <span class="text-lg text-palette-base-black"
          >{{ 'POLL.VOTING_TYPE' | translate }} <span class="text-palette-red-500">*</span></span
        >
        <p-dropdown
          appendTo="body"
          [options]="votingTypeOptions"
          formControlName="voting_type"
          optionLabel="type"
          (onChange)="updateLimitVotesPerTimeValidators()">
          <ng-template pTemplate="selectedItem">
            <span class="font-normal"> {{ formPollQuestion.get('voting_type')?.value?.label | translate }}</span>
          </ng-template>
          <ng-template let-type pTemplate="item">
            <span class="font-normal"> {{ type.label | translate }}</span>
          </ng-template></p-dropdown
        >
      </div>

      <div
        class="flex gap-2"
        *ngIf="(this.formPollQuestion.get('voting_type')?.value).value === 'NORMAL' && !isPresentation()">
        <p-inputSwitch
          [disabled]="isSubmitting || (isEdit && editData?.status === pollStatus.ONGOING)"
          [(ngModel)]="checkedLimitVote"
          [ngModelOptions]="{ standalone: true }"
          (onChange)="onChangeUnLimitVote($event)"></p-inputSwitch>
        <span> {{ 'POLL.LIMITED_VOTE' | translate }} </span>
      </div>
      <div
        *ngIf="
          checkedLimitVote && (this.formPollQuestion.get('voting_type')?.value).value === 'NORMAL' && !isPresentation()
        ">
        <div class="flex flex-row gap-2 bg-palette-gray-100 p-2">
          <div class="flex flex-col gap-1 relative">
            <span> {{ 'POLL.MAXIMUM_VOTES' | translate }} <span class="text-palette-red-500">*</span></span>
            <p-inputNumber
              [min]="1"
              [disabled]="isSubmitting || (isEdit && editData?.status === pollStatus.ONGOING)"
              class="w-full rounded-lg relative"
              placeholder="{{ 'POLL.ENTER_TIME' | translate }}"
              formControlName="pool_votes"
              [maxlength]="4" />
          </div>
        </div>
      </div>

      <div class="flex gap-2" *ngIf="(this.formPollQuestion.get('voting_type')?.value).value === 'COINS'">
        <p-inputSwitch
          [disabled]="isSubmitting || (isEdit && editData?.status === pollStatus.ONGOING)"
          [(ngModel)]="checkedLimitVoteTimes"
          [ngModelOptions]="{ standalone: true }"
          (onChange)="onChangeLimitVote($event)"></p-inputSwitch>
        <span> {{ 'POLL.LIMIT_VOTE_TIMES' | translate }} </span>
      </div>
      <div
        *ngIf="
          (checkedLimitVoteTimes && (this.formPollQuestion.get('voting_type')?.value).value === 'COINS') ||
          (!checkedLimitVote &&
            (this.formPollQuestion.get('voting_type')?.value).value === 'NORMAL' &&
            !isPresentation())
        ">
        <div class="flex flex-col gap-2 bg-palette-gray-100 p-2">
          <div class="flex flex-col gap-1">
            <span>{{ 'POLL.DURATION' | translate }} <span class="text-palette-red-500">*</span></span>
            <div class="flex flex-row gap-1">
              <p-inputNumber
                [min]="1"
                [disabled]="isEdit && editData?.status === pollStatus.ONGOING"
                class="rounded-lg input-time"
                [useGrouping]="false"
                placeholder="{{ 'POLL.ENTER_TIME' | translate }}"
                formControlName="duration"
                [maxlength]="10" />
              <p-dropdown
                [disabled]="isEdit && editData?.status === pollStatus.ONGOING"
                appendTo="body"
                formControlName="duration_type"
                [options]="durationTypeOptions"
                optionLabel="type">
                <ng-template pTemplate="selectedItem">
                  <span class="font-normal">
                    {{ formPollQuestion.get('duration_type')?.value?.label | translate }}</span
                  >
                </ng-template>
                <ng-template let-type pTemplate="item">
                  <span class="font-normal"> {{ type.label | translate }}</span>
                </ng-template></p-dropdown
              >
            </div>
          </div>
          <div class="flex flex-col gap-1 relative">
            <span> {{ 'POLL.NUMBER_OF_VOTES' | translate }} <span class="text-palette-red-500">*</span></span>
            <p-inputNumber
              [min]="1"
              [disabled]="isEdit && editData?.status === pollStatus.ONGOING"
              class="w-full rounded-lg relative"
              placeholder="{{ 'POLL.ENTER_TIME' | translate }}"
              formControlName="votes"
              [maxlength]="4" />
          </div>
        </div>
      </div>
      <span>{{ 'POLL.POLL_DETAILS_DESC' | translate }}</span>
      <div class="flex flex-row">
        <div class="flex flex-row gap-7">
          <div class="flex gap-2">
            <p-inputSwitch
              [(ngModel)]="checkedPollDetails"
              [ngModelOptions]="{ standalone: true }"
              (onChange)="onChangePollDetails($event, 'detail')"></p-inputSwitch>
            <span> {{ 'POLL.ACTIVITIES' | translate }} </span>
          </div>
          <div class="flex gap-2">
            <p-inputSwitch
              [(ngModel)]="checkedResultPercents"
              [ngModelOptions]="{ standalone: true }"
              (onChange)="onChangePollDetails($event, 'result')"></p-inputSwitch>
            <span> {{ 'POLL.RESULT' | translate }} </span>
          </div>
        </div>
      </div>

      <p-divider type="dashed"></p-divider>

      <div
        *ngIf="!isPresentation()"
        [ngClass]="(this.formPollQuestion.get('voting_type')?.value).value === 'COINS' ? 'hidden' : ''">
        <span class="text-lg text-palette-base-black"
          >{{ 'POLL.POLL_TYPE' | translate }} <span class="text-palette-red-500">*</span></span
        >
        <p-dropdown
          [disabled]="(this.formPollQuestion.get('voting_type')?.value).value === 'COINS'"
          appendTo="body"
          [options]="pollTypeOptions"
          formControlName="poll_type"
          optionLabel="type"
          (onChange)="updateLimitVotesPerTimeValidators()">
          <ng-template pTemplate="selectedItem">
            <span class="font-normal"> {{ formPollQuestion.get('poll_type')?.value?.label | translate }}</span>
          </ng-template>
          <ng-template let-type pTemplate="item">
            <span class="font-normal"> {{ type.label | translate }}</span>
          </ng-template>
        </p-dropdown>
      </div>

      <div *ngIf="isLimitVotesPerTime()">
        <span class="text-lg text-palette-base-black">{{ 'POLL.MAXIUM_OPTIONS_VOTE' | translate }} </span>
        <input
          class="w-full rounded-lg"
          formControlName="limit_votes_per_time"
          type="number"
          (keydown)="preventInvalidInput($event)"
          pInputText />

        <div *ngIf="formPollQuestion.get('limit_votes_per_time')?.errors" class="text-palette-red-600 text-xs m-1">
          {{ 'POLL.CHOICE_LIMIT' | translate }}
        </div>
      </div>

      <div>
        <div class="flex justify-between items-end">
          <span class="text-lg text-palette-base-black"
            >{{ 'POLL.ANSWER_OPTIONS' | translate }} <span class="text-palette-red-500">*</span></span
          >
          <span *ngIf="isEdit && editData?.status === pollStatus.ONGOING" class="text-lg text-palette-base-black">{{
            'POLL.HIDE_ANSWER' | translate
          }}</span>
        </div>

        <post-dialog-poll-answers
          #pollAnswers
          [isSubmitting]="isSubmitting"
          [editData]="editData"
          [isEdit]="isEdit"
          (updateValidators)="updateLimitVotesPerTimeValidators()"></post-dialog-poll-answers>
      </div>
    </form>
  </div>

  <ng-template pTemplate="footer">
    <div class="flex gap-1 justify-end items-center">
      <button
        class="custom-button bg-palette-base-white hover:bg-palette-gray-200 border-palette-gray-300 text-palette-gray-700 disabled:bg-palette-gray-100 disabled:text-palette-gray-300"
        [disabled]="isSubmitting"
        (click)="visiblePoll = false">
        {{ 'COMMON.BUTTON_ACTION.CANCEL' | translate }}
      </button>

      <button
        class="custom-button bg-palette-blue-600 hover:bg-palette-blue-700 disabled:bg-palette-blue-200 border-palette-blue-600 disabled:border-palette-blue-200 text-palette-base-white"
        type="submit"
        [disabled]="shouldDisabledSubmitBtn()"
        (click)="submit()">
        <i *ngIf="isSubmitting" class="sctr-icon-loading-02 animate-spin text-xl/5"></i>
        <span>{{ 'COMMON.BUTTON_ACTION.CONFIRM' | translate }}</span>
      </button>
    </div>
  </ng-template>
</p-dialog>
