import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable, catchError, map, timeout } from 'rxjs';
import { ApiClientConfig } from '../api-client.config';
import { WalletApiModels } from './api.wallet.models';

const ECOMMERCE_WALLET = {
  wallets: '/ecommerce-wallet/wallets',
  transactionHistories: '/ecommerce-wallet/transaction-histories'
};

const PAYMENT_PATH = {
  payments: '/payment/v2/payments'
};

export class WalletApi {
  private apiUrl: string = environment.baseURL;

  constructor(
    public http: HttpClient,
    public config: ApiClientConfig
  ) {}

  getBalance(walletId: string): Observable<WalletApiModels.WalletBalanceRes> {
    return this.http
      .get<WalletApiModels.WalletBalanceRes>(`${this.apiUrl}${ECOMMERCE_WALLET.wallets}/${walletId}`)
      .pipe(
        timeout(this.config.responseTimeout),
        map(res => {
          return res;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  topUpWallet(walletId: string, amount: number): Observable<WalletApiModels.WalletBalanceRes> {
    return this.http
      .post<WalletApiModels.WalletBalanceRes>(
        `${this.apiUrl}${ECOMMERCE_WALLET.wallets}/${walletId}/top-up?amount=${amount}`,
        {}
      )
      .pipe(
        timeout(this.config.responseTimeout),
        map(res => {
          return res;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getWalletTransactionHistories(
    walletId: string,
    pageNum = 0,
    pageSize = 10,
    status?: string,
    startTime?: number,
    endTime?: number,
    transactionCode?: string,
    sort?: string
  ): Observable<WalletApiModels.WalletTransactionHistoriesRes> {
    let params = new HttpParams().set('pageNum', pageNum).set('pageSize', pageSize);
    const filterTransactionCode =
      transactionCode && transactionCode.trim() !== '' ? `transaction_code%3D%3D${transactionCode.trim()}` : '';
    const filterStatus = status ? `status%3D%3D${encodeURI(status)}` : '';
    if (startTime) {
      params = params.set('start_time', startTime);
    }
    if (endTime) {
      params = params.set('end_time', endTime);
    }
    if (sort) {
      params = params.set('sort', sort);
    }
    return this.http
      .get<WalletApiModels.WalletTransactionHistoriesRes>(
        `${this.apiUrl}${ECOMMERCE_WALLET.transactionHistories}/${walletId}/search?filter=${filterTransactionCode}${
          filterTransactionCode && status ? ',' : ''
        }${filterStatus}`,
        { params: params }
      )
      .pipe(
        timeout(this.config.responseTimeout),
        map(res => {
          return res;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getPaymentStatus(token: string) {
    return this.http
      .get<any>(
        `${this.apiUrl}/payment/v2/payments/token/${token}`
      )
      .pipe(
        timeout(this.config.responseTimeout),
        map(res => {
          return res;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getPaymentHistoryByToken(paymentToken: string): Observable<WalletApiModels.PaymentDataRes> {
    return this.http
      .get<WalletApiModels.PaymentDataRes>(`${this.apiUrl}${PAYMENT_PATH.payments}/token/${paymentToken}`)
      .pipe(
        timeout(this.config.responseTimeout),
        map(res => {
          return res;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }
}
