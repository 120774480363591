import { Component, Input } from '@angular/core';
import { PRIVACY_OPTIONS, PROGRESS_UPDATE_INTERVAL } from '@app/shared/constant';
import { ProgressBar } from '@app/shared/models/post';
import { UserInfo } from '@app/shared/models/user';

@Component({
  selector: 'progress-post',
  templateUrl: './progress-post.component.html',
  styleUrls: ['./progress-post.component.scss']
})
export class ProgressPostComponent {
  @Input() userInfo: UserInfo;
  @Input() privacy = '';
  @Input() isLoggedUser: boolean;
  @Input() progress = 0;
  @Input() selectedProfile: any;
  progressDefault = ProgressBar.value_10;
  privacyIcon = '';

  ngOnInit() {
    let interval = setInterval(() => {
      if (this.progressDefault < ProgressBar.value_90) {
        this.progressDefault += ProgressBar.value_10;
      } else {
        clearInterval(interval);
      }
    }, PROGRESS_UPDATE_INTERVAL);
    this.privacyIcon = PRIVACY_OPTIONS.filter(item => item.privacy === this.privacy)[0].icon;
  }

  updateProgress(number: any) {
    this.progressDefault = number;
  }
}
