<p-dialog
  [styleClass]="'w-[480px] h-[440px] border-none rounded-full'"
  [(visible)]="visible"
  [modal]="!visiblePostDialog ? true : visible"
  (onHide)="onHideDialog()"
  [draggable]="false"
  [dismissableMask]="true"
  [resizable]="false">
  <ng-template pTemplate="header">
    <div class="w-full flex items-center">
      <h1 class="text-lg font-semibold">{{ 'GROUP.CHOOSE_YOUR_GROUP' | translate }}</h1>
    </div>
  </ng-template>
  <div class="flex flex-col h-[340px]">
    <div class="relative">
      <div class="absolute flex items-center pl-3.5 py-3">
        <i class="sctr-icon-search-lg text-xl text-palette-gray-500"></i>
      </div>
      <input
        [(ngModel)]="searchText"
        (keyup)="search()"
        class="w-full mb-[10px] h-[46px] pl-10 pr-3.5 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:outline-none focus:border-black"
        [placeholder]="'GROUP.SEARCH' | translate" />
    </div>
    <div (scroll)="loadMore()" class="overflow-y-auto" #scrollContainer>
      <ng-container *ngFor="let group of searchResults.length > 0 || searchText !== '' ? searchResults : listAllGroup">
        <div
          class="mx-1.5 px-2 py-[10px] my-0.5 hover:bg-gray-50 cursor-pointer rounded-md"
          (click)="handleShareGroup(group)">
          <div class="flex gap-2 items-center">
            <img
              *ngIf="group.icon_image"
              [src]="hostUrl + group.icon_image"
              class="w-6 h-6 rounded-full"
              defaultImage />
            <ng-container *ngIf="!group.icon_image">
              <p-avatar
                styleClass="w-6 h-6 bg-palette-amber-50 text-palette-amber-600 text-xs font-bold"
                shape="circle"
                [label]="group.group_name || '' | abbreviate: 'group'"></p-avatar>
            </ng-container>
            <div>
              <span class="font-medium text-base overflow-wrap-anywhere">{{ group.group_name }}</span>
            </div>
          </div>
        </div>
      </ng-container>
      <div
        *ngIf="listAllGroup.length === 0 || (searchResults.length === 0 && searchText !== '')"
        class="item-center justify-center flex">
        <p>
          {{ (listAllGroup.length === 0 ? 'GROUP.NOT_FOUND_GROUP' : 'GROUP.NO_GROUPS_MATCH_YOUR_SEARCH') | translate }}
        </p>
      </div>
      <ng-container *ngIf="isLoadingMyGroup || isLoadingGroupJoined" [ngTemplateOutlet]="skeleton"></ng-container>
      <ng-template #skeleton>
        <div class="py-4 px-0 rounded-lg bg-palette-base-white border-palette-gray-200 gap-4 mb-2">
          <loading-skeleton [loadingShareGroup]="true"></loading-skeleton>
          <loading-skeleton [loadingShareGroup]="true"></loading-skeleton>
          <loading-skeleton [loadingShareGroup]="true"></loading-skeleton>
        </div>
      </ng-template>
    </div>
  </div>
</p-dialog>
<post-dialog
  #postDialog
  [loading]="(loading$ | async) || false"
  [sharedPost]="sharedPost"
  [friendList]="friendList"
  [sharePostType]="sharePostType"
  (shareTypeChange)="onShareTypeChange($event)"
  [groupInfo]="sharedToGroupInfo"
  [userInfo]="userInfo$ | async"
  [(visible)]="visiblePostDialog"
  [selectedProfile]="selectedProfile"
  (visibleChange)="visiblePostDialogChange.emit($event)"
  (goBackToGroupDialog)="visible = true"
  (postData)="sharePost($event)"></post-dialog>
