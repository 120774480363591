import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { MediaService } from '@app/core/services/media.service';
import { FILE_EXTENSION } from '@app/shared/constant';
import Hls from 'hls.js';

@Directive({
  selector: '[appHlsVideo]',
})
export class HlsVideoDirective implements OnInit, OnDestroy {
  @Input('appHlsVideo') src = ''; // Video source URL
  @Input() videoId = '';

  private hls: Hls | null = null;
  isFirstFragment = true;
  retryCount = 0;

  constructor(
    private el: ElementRef<HTMLVideoElement>,
    private mediaService: MediaService
  ) { }

  async ngOnInit(): Promise<void> {
    const videoElement = this.el.nativeElement;
    const hlsConfig = {
      fragLoadingMaxRetry: 3,
      fragLoadingRetryDelay: 1000,
      enableWorker: true
    };

    if (Hls.isSupported()) {
      this.mediaService.checkHlsVideo(this.videoId).subscribe((res) => {
        if (res.success && res.data) {
          this.hls = new Hls(hlsConfig);
          // Load the HLS source and attach it to the video element
          this.hls.loadSource(this.src);
          this.hls.attachMedia(videoElement);
    
          this.hls.on(Hls.Events.FRAG_LOADED, (event, data) => {
            if (this.isFirstFragment) {
              this.isFirstFragment = false; // Set to false after first fragment is loaded
            }
          });
    
          this.hls.on(Hls.Events.ERROR, (event, data) => {
            if (data.details === Hls.ErrorDetails.FRAG_LOAD_ERROR && this.isFirstFragment) {
              if (this.retryCount <= 3) {
                this.retryFragment(data);
                this.retryCount += 1;
              } else {
                this.hls?.stopLoad();
                this.hls?.detachMedia();
                if (this.src.startsWith('blob:')) {
                  videoElement.src = this.src;
                } else {
                  videoElement.src = this.src + FILE_EXTENSION.video;
                }
              }
              return;
            }
    
            if (data.details === Hls.ErrorDetails.FRAG_LOAD_ERROR) {
              this.retryFragment(data);
              return;
            }
    
            this.hls?.stopLoad();
            this.hls?.detachMedia();
            if (this.src.startsWith('blob:')) {
              videoElement.src = this.src;
            } else {
              videoElement.src = this.src + FILE_EXTENSION.video;
            }
          });
        } else {
          if (this.src.startsWith('blob:')) {
            videoElement.src = this.src;
          } else {
            videoElement.src = this.src + FILE_EXTENSION.video;
          }
        }
      });
      
    } else if (videoElement.canPlayType('application/vnd.apple.mpegurl')) {
      // Fallback for browsers with native HLS support (e.g., Safari)
      videoElement.src = this.src;
    } else {
      if (this.src.startsWith('blob:')) {
        videoElement.src = this.src;
      } else {
        videoElement.src = this.src + FILE_EXTENSION.video;
      }
    }
  }

  retryFragment(data: any) {
    setTimeout(() => {
      if (this.hls) this.hls.startLoad(data.frag.sn);
    }, 1000);
  }

  ngOnDestroy(): void {
    if (this.hls) {
      this.hls.destroy();
    }
  }
}