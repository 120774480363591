import { Location } from '@angular/common';
import { Component, ElementRef, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { PollService } from '@app/core/services/poll.service';
import { ToastMessageService } from '@app/core/services/toast-message.service';
import { TranslationService } from '@app/core/services/translation.service';
import { PollInfo, PollObject, VoteUnVoteRequest } from '@app/lib/api/poll/api.poll.model';
import { PostDialogActions } from '@app/shared/components/create-post-dialog/store/post-dialog.actions';
import { selectPreviousUrl } from '@app/shared/components/create-post-dialog/store/post-dialog.selector';
import { TOAST_MESSAGE_SEVERITY_LEVELS } from '@app/shared/constant';
import { checkUrl, FILE_TYPE_URL, POLL_INFO_TYPE, POLL_MODE, POLL_PRIVACIES, POLL_STATUS, POLL_TYPE, POLL_VOTING_TYPE, Post } from '@app/shared/models/post';
import { UserInfo } from '@app/shared/models/user';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { first, interval, Subscription } from 'rxjs';

@Component({
  selector: 'post-content-poll',
  templateUrl: './post-content-poll.component.html',
  styleUrls: ['./post-content-poll.component.scss']
})
export class PostContentPollComponent implements OnInit, OnDestroy, OnChanges {
  @Input() userInfo: UserInfo;
  @Input() postData: Post;
  @Input() editedPollId: string;
  @Input() isFullScreen = false;
  @Input() isSharedPost = false;
  @Input() isPostDetail = false;
  private startTimeSubscription: Subscription | null;
  private endTimeSubscription: Subscription | null;
  data: PollObject;
  backgroundStyle: any;
  visibleDetail = false;
  intervalSubs: Subscription;
  isPollOwner = false;
  isShowEndPollDialog = false;
  isShowDownLoadQrCode = false;
  pollResult: PollInfo[] = [];
  votingType = POLL_VOTING_TYPE;
  showManageCoinDialog = false;
  selectedAnswerData: PollInfo;
  pollStatus = POLL_STATUS;
  isPollView = false;
  isOpenPopupLogin = false;
  isShowNotiPopup = false;
  isEndTimeCountdown = false;
  countdownStartTime: any;
  countdownEndTime: any;
  options: any = {
    path: 'assets/animations/Confetti.json'
  };
  pollMode = POLL_MODE;
  visiblePresentationControl = false;
  isStartPresentationPoll = false;
  isShowConfirmPollPopup = false;
  errorText = '';
  isAllowViewVoteResult = false;
  currentVotesList: { answerId: string, quantity: number }[] = [];
  noticeText = '';
  totalVotesPerTime = 0;
  infoType = '';
  infoTypeList = POLL_INFO_TYPE;
  worker!: Worker;

  constructor(
    private pollService: PollService,
    private el: ElementRef,
    private router: Router,
    private location: Location,
    private store: Store,
    private toastMessageService: ToastMessageService,
    private translationService: TranslationService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['editedPollId'] && changes['editedPollId'].currentValue) {
      if (this.postData.poll_id === changes['editedPollId'].currentValue) {
        this.pollService.getPollData(this.postData.poll_id || '').subscribe({
          next: res => {
            if (res.success) {
              this.reloadView(res.data);
            }
          }
        });
      }
    }
  }

  ngOnInit(): void {
    this.startCountdown();
    this.isPollView = this.router.url?.startsWith('/poll/') || false;
    if (this.userInfo && this.postData.user_id) {
      this.isPollOwner = this.userInfo.id === this.postData.user_id;
    }
    if (this.postData.poll_id) {
      this.pollService.getPollData(this.postData.poll_id).subscribe(res => {
        this.data = res.data;
        if (this.data) {
          this.totalVotesPerTime = this.data.limit_votes_per_time ? this.data.limit_votes_per_time : 10;
          this.translationService.language$.subscribe(() => this.getNoticeText());
          // Show notice when view detail post
          const isLoggedIn = JSON.parse(localStorage.getItem('auth_status') || '[]')?.isLoggedIn || false;
          const showedNoticeList = JSON.parse(localStorage.getItem('showedNoticePoll') || '[]');
          const isShowedNotice = showedNoticeList.includes(this.data.id);
          if (this.isPostDetail && (this.data.poll_mode !== this.pollMode.presentation && !(this.data.voting_type === this.votingType.COINS && !this.data.limit_vote_times)) && !this.isEndedPoll() && !isShowedNotice && isLoggedIn) {
            this.infoType = POLL_INFO_TYPE.pollRules;
            this.updateInfoText();
            this.isShowNotiPopup = true;
            showedNoticeList.push(this.data.id);
            localStorage.setItem('showedNoticePoll', JSON.stringify(showedNoticeList));
          }
          if (this.data.poll_mode === this.pollMode.presentation && this.data.start_date) this.isStartPresentationPoll = true;
          this.data.poll_result.forEach((el) => {
            this.currentVotesList.push({ answerId: el.answer_id, quantity: 0 });
          });
          this.pollResult = this.data.poll_result.sort((a, b) => {
            if (this.data.voting_type === this.votingType.COINS) {
              if (b.value === a.value) {
                return new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime();
              }
              return (b.value || 0) - (a.value || 0);
            } else {
              if (b.votes === a.votes) {
                return new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime();
              }
              return b.votes - a.votes;
            }
          });
          if (this.data.poll_mode === this.pollMode.default && this.data.voting_type === this.votingType.NORMAL) this.pollResult.forEach(el => el.is_voted = false);
          if (this.data.background_image) {
            const bgImg = this.data.background_image;
            const img = new Image();
            img.src = this.validUrl(bgImg);
            img.onload = () => {
              this.backgroundStyle = {
                'background-image': `url(${this.validUrl(bgImg)})`,
                'background-position': 'center',
                'background-size': 'cover',
                'background-repeat': 'no-repeat'
              };
            };
          }
          this.isAllowViewVoteResult = this.data.display_privacies.includes(POLL_PRIVACIES.resultPercents) || this.data.display_privacies.includes(POLL_PRIVACIES.result) || this.isPollOwner;
          // Detect is poll in view after loaded data
          setTimeout(() => {
            this.isScrolledIntoView();
          }, 2000);
        }
      });
    }
  }

  @HostListener('window:scroll', ['$event'])
  isScrolledIntoView() {
    const element = this.el.nativeElement.querySelector('.poll-container');
    if (element) {
      if (this.isElementInViewport(element) && this.isStartedPoll() && !this.isEndedPoll()) {
        this.refreshData();
      } else {
        this.clearPollInterval();
      }
    }
  }

  isElementInViewport(el: any) {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 && rect.top <= (window.innerHeight || document.documentElement.clientHeight) ||
      rect.bottom >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
    );
  }

  ngOnDestroy(): void {
    this.clearPollInterval();
    this.clearCountdown();
  }

  validUrl(url: string) {
    if (url.includes('.webp')) return checkUrl(url, FILE_TYPE_URL.web);
    return `${checkUrl(url, FILE_TYPE_URL.web)}.webp`;
  }

  onUpdateValue(event: number) {
    const voteInfo = {
      user_id: this.userInfo.id,
      user_data: {
        full_name: this.userInfo.full_name,
        avatar_thumbnail_url: this.userInfo.avatar_thumbnail_url
      }
    };
    const isLatestVote = this.selectedAnswerData.latest_votes.find(el => el.user_id === this.userInfo.id);
    if (!isLatestVote) {
      this.selectedAnswerData.latest_votes.push(voteInfo);
    }
    this.selectedAnswerData.value ? (this.selectedAnswerData.value += event) : (this.selectedAnswerData.value = event);
    this.refreshData();
  }

  onClickOption(selectedPoll: PollInfo, index: number) {
    if (!this.isStartedPoll()) {
      this.infoType = POLL_INFO_TYPE.notStart;
      this.updateInfoText();
      this.isShowNotiPopup = true;
      return;
    }

    if (this.data.voting_type === this.votingType.NORMAL) return;

    if (this.data.voting_type === this.votingType.COINS) {
      this.showManageCoinDialog = true;
      const selectedAnswer = this.pollResult.find(answer => answer?.answer_id === selectedPoll.answer_id);
      if (selectedAnswer) this.selectedAnswerData = selectedAnswer;
    }
  }

  clearPollInterval() {
    if (typeof Worker !== 'undefined') {
      if (this.worker) this.worker.postMessage({ action: 'stopIntervalPoll' });
    } else {
      if (this.intervalSubs && !this.intervalSubs.closed) {
        this.intervalSubs.unsubscribe();
      }
    }
  }

  refreshData() {
    this.clearPollInterval();
    if (typeof Worker !== 'undefined') {
      this.worker = new Worker(new URL('./post-content-poll.worker', import.meta.url));
      this.worker.onmessage = ({ data }) => {
        this.reloadView(data.data);
      };
      this.worker.postMessage({ action: 'startIntervalPoll', interval: 3000, isAllowInterval: !(!this.isStartedPoll() || this.isEndedPoll()), apiUrl: `${environment.baseURL}/poll/question/${this.postData.poll_id || ''}` });
    } else {
      // RXJS interval if browser not support web worker
      this.intervalSubs = interval(3000).subscribe(() => {
        if (!this.isStartedPoll() || this.isEndedPoll()) return;
        this.pollService.getPollData(this.postData.poll_id || '').subscribe({
          next: res => {
            if (res.success) {
              this.reloadView(res.data);
            }
          }
        });
      });
    }
  }

  reloadView(currentData: PollObject) {
    const currentAnswer = currentData.poll_result;
    this.pollResult.forEach((el, i) => {
      const newAnswerData = this.answerInfo(currentAnswer, el.answer_id);
      if (!newAnswerData) return;
      if (JSON.stringify(el.latest_votes) !== JSON.stringify(newAnswerData.latest_votes))
        this.pollResult[i].latest_votes = newAnswerData.latest_votes;
      if (el.rate !== newAnswerData.rate) this.pollResult[i].rate = newAnswerData.rate;
      if (el.value !== newAnswerData.value) this.pollResult[i].value = newAnswerData.value;
      if (el.votes !== newAnswerData.votes) this.pollResult[i].votes = newAnswerData.votes;
      if (el.image !== newAnswerData.image) this.pollResult[i].image = newAnswerData.image;
      if (el.name !== newAnswerData.name) this.pollResult[i].name = newAnswerData.name;
    });
    if (currentAnswer.length !== this.pollResult.length) {
      this.pollResult = currentAnswer;
    }
    this.data = { ...currentData };
  }

  isStartedPoll() {
    const startTime = new Date(this.data.start_date);
    const currentTime = new Date();
    return startTime < currentTime;
  }

  isEndedPoll() {
    const endTime = new Date(this.data.end_date);
    const currentTime = new Date();
    return this.data.end_date && (endTime < currentTime || this.data.status === this.pollStatus.END);
  }

  getStartTime() {
    return {
      time: moment(this.data.start_date).format('HH:mm'),
      day: moment(this.data.start_date).format('DD'),
      month: moment(this.data.start_date).format('MM'),
      year: moment(this.data.start_date).format('YYYY')
    };
  }

  isExpiredToday() {
    const endDate = moment(this.data.end_date).format('YYYY-MM-DD');
    const currentDate = moment(new Date()).format('YYYY-MM-DD');
    return endDate === currentDate;
  }

  getEndTime() {
    return {
      time: moment(this.data.end_date).format('HH:mm'),
      day: moment(this.data.end_date).format('DD'),
      month: moment(this.data.end_date).format('MM'),
      year: moment(this.data.end_date).format('YYYY')
    };
  }

  handleImageError(event: any, imgId?: string) {
    event.target.src = 'assets/images/default_user_avatar.png';
    if (imgId) {
      setTimeout(() => {
        event.target.src = this.validUrl(imgId);
      }, 1000);
    }
  }

  convertTime(time: number, type: 'day' | 'minute' | 'hour') {
    if (type === 'day') return time / (60 * 60 * 24);
    if (type === 'hour') return time / (60 * 60);
    if (type === 'minute') return time / 60;
    return null;
  }

  reachMaxVoteMess() {
    const durationTypeText = this.translationService.getTranslation(`POLL.TIME_${this.data.limit_vote_times?.duration_type.toUpperCase()}`).toLowerCase();
    const duration = `${this.convertTime(this.data.limit_vote_times?.duration || 0, this.data.limit_vote_times?.duration_type || 'day')} `;

    return this.translationService.getTranslationWithParams('POLL.REACH_MAX_NUMBER_VOTE', { number: this.data.limit_vote_times?.votes, duration: `${duration} ${durationTypeText}` })
  }

  votePoll() {
    this.clearPollInterval();
    const answers = this.currentVotesList.filter(el => el.quantity !== 0).map(el => {
      return { answer_id: el.answerId, quantity: el.quantity };
    });
    const body: VoteUnVoteRequest = {
      poll_id: this.data.id,
      answers: answers
    };

    const voteInfo = {
      user_id: this.userInfo.id,
      user_data: {
        full_name: this.userInfo.full_name,
        avatar_thumbnail_url: this.userInfo.avatar_thumbnail_url
      }
    };
    this.pollResult.forEach(el => {
      if (el.is_voted) {
        el.votes += 1;
        const isLatestVote = el.latest_votes.find(el => el.user_id === this.userInfo.id);
        if (!isLatestVote) {
          el.latest_votes.push(voteInfo);
        }
      }
    });

    this.pollService.votePoll(body).subscribe({
      next: (res) => {
        if (!res.success) {
          switch (res.error.code) {
            case 9524: // 9524: Reach maximum vote error code
              this.infoType = POLL_INFO_TYPE.reachMaxVote;
              this.updateInfoText();
              this.isShowNotiPopup = true;
              break;
            case 9542: // 9542: Reach vote limit error code
              this.infoType = POLL_INFO_TYPE.reachLimitVote;
              this.updateInfoText();
              this.isShowNotiPopup = true;
              break;
            default:
              this.toastMessageService.addToastMessage(TOAST_MESSAGE_SEVERITY_LEVELS.error, 'POLL.FAILED_TO_VOTE');
              break;
          }
        } else {
          this.toastMessageService.addToastMessage(TOAST_MESSAGE_SEVERITY_LEVELS.success, 'POLL.VOTE_SUCCESSFULLY');
        }
        this.pollResult.forEach(el => el.is_voted = false);
        this.currentVotesList.forEach(el => {
          el.quantity = 0;
        });
        this.refreshData();
      },
      error: () => {
        this.pollResult.forEach(el => el.is_voted = false);
        this.currentVotesList.forEach(el => {
          el.quantity = 0;
        });
        this.toastMessageService.addToastMessage(TOAST_MESSAGE_SEVERITY_LEVELS.error, 'POLL.FAILED_TO_VOTE');
        this.refreshData();
      }
    });
    this.isShowConfirmPollPopup = false;
  }

  endPoll() {
    this.pollService.endPoll(this.data.id).subscribe(res => {
      if (res.success) {
        this.isShowEndPollDialog = false;
        this.visiblePresentationControl = false;
        this.data.status = this.pollStatus.END;
      }
    });
  }

  getVoteRateWidth(rate: number) {
    return {
      'width': `${rate * 100}%`
    };
  }

  onVisibleChange(event: boolean) {
    this.visibleDetail = event;
    if (!event) {
      this.pollService.getPollData(this.postData.poll_id || '').subscribe({
        next: (res) => {
          this.data = res.data;
          if (this.data) {
            this.reloadView(res.data);
            this.refreshData();
          }
        },
        error: () => {
          this.refreshData();
        }
      });
    }
  }

  showOverviewPopup() {
    const isLoggedIn = JSON.parse(localStorage.getItem('auth_status') || '[]')?.isLoggedIn || false;
    if (isLoggedIn) {
      this.clearPollInterval();
      this.visibleDetail = true;
    }
  }

  quantityView(quantity: number) {
    if (quantity >= 1000000) {
      return (quantity / 1000000).toFixed(1) + 'm';
    } else if (quantity >= 10000) {
      return (quantity / 1000).toFixed(0) + 'k';
    } else {
      return quantity.toString();
    }
  }

  isAnyImageInList() {
    return !!this.pollResult.find(el => el.image !== '');
  }

  withoutTop3List() {
    let sortedArr = [];
    if (this.data.voting_type === this.votingType.COINS)
      sortedArr = this.pollResult.sort((a, b) => (b.value || 0) - (a.value || 0));
    sortedArr = this.pollResult.sort((a, b) => b.votes - a.votes);
    return this.backgroundStyle ? sortedArr.slice(3) : sortedArr;
  }

  private startCountdown() {
    this.updateStartCountdown();
    this.startTimeSubscription = interval(1000).subscribe(() => {
      this.updateStartCountdown();
    });
  }

  private updateStartCountdown() {
    const now = new Date();
    const startDate = new Date(this.data?.start_date || '');

    if (isNaN(startDate.getTime())) {
      this.countdownStartTime = '';
      return;
    }

    const timeDiff = startDate.getTime() - now.getTime();

    if (timeDiff <= 0) {
      this.countdownStartTime = '00:00:00';
      this.clearStartCountdown();
      this.startEndCountdown();
    } else {
      this.countdownStartTime = this.formatTimeDiff(timeDiff);
    }
  }

  private startEndCountdown() {
    this.isEndTimeCountdown = true;
    this.updateEndCountdown();
    this.endTimeSubscription = interval(1000).subscribe(() => {
      this.updateEndCountdown();
    });
  }

  private updateEndCountdown() {
    const now = new Date();
    const endDate = new Date(this.data?.end_date || '');

    if (isNaN(endDate.getTime())) {
      this.countdownEndTime = '';
      return;
    }

    const timeDiffEnd = endDate.getTime() - now.getTime();

    if (timeDiffEnd <= 0) {
      this.countdownEndTime = '00:00:00';
      this.clearEndCountdown();
    } else {
      this.countdownEndTime = this.formatTimeDiff(timeDiffEnd);
    }
  }

  private formatTimeDiff(timeDiff: number) {
    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDiff / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((timeDiff / (1000 * 60)) % 60);
    const seconds = Math.floor((timeDiff / 1000) % 60);
    return {
      days: days,
      time: `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
        .toString()
        .padStart(2, '0')}`
    };
  }

  private clearCountdown() {
    this.clearStartCountdown();
    this.clearEndCountdown();
  }

  private clearStartCountdown() {
    if (this.startTimeSubscription) {
      this.startTimeSubscription.unsubscribe();
      this.startTimeSubscription = null;
    }
  }

  private clearEndCountdown() {
    if (this.endTimeSubscription) {
      this.endTimeSubscription.unsubscribe();
      this.endTimeSubscription = null;
    }
  }

  expandPollView(minimize = false) {
    if (minimize) {
      this.store
        .select(selectPreviousUrl)
        .pipe(first())
        .subscribe(previousUrl => {
          if (previousUrl?.length) return this.location.back();
          if (!previousUrl?.length) {
            setTimeout(() => {
              return this.router.navigate([`/post/${this.postData.id}`]);
            }, 0);
          }
        });
    }
    this.store.dispatch(PostDialogActions.onGetPreviousUrl({ previousUrl: this.router.url }));
    return this.router.navigate([`/poll/${this.postData.id}`]);
  }

  startPresentationPoll() {
    this.clearPollInterval();
    this.pollService.startPoll(this.data.id).subscribe({
      next: (res) => {
        if (res.success) this.isStartPresentationPoll = true;
        this.refreshData();
      },
      error: () => {
        this.refreshData();
      }
    });
  }

  updatePriorityAnswer(answerId: string) {
    if (this.data.priority_answer_id === answerId) return;
    this.clearPollInterval();
    if (!this.data.start_date) {
      this.pollService.startPoll(this.data.id).subscribe({
        next: (res) => {
          if (res.success) {
            this.pollService.updatePriorityAnswer(this.data.id, answerId).subscribe({
              next: (res) => {
                if (res.success) {
                  this.refreshData();
                  this.data.priority_answer_id = answerId;
                }
              },
              error: () => {
                this.refreshData();
              }
            });
          }
        },
        error: () => {
          this.refreshData();
        }
      });
    } else {
      this.pollService.updatePriorityAnswer(this.data.id, answerId).subscribe({
        next: (res) => {
          if (res.success) {
            this.refreshData();
            this.data.priority_answer_id = answerId;
          }
        },
        error: () => {
          this.refreshData();
        }
      });
    }
  }

  answerInfo(answerList: PollInfo[], answerId: string) {
    return answerList.find(el => el.answer_id === answerId);
  }

  turnToWaitingScreen() {
    if (!this.data.priority_answer_id) return;
    this.clearPollInterval();
    this.pollService.updatePriorityAnswer(this.data.id, '').subscribe({
      next: (res) => {
        if (res.success) {
          this.refreshData();
          delete this.data.priority_answer_id;
        }
      },
      error: () => {
        this.refreshData();
      }
    });
  }

  votePresentationMode(answerId: string) {
    const isLoggedIn = JSON.parse(localStorage.getItem('auth_status') || '[]')?.isLoggedIn || false;
    if (!isLoggedIn) return;
    if (this.data.voting_type === this.votingType.COINS) {
      this.showManageCoinDialog = true;
      const selectedAnswer = this.pollResult.find(answer => answer?.answer_id === answerId);
      if (selectedAnswer) this.selectedAnswerData = selectedAnswer;
      return;
    }

    const body: VoteUnVoteRequest = {
      poll_id: this.data.id,
      answers: [
        {
          answer_id: answerId
        }
      ]
    };
    const currentAnswerIndex = this.pollResult.findIndex(el => el.answer_id === answerId);
    if (currentAnswerIndex === -1) {
      this.refreshData();
      return;
    }
    this.pollResult[currentAnswerIndex].is_voted = true;
    if (this.data.poll_mode === this.pollMode.presentation) {
      this.pollService.votePoll(body).subscribe({
        next: () => {
          this.refreshData();
        },
        error: () => {
          this.refreshData();
        }
      });
    }
  }

  updateInfoText() {
    switch (this.infoType) {
      case POLL_INFO_TYPE.notStart:
        this.errorText = this.translationService.getTranslation('POLL.NOT_STARTED_POLL_ERROR');
        break;
      case POLL_INFO_TYPE.reachLimitVote:
        this.errorText = this.translationService.getTranslation('POLL.REACH_VOTE_LIMIT');
        break;
      case POLL_INFO_TYPE.reachMaxVote:
        this.errorText = this.reachMaxVoteMess();
        break;
      case POLL_INFO_TYPE.pollRules:
        this.errorText = this.noticeText;
        break;
    }
  }

  currentVoteQuantity(answerId: string) {
    return this.currentVotesList.find(el => el.answerId === answerId)?.quantity || 0;
  }

  updateVote(answerId: string, isAddVote: boolean) {
    const isLoggedIn = JSON.parse(localStorage.getItem('auth_status') || '[]')?.isLoggedIn || false;
    if (!isLoggedIn) return;
    const voteIndex = this.currentVotesList.findIndex(el => el.answerId === answerId);
    if (voteIndex !== -1) {
      !isAddVote && this.currentVotesList[voteIndex].quantity > 0 ? this.currentVotesList[voteIndex].quantity -= 1 : this.currentVotesList[voteIndex].quantity += 1;
    }
  }

  totalSelectedVote() {
    return this.currentVotesList.reduce((sum, item) => sum + item.quantity, 0);
  }

  isDisabledVoteButton() {
    const isReachLimit = this.totalSelectedVote() === this.totalVotesPerTime;
    const isVotedInSingleMode = this.totalSelectedVote() >= 1 && this.data.poll_type === POLL_TYPE.SINGLE;
    return isReachLimit || isVotedInSingleMode;
  }

  getNoticeText() {
    // const voteTime = this.data.pool_votes === 0 ? this.data.limit_vote_times?.votes : this.data.pool_votes;
    // const voteTimeText = this.translationService.getTranslationWithParams((voteTime || 0) > 1 ? 'POLL.NUMBER_TIMES' : 'POLL.NUMBER_TIME', { number: voteTime })
    // const duration = `${this.convertTime(this.data.limit_vote_times?.duration || 0, this.data.limit_vote_times?.duration_type || 'day')}`;
    // const durationTypeText = this.translationService.getTranslation(`POLL.TIME_${this.data.limit_vote_times?.duration_type.toUpperCase()}${Number(duration) > 1 ? 'S' : ''}`).toLowerCase();
    // const intervalText = this.data.pool_votes === 0 ? `/${duration} ${durationTypeText}` : '';
    // let totalVotes = this.translationService.getTranslationWithParams((this.totalVotesPerTime || 0) > 1 ? 'POLL.NUMBER_OPTIONS' : 'POLL.NUMBER_OPTION', { number: this.totalVotesPerTime });

    // const limitVoteNotice = this.translationService.getTranslationWithParams('POLL.TIME_LIMIT_NOTICE', { times: voteTimeText, interval: intervalText });
    // const choiceLimitNotice = this.translationService.getTranslationWithParams('POLL.VOTE_LIMIT_NOTICE', { numberVotes: totalVotes });

    // if (this.data.poll_mode === this.pollMode.presentation || this.data.voting_type === this.votingType.COINS || !this.totalVotesPerTime) {
    //   this.noticeText = this.data.limit_vote_times ? limitVoteNotice : '';
    // } else {
    //   this.noticeText = `${limitVoteNotice}${choiceLimitNotice}`;
    // }

    const totalVotes = this.translationService.getTranslationWithParams((this.totalVotesPerTime || 0) > 1 ? 'POLL.NUMBER_OPTIONS' : 'POLL.NUMBER_OPTION', { number: this.totalVotesPerTime });
    this.noticeText = this.translationService.getTranslationWithParams('POLL.VOTE_TEMPORARY_NOTICE', { numberVotes: totalVotes });
  }
}