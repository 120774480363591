<p-card class="card-progress-custom" [styleClass]="'shadow-sm'">
  <div class="flex justify-between px-4 pt-4">
    <div class="flex items-center space-x-3">
      <div class="relative w-10">
        <avatar
          *ngIf="userInfo.avatar_thumbnail_url; else isAvatarName"
          [userInfo]="userInfo"
          [selectedProfile]="selectedProfile"></avatar>
        <!-- Avatar for Fanpage  -->
        <ng-template #isAvatarName>
          <div class="w-10 rounded-full ring-2 ring-white h-9">
            <div class="rounded-full p-0.5 ring-2 ring-palette-orange-500">
              <div class="rounded-full ring-2 ring-transparent w-9 h-9">
                <p-avatar
                  styleClass="w-full h-full rounded-full bg-palette-blue-50 text-palette-blue-600 font-bold text-lg"
                  [label]="userInfo.full_name | abbreviate: 'fanpage'">
                </p-avatar>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
      <div class="flex flex-col">
        <div class="flex items-center gap-1.5">
          <div class="flex items-center text-base text-palette-gray-700 font-semibold cursor-pointer gap-2">
            <div class="flex max-w-[15rem]">
              <!--Display the name of the selected profile-->
              <span *ngIf="selectedProfile && selectedProfile?.id !== userInfo?.id; else userName" class="truncate">{{
                selectedProfile?.page_name
              }}</span>
              <ng-template #userName>
                <span class="truncate">{{ userInfo.full_name }}</span>
              </ng-template>
            </div>
            <div class="font-medium text-sm text-palette-gray-500">• {{ 'POST.POSTING' | translate }}</div>
          </div>
        </div>
        <div class="flex items-center space-x-2 py-0.5">
          <i class="{{ privacyIcon }} text-base"></i>
          <span
            class="text-xs font-semibold text-palette-gray-400 hover:text-palette-gray-400 cursor-pointer hover:underline">
            {{ 'POST.JUST_NOW' | translate }}
          </span>
        </div>
      </div>
    </div>
  </div>
  <ng-template pTemplate="content" class="pt-0">
    <div class="p-4 flex w-full items-center">
      <p-progressBar
        [value]="progress || progressDefault"
        class="custom-progressBar"
        [style]="{ height: '8px' }"
        [showValue]="false"></p-progressBar>
      {{ (progress || progressDefault) + '%' }}
    </div>
  </ng-template>
</p-card>
