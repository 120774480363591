export interface UserBadge {
  type: string,
  icon: string,
  color: string,
  label: string
}

export const BRAND_SOCTRIP_STAR = [
    {
      type: 'DIAMOND',
      icon: 'assets/icons/badge/diamond_star.svg',
      color: '#7A5AF8',
      label: 'Diamond Star'
    },
    {
      type: 'GOLD',
      icon: 'assets/icons/badge/gold_star.svg',
      color: '#FABC05',
      label: 'Gold Star'
    },
    {
      type: 'SILVER',
      icon: 'assets/icons/badge/silver_star.svg',
      color: '#2970FF',
      label: 'Silver Star'
    },
    {
      type: 'BRONZE',
      icon: 'assets/icons/badge/bronze_star.svg',
      color: '#879DC2',
      label: 'Bronze Star'
    }
];

export const BRAND_SOCTRIP_MASTER = [
    {
      type: 'DIAMOND',
      icon: 'assets/icons/badge/diamond_master.svg',
      color: '#27B6FE',
      label: 'Diamond Master'
    },
    {
      type: 'GOLD',
      icon: 'assets/icons/badge/gold_master.svg',
      color: '#FABC05',
      label: 'Gold Master'
    },
    {
      type: 'SILVER',
      icon: 'assets/icons/badge/silver_master.svg',
      color: '#879DC2',
      label: 'Silver Master'
    },
    {
      type: 'BRONZE',
      icon: 'assets/icons/badge/bronze_master.svg',
      color: '#F38F33',
      label: 'Bronze Master'
    }
];