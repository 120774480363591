<!-- Default banner -->

<!-- <div *ngIf="!imgPanel1?.length; else image1">
  <div class="w-full aspect-[8/3]"><p-skeleton height="100%"></p-skeleton></div>
</div>
<div *ngIf="!imgPanel2?.length; else image2">
  <div class="w-full aspect-square"><p-skeleton height="100%"></p-skeleton></div>
</div> -->

<!-- Event banner -->
<div class="flex flex-col items-center" *ngIf="!isLoadingTopRight">
  <a *ngIf="topRightBanner.length" [href]="topRightBanner[currentTopRightIndex].url">
    <img class="aspect-[282/500] rounded-md" [src]="validUrl(topRightBanner[currentTopRightIndex].medias[0].id)" />
  </a>
  <img *ngIf="!topRightBanner.length" src="assets/images/default-top-right-banner.png" alt="">
  <div class="flex gap-1 mt-2" *ngIf="topRightBanner.length > 1">
    <span class="h-1.5 cursor-pointer rounded-lg"
      [ngClass]="i === currentTopRightIndex ? 'bg-palette-blue-600 w-6' : 'bg-palette-gray-300 w-4'"
      *ngFor="let item of [].constructor(topRightBanner.length); let i = index"
      (click)="goToSlideTopRightBanner(i)"></span>
  </div>
</div>

<p-skeleton *ngIf="isLoadingTopRight" styleClass="aspect-[282/500] w-full h-auto"></p-skeleton>

<div class="flex flex-col gap-3">
  <ng-container>
    <!-- <div class="text-lg font-semibold">
      {{ 'HOME.SPONSORED_BY' | translate }}
    </div> -->

    <!-- Default banner -->
    <!-- <div class="flex flex-col gap-3">
      <ng-container [ngTemplateOutlet]="sponsored"
        [ngTemplateOutletContext]="{ data: dataSponsored1, isLoading: isLoadingSponsored }">
      </ng-container>
      <ng-container [ngTemplateOutlet]="sponsored"
        [ngTemplateOutletContext]="{ data: dataSponsored2, isLoading: isLoadingSponsored }">
      </ng-container>
    </div> -->

    <!-- Event banner -->
    <div class="flex flex-col items-center">
      <div class="flex w-full justify-between">
        <ng-container *ngIf="!isLoadingSponsored">
          <a *ngIf="sponsorBanner[currentSponsorImageIndex()]" [href]="sponsorBanner[currentSponsorImageIndex()].url"
            class="cursor-pointer">
            <img [src]="validUrl(sponsorBanner[currentSponsorImageIndex()].medias[0].id)" class="w-[133px] h-[182px]" />
          </a>
          <img *ngIf="!sponsorBanner.length" [src]="'assets/images/default-sponsor-banner-1.png'"
            class="w-[133px] h-[182px]" />
          <a *ngIf="sponsorBanner[currentSponsorImageIndex(true)]"
            [href]="sponsorBanner[currentSponsorImageIndex(true)].url">
            <img [src]="validUrl(sponsorBanner[currentSponsorImageIndex(true)].medias[0].id)"
              class="w-[133px] h-[182px]" />
          </a>
          <img *ngIf="!sponsorBanner.length" [src]="'assets/images/default-sponsor-banner-2.png'"
            class="w-[133px] h-[182px]" />
        </ng-container>
        <p-skeleton *ngIf="isLoadingSponsored" styleClass="w-[133px] h-[182px]"></p-skeleton>
        <p-skeleton *ngIf="isLoadingSponsored" styleClass="w-[133px] h-[182px]"></p-skeleton>
      </div>
      <div class="flex gap-1 mt-3" *ngIf="sponsorBanner.length">
        <span class="h-1.5 cursor-pointer rounded-lg "
          [ngClass]="i === currentSponsorIndex ? 'bg-palette-blue-600 w-6' : 'bg-palette-gray-300 w-4'"
          *ngFor="let item of [].constructor(divideAndRoundUp(sponsorBanner.length)); let i = index"
          (click)="goToSlideSponsor(i)"></span>
      </div>
    </div>
  </ng-container>

  <div class="flex flex-col gap-3">
    <div class="aboutus flex gap-[6px] items-center">
      <div class="text-xs cursor-pointer" [routerLink]="'/help-center/about-us'">{{ 'HOME.ABOUT_US' | translate }}</div>
      <i class="pi pi-circle-fill text-[5px] text-[#667085]"></i>
      <div class="text-xs cursor-pointer" [routerLink]="'/help-center/term'">{{ 'HOME.TERMS' | translate }}</div>
      <i class="pi pi-circle-fill text-[5px] text-[#667085]"></i>
      <div class="text-xs cursor-pointer" [routerLink]="'/help-center/privacy'">
        {{ 'COMMON.PRIVACY.PRIVACY' | translate }}
      </div>
      <i class="pi pi-circle-fill text-[5px] text-[#667085]"></i>
      <a href="{{partnerCenter}}">
        <div class="text-xs cursor-pointer">
          {{ 'HEADER.PARTNER_CENTER' | translate }}
        </div>
      </a>
    </div>
    <div class="flex gap-4">
      <div class="flex gap-1">
        <i class="sctr-icon-globe-01 text-xs mt-1"></i>
        <div class="text-sm cursor-pointer" (click)="showLanguageSetting()">
          {{ currentLanguage | translate }}
        </div>
      </div>
      <div class="text-sm">
        {{ 'COPYRIGHT_TEXT' | translate: { currentYear: currentYear, companyName: companyName } }}
      </div>
    </div>
  </div>
</div>

<ng-template #sponsored let-data="data" let-isLoading="isLoading">
  <ng-container *ngIf="!isLoading">
    <div class="flex gap-3" [ngClass]="{ 'cursor-pointer': !!data.destination_url }" *ngIf="data"
      (click)="handleNavigate(data, adsCategory.adsSponsoredBy)">
      <div class="w-28 min-w-[112px] h-28 rounded-md bg-palette-gray-50 overflow-clip">
        <img (load)="onImageLoad(data, adsCategory.adsSponsoredBy)" defaultImage
          class="w-full h-full max-w-[112px] aspect-square" [src]="baseUrl + data.imageUrl" />
      </div>
      <div class="gap-1">
        <h1 class="text-palette-gray-900 font-semibold text-base line-clamp-2" [title]="data?.title | translate">
          {{ data?.title | translate }}
        </h1>
        <span class="text-palette-gray-500 font-normal text-sm line-clamp-3" [title]="data?.description | translate">
          {{ data?.description | translate }}
        </span>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="isLoading">
    <loading-skeleton></loading-skeleton>
  </ng-container>
</ng-template>

<ng-template #image1>
  <div class="w-full aspect-[8/3]" [ngClass]="{ 'cursor-pointer': !!dataPanel1?.destination_url }"
    (click)="handleNavigate(dataPanel1, adsCategory.adsPanel1)">
    <img (load)="onImageLoad(dataPanel1, adsCategory.adsPanel1)" defaultImage
      class="w-full object-cover rounded max-h-[155px]" [src]="baseUrl + imgPanel1" />
  </div>
</ng-template>

<ng-template #image2>
  <div class="w-full aspect-square" [ngClass]="{ 'cursor-pointer': !!dataPanel2?.destination_url }"
    (click)="handleNavigate(dataPanel2, adsCategory.adsPanel2)">
    <img (load)="onImageLoad(dataPanel2, adsCategory.adsPanel2)" defaultImage
      class="w-full max-h-full object-cover rounded" [src]="baseUrl + imgPanel2" />
  </div>
</ng-template>