import { ADS_CREATE } from '@app/shared/constant';
import { environment } from '@env/environment';

export const AD_ACCOUNT_TYPES = {
  personalAccount: 'PERSONAL_ACCOUNT',
  myBusinessAccount: 'MY_BUSINESS_ACCOUNT',
  sharedBusinessAccount: 'SHARED_BUSINESS_ACCOUNT'
};

export const BUSINESS_VERIFICATION_STATE = {
  notVerified: 'NOT_VERIFIED',
  verifying: 'VERIFYING',
  pending: 'PENDING',
  verified: 'VERIFIED',
  approved: 'APPROVED',
  rejected: 'REJECTED'
};

export const SHARE_BUSINESS_ACCOUNT_ROLES = {
  admin: 'ADMIN',
  advertiser: 'MEMBER'
};

export const PAYMENT_TRANSACTION_STATUS = {
  requested: 'REQUESTED',
  inReview: 'IN_REVIEW',
  accepted: 'ACCEPTED',
  success: 'SUCCESS',
  rejected: 'REJECTED',
  failed: 'FAILED',
  waitingForRefund: 'WAITING_FOR_REFUND'
};

export const TOP_UP_CALLBACK_RESULTS = {
  success: 'SUCCESS',
  failed: 'FAILED'
};

export const BUSINESS_VERIFICATION_ATTACHMENT_LABEL = {
  businessLicense: 'Business license',
  logo: 'Logo'
};

export const MAX_BUSINESS_ACCOUNTS = 5;

export const MAX_SHARED_BUSINESS_ACCOUNTS = 5;

export const AD_ACCOUNT_SIDE_BAR_CONTEXTS = [
  // TODO: Unhide after dashboard UI is available
  // {
  //   url: '/dashboard',
  //   icon: 'sctr-icon-bar-chart-square-01',
  //   label: 'ADVERTISING.SIDEBAR_ITEMS.DASHBOARD',
  //   supportAccountTypes: [AD_ACCOUNT_TYPES.personalAccount, AD_ACCOUNT_TYPES.myBusinessAccount]
  // },
  {
    url: '/campaign',
    icon: 'sctr-icon-target-04',
    label: 'ADVERTISING.CAMPAIGN.CAMPAIGN',
    supportAccountTypes: Object.values(AD_ACCOUNT_TYPES),
    visible: true
  },
  {
    url: '/payment',
    icon: 'sctr-icon-bank-note-01',
    label: 'ADVERTISING.SIDEBAR_ITEMS.PAYMENT',
    supportAccountTypes: [AD_ACCOUNT_TYPES.personalAccount, AD_ACCOUNT_TYPES.myBusinessAccount],
    visible: true
  },
  {
    url: '/business-information',
    icon: 'sctr-icon-briefcase-01',
    label: 'ADVERTISING.SIDEBAR_ITEMS.BUSINESS_INFORMATION',
    supportAccountTypes: [AD_ACCOUNT_TYPES.myBusinessAccount],
    visible: true
  },
  {
    url: '/fanpage-management',
    icon: 'sctr-icon-laptop-01',
    label: 'ADVERTISING.SIDEBAR_ITEMS.FANPAGE_MANAGEMENT',
    supportAccountTypes: [AD_ACCOUNT_TYPES.myBusinessAccount, AD_ACCOUNT_TYPES.sharedBusinessAccount],
    visible: true
  },
  {
    url: '/account-management',
    icon: 'sctr-icon-user-circle',
    label: 'ADVERTISING.SIDEBAR_ITEMS.AD_ACCOUNT_MANAGEMENT',
    supportAccountTypes: [AD_ACCOUNT_TYPES.myBusinessAccount],
    visible: true
  },
  {
    url: '/data-source',
    icon: 'sctr-icon-share-07',
    label: 'ADVERTISING.SIDEBAR_ITEMS.DATA_SOURCE',
    supportAccountTypes: [AD_ACCOUNT_TYPES.personalAccount, AD_ACCOUNT_TYPES.myBusinessAccount],
    visible: !environment.production
  },
  {
    url: '/audience',
    icon: 'sctr-icon-users-01',
    label: 'ADVERTISING.SIDEBAR_ITEMS.AUDIENCE',
    supportAccountTypes: [AD_ACCOUNT_TYPES.personalAccount, AD_ACCOUNT_TYPES.myBusinessAccount],
    visible: !environment.production
  }
];

export const BUSINESS_ACCOUNT_POLICY_CHECKLIST = {
  identify: {
    value: 'Verify business’s identify',
    title: 'ADVERTISING.BUSINESS_VERIFICATION.POLICY_CHECKLIST.IDENTIFY_TITLE',
    description: 'ADVERTISING.BUSINESS_VERIFICATION.POLICY_CHECKLIST.IDENTIFY_DESCRIPTION'
  },
  activity: {
    value: 'Verify business activity',
    title: 'ADVERTISING.BUSINESS_VERIFICATION.POLICY_CHECKLIST.ACTIVITY_TITLE',
    description: 'ADVERTISING.BUSINESS_VERIFICATION.POLICY_CHECKLIST.ACTIVITY_DESCRIPTION'
  }
};

export const ADVERTISING_PATH = {
  payment: '/advertisement/payment',
  adsCampaign: '/ads-campaign',
  adsGroup: '/ads-group',
  ads: '/ads'
};

export const ADVERTISING_BUTTONS = {
  none: { name: 'COMMON.NONE', value: ADS_CREATE.none, common: false },
  registerForm: { name: 'ADVERTISING.ADS.REGISTER_FORM', value: ADS_CREATE.register_form, common: true },
  contactUs: { name: 'ADVERTISING.ADS.CONTACT_US', value: ADS_CREATE.contact_us, common: true },
  bookNow: { name: 'ADVERTISING.ADS.BOOK_NOW', value: ADS_CREATE.book_now, common: true },
  other: { name: 'COMMON.OTHER', value: ADS_CREATE.other, common: false }
};
