import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonService } from '@app/core/services/common.service';
import { AVATAR_SIZE, AVATAR_TYPE } from '@app/shared/constant';
import { environment } from '@env/environment';

@Component({
  selector: 'profile-avatar',
  templateUrl: './profile-avatar.component.html',
  styleUrls: ['./profile-avatar.component.scss']
})
export class ProfileAvatarComponent {
  @Input() type = AVATAR_TYPE.grayBorder;
  @Input() size = AVATAR_SIZE.small;
  @Input() primaryRingColor = 'bg-gradient-to-r from-[#FEAD34] to-[#FF7041]';
  @Input() iconLoyalty: string;
  @Input() initialsAvatarColor = 'bg-palette-blue-50 text-palette-blue-600';
  @Input() thumbnailURL = '';
  @Input() onerrorImage = 'assets/images/default_user_avatar.png';
  @Input() name = '';
  @Input() clickable = true;
  @Input() rounded = 'rounded-full';
  @Input() initialsAvatarCursorPointer = false;
  @Output() onCLick = new EventEmitter();
  baseUrl = environment.baseURL;
  avatarType = AVATAR_TYPE;
  avatarSize = AVATAR_SIZE;

  constructor(private commonService: CommonService) {}

  getThumbnailURL(): string {
    if (this.thumbnailURL?.indexOf('/storage/files/web/') !== -1) {
      return `${this.baseUrl}${this.thumbnailURL}`;
    }
    return this.commonService.getImageUrl(this.thumbnailURL);
  }

  handleImageError(event: any) {
    if (this.onerrorImage.length > 0) {
      event.target.src = this.onerrorImage;
    } else {
      this.thumbnailURL = '';
    }
  }
  getImageUrl(url: string){
    return this.commonService.getImageUrl(url);
  }

  onCLickAvatar(): void {
    this.onCLick.emit();
  }
}
