<div class="flex w-full items-center px-0.5">
  <div *ngIf="!isStory" class="relative self-start" [ngClass]="isReplyForm ? 'w-8 h-8' : 'w-9 h-9'">
    <avatar
      [avatarSize]="isReplyForm ? 32 : 36"
      [userInfo]="loginUser"
      [selectedProfile]="selectedProfile"
      [showSwitchProfileOption]="showSwitchProfileOption"
      (switchProfileEvent)="onSwitchProfile($event)"></avatar>
  </div>
  <div class="flex-auto" [ngClass]="{ 'ml-2': !isStory }">
    <div
      outsideClick
      (outsideClicked)="isFocusInput = false"
      [ngClass]="[
        'flex border text-sm w-full flex-col',
        bgComment,
        isFocusInput && !userStory ? 'border-palette-blue-300' : 'border-palette-gray-300',
        isStory && !isEditComment ? 'rounded-full py-2 bg-palette-gray-200' : 'rounded-lg pt-3 bg-palette-base-white',
        userStory ? 'py-[13px] bg-transparent text-lg border-palette-gray-300' : ''
      ]">
      <div
        #textarea
        [attr.contenteditable]="isFocusInput && isLoggedIn ? 'true' : 'false'"
        checkAuthentication
        (openLoginPopup)="isOpenPopupLogin = $event"
        [ngClass]="[
          'flex-auto text-sm p-0 text-left enabled:focus:shadow-none border-0 px-3 enabled:focus:col-8 outline-0 w-full focus-visible:outline-none whitespace-pre-wrap overflowWrap emoji',
          color ? color : 'text-palette-gray-700',
          isStory ? 'comment-form-story' : '',
          !isEditComment ? 'one-row' : '',
          isFocusInput ? 'should-focus-input' : '',
          isDisableEditComment ? 'pointer-events-none opacity-50' : ''
        ]"
        (keydown)="onKeyDown($event)"
        (keyup)="onKeyUp($event)"
        (paste)="onPaste($event)"
        (click)="onClick()"
        id="textarea"
        [attr.placeholder]="commentAreaPlaceholder"></div>
      <div *ngIf="previewData || loadingPreviewData" class="mt-2 px-3">
        <post-content-preview
          [loading]="loadingPreviewData"
          [isCreatingPost]="true"
          [commentUI]="true"
          [data]="previewData"
          (removePreview)="previewData = null; isPreviewDataChanged = true">
        </post-content-preview>
      </div>
      <div class="flex justify-between h-10 px-2" *ngIf="!isStory || isEditComment">
        <div class="flex items-center w-fit relative" [ngClass]="{ 'invisible': isStory }">
          <i
            *ngIf="isInsertImage"
            [ngClass]="[
              'sctr-icon-image-01 text-base cursor-pointer rounded-md px-1.5 py-0.5 hover:bg-palette-gray-200',
              color ? color : 'text-palette-gray-600'
            ]"
            checkAuthentication
            (openLoginPopup)="isOpenPopupLogin = $event"
            (click)="handleUploadFileClick()"></i>
          <i
            checkAuthentication
            (openLoginPopup)="isOpenPopupLogin = $event"
            [ngClass]="[
              'sctr-icon-face-smile text-base cursor-pointer rounded-md px-1.5 py-0.5 hover:bg-palette-gray-200',
              color ? color : 'text-palette-gray-600'
            ]"
            (click)="!isOpenPopupLogin ? emoji.toggle($event) : null"></i>
          <p-overlayPanel
            [styleClass]="'emojiPanel w-[360px] h-[317px]'"
            (onShow)="onShowOverlayPanel()"
            (onHide)="onHideOverlayPanel()"
            #emoji>
            <comment-emoji
              *ngIf="isShowEmojiPopup"
              (selectEmoji)="onSelectEmoji($event)"
              [isShowEmoji]="isShowEmojiPopup"></comment-emoji>
          </p-overlayPanel>
        </div>
        <div
          *ngIf="!isEditComment; else editComment"
          [ngClass]="{
            'p-disabled': showProgress || !(textarea.innerText.trim() || selectedFiles.length || mediaComment.length)
          }"
          class="flex items-center">
          <i
            checkAuthentication
            (openLoginPopup)="isOpenPopupLogin = $event"
            class="sctr-icon-solid-send-03 text-base"
            [ngClass]="
              (isFocusInput && textarea.innerText.trim()) || selectedFiles.length || mediaComment.length
                ? 'text-palette-blue-600 cursor-pointer'
                : color
                ? color
                : 'text-palette-gray-300'
            "
            (click)="!showProgress && isFocusInput ? addComment() : null; isFocusInput = false"></i>
        </div>
        <ng-template #editComment>
          <div class="flex items-center gap-2">
            <p-button
              [disabled]="isDisableEditComment"
              label="{{ 'COMMON.BUTTON_ACTION.CANCEL' | translate }}"
              [styleClass]="
                'p-button-outlined p-button-secondary rounded-lg py-1 px-3 border-palette-gray-300 text-palette-base-black shadow-none'
              "
              (onClick)="cancelEditComment()"></p-button>
            <p-button
              label="{{ 'COMMON.BUTTON_ACTION.SAVE' | translate }}"
              [styleClass]="
                'p-button-outlined p-button-secondary rounded-lg py-1 px-3 text-palette-base-white bg-palette-blue-600 border-palette-blue-600 shadow-none'
              "
              [disabled]="
                !(
                  (isChangeContent &&
                    (textarea.innerText.trim() ||
                      (selectedFiles && selectedFiles.length) ||
                      (mediaComment && mediaComment.length))) ||
                  (isEditComment && isPreviewDataChanged)
                )
              "
              (onClick)="addComment()"></p-button>
          </div>
        </ng-template>
      </div>

      <!-- Upload image -->
      <input
        type="file"
        #file_upload
        name="file_upload"
        class="hidden"
        [accept]="allowedTypes.join(',')"
        (change)="handleFileUpload($event)" />
      <div
        class="w-full flex flex-wrap py-1 px-3.5 pb-4 gap-3"
        *ngIf="(selectedFiles && selectedFiles.length > 0) || (mediaComment && mediaComment.length > 0)">
        <p-progressBar
          *ngIf="showProgress"
          mode="indeterminate"
          [class]="'w-full'"
          [styleClass]="'h-1.5'"></p-progressBar>
        <div class="w-full flex flex-wrap gap-3">
          <div
            *ngFor="let file of selectedFiles.length ? selectedFiles : mediaComment; let i = index"
            class="rounded-lg w-[110px] h-[110px] relative inline-block">
            <ng-container *ngIf="file?.file?.type.startsWith('image/') || file.type?.startsWith('image/')">
              <img [src]="file.original || createMediaUrl(file.id)" class="rounded-lg object-cover w-full h-full" />
            </ng-container>
            <ng-container *ngIf="file?.file?.type.startsWith('video/') || file.type?.startsWith('video/')">
              <video class="rounded-lg object-cover w-28 h-28">
                <source [src]="file.original || createMediaUrl(file.id, FILE_TYPE_URL.streamVideo)" />
                {{ 'COMMENT.NOT_SUPPORT_VIDEO' | translate }}
              </video>
            </ng-container>
            <p-button
              [styleClass]="
                'absolute top-[5px] right-[5px] w-5 h-5 p-0.5 rounded-full text-palette-base-white border-palette-base-white bg-palette-gray-900'
              "
              (click)="deleteFile(i)">
              <i class="sctr-icon-x text-palette-base-white"></i>
            </p-button>
          </div>
          <!-- <div
            class="cursor-pointer rounded-lg w-[110px] h-[110px] bg-palette-gray-50 border-palette-gray-300 border-dashed border-2 flex justify-center items-center">
            <i
              class="sctr-icon-plus text-2xl rounded-lg p-4 text-palette-gray-300"
              (click)="handleUploadFileClick()"></i>
          </div> -->
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="isStory && !isEditComment"
    class="flex ml-3 justify-center items-center text-palette-base-white cursor-pointer rounded-full border-[1.5px] border-palette-base-white p-3"
    [ngClass]="[
      userStory ? 'bg-palette-gray-800 w-12 h-12' : 'gradient-button w-10 h-10',
      !textarea.innerText.trim() ? 'p-disabled opacity-100' : ''
    ]"
    (click)="addComment()">
    <i class="sctr-icon-send-01" [ngClass]="[userStory ? 'text-[26px]' : 'text-xl']"></i>
  </div>
</div>
<p-overlayPanel [styleClass]="'tagCommentPanel'" (onHide)="this.removeTaggingSpans()" #tagCommentPanel>
  <p-listbox
    #listbox
    [options]="friendList"
    [ngModel]="friendList[index]"
    [listStyle]="{ 'max-height': '300px' }"
    (onClick)="tagFriend($event)">
    <ng-template let-item pTemplate="item">
      <avatar [isDisableNavigate]="true" [isSmallThumbnail]="true" [userInfo]="item"></avatar>
      <span class="px-2 font-semibold text-sm whitespace-nowrap overflow-hidden w-[160px]" #ellipsis>{{
        item.full_name
      }}</span>
      <span class="ml-1" *ngIf="ellipsis.scrollWidth > 160" [pTooltip]="item.full_name">...</span>
    </ng-template>
    <ng-template pTemplate="empty"> </ng-template>
  </p-listbox>
</p-overlayPanel>
<div *ngIf="isOpenPopupLogin">
  <popup-login [(visible)]="isOpenPopupLogin"></popup-login>
</div>
