<div class="str-p-paginator flex flex-col md:flex-row justify-center md:justify-between gap-2">
  <div
    class="flex gap-2 items-center justify-center md:justify-start"
    *ngIf="paging.totalElement > PAGE_SIZE_OPTIONS[0].size">
    <span class="text-sm text-palette-gray-700">{{ 'STATISTIC.POST_LIST.ITEMS_PER_PAGE' | translate }}:</span>
    <div class="custom-select-row">
      <p-dropdown
        [options]="PAGE_SIZE_OPTIONS"
        [(ngModel)]="selectedPageSize"
        optionLabel="label"
        optionValue="size"
        styleClass="w-[90px] h-9 rounded-lg border border-palette-gray-300 text-center flex items-center justify-center text-sm"
        panelStyleClass="w-[90px] px-2 py-0 rounded-lg text-sm align-middle"
        (onChange)="onChangedPageSize($event.value)"></p-dropdown>
    </div>
    <span *ngIf="visibleItemsCount && paging && paging.totalElement">{{
      'COMMON.RANGE_OF_TOTAL_ITEMS'
        | translate
          : {
              from: paging.pageSize * paging.page + 1,
              to: currentLastRow,
              total: paging.totalElement
            }
    }}</span>
  </div>
  <div class="md:min-w-fit">
    <p-paginator
      *ngIf="selectedPageSize === paging.pageSize"
      (onPageChange)="onPageChange($event)"
      [showFirstLastIcon]="paging.totalElement / paging.pageSize > 5"
      [alwaysShow]="false"
      [first]="paging.pageSize * paging.page"
      [rows]="paging.pageSize"
      [totalRecords]="paging.totalElement"
      styleClass="h-9 p-0"></p-paginator>
  </div>
</div>
