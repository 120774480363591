import { HttpClient } from '@angular/common/http';
import { Injectable, QueryList } from '@angular/core';
import { ApiClient } from '@app/lib/api/api-client';
import {
  ADS_ACTION,
  ADS_CATEGORY,
  ELEMENT_TRACKING_RATIO,
  PLAY_VIDEO_EVENT_TIMES
} from '@app/lib/api/client/api.client.constant';
import { DataEventTracking } from '@app/lib/api/client/api.client.model';
import { selectUserInfo } from '@app/modules/main/states/users/users.selectors';
import { PostComponent } from '@app/shared/components/post/post.component';
import { Post } from '@app/shared/models/post';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';

const DEFAULT_INTERACTION_VALUES = {
  platform: 'Web',
  module: 'Social'
};

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  private api: ApiClient;
  private trackedDevice = false;
  eventBuffer: DataEventTracking[] = [];

  constructor(
    private httpClient: HttpClient,
    private store: Store
  ) {
    this.api = new ApiClient(this.httpClient, { responseTimeout: environment.API_TIMEOUT });
  }

  pushToBuffer(dataSource: string, category: string, action: string, count = 1, label = ''): void {
    if (!this.eventBuffer?.length) {
      const existingEventBuffer = JSON.parse(localStorage.getItem('event_buffer') || '[]');
      this.eventBuffer = cloneDeep(existingEventBuffer);
    }

    const newData: DataEventTracking = {
      m: DEFAULT_INTERACTION_VALUES.module,
      c: category,
      a: action,
      d: dataSource,
      co: count,
      l: label
    };
    const bufferSizeInBytes = new TextEncoder().encode(JSON.stringify([...this.eventBuffer, newData])).length;
    if (bufferSizeInBytes >= environment.AD_BUFFER_SIZE_LIMIT) {
      this.flushAndResetBuffer(newData);
    } else {
      this.eventBuffer.push(newData);
      localStorage.setItem('event_buffer', JSON.stringify(this.eventBuffer));
    }
  }

  pushPostAction(postData: Post, action: string, isSearching = false, label = '', count = 1): void {
    if (postData && postData.id) {
      this.pushToBuffer(
        postData.ads_id ? postData.ads_id : postData.id,
        postData.ads_id ? (isSearching ? ADS_CATEGORY.adsSearch : ADS_CATEGORY.adsFeed) : ADS_CATEGORY.post,
        action,
        count,
        label
      );
    }
  }

  pushPlayVideoToBuffer(videoId: string, action: string): void {
    const existingDataIndex = this.eventBuffer.findIndex(
      (data: DataEventTracking) => data.c === ADS_CATEGORY.video && data.d === videoId && data.a === action
    );
    if (existingDataIndex === -1) {
      this.pushToBuffer(videoId, ADS_CATEGORY.video, action);
    }
  }

  pushDeviceTracking(): void {
    if (!this.trackedDevice) {
      this.store.select(selectUserInfo).subscribe(data => {
        if (data && data.id) {
          this.pushToBuffer(data.id, ADS_CATEGORY.deviceUsing, ADS_ACTION.deviceTracking);
          this.trackedDevice = true;
        }
        return;
      });
    }
  }

  handlePlayVideoEvent(startTime: number, totalPlayDuration: number, videoId: string, videoDuration: number): number {
    totalPlayDuration = totalPlayDuration + (Date.now() - startTime);
    switch (true) {
      case totalPlayDuration / 1000 / videoDuration >= PLAY_VIDEO_EVENT_TIMES.long.playPercent:
        totalPlayDuration = 0;
        this.pushPlayVideoToBuffer(videoId, PLAY_VIDEO_EVENT_TIMES.long.action);
        break;
      case totalPlayDuration >= PLAY_VIDEO_EVENT_TIMES.short.totalTime:
        this.pushPlayVideoToBuffer(videoId, PLAY_VIDEO_EVENT_TIMES.short.action);
        break;
      case totalPlayDuration >= PLAY_VIDEO_EVENT_TIMES.quick.totalTime:
        this.pushPlayVideoToBuffer(videoId, PLAY_VIDEO_EVENT_TIMES.quick.action);
        break;
      case totalPlayDuration >= PLAY_VIDEO_EVENT_TIMES.brief.totalTime:
        this.pushPlayVideoToBuffer(videoId, PLAY_VIDEO_EVENT_TIMES.brief.action);
        break;
      default:
        break;
    }
    return totalPlayDuration;
  }

  flushAndResetBuffer(newData?: DataEventTracking): void {
    if (this.eventBuffer.length) {
      const userProfile = JSON.parse(localStorage.getItem('user_profile') || '{}');
      const data = {
        platform: DEFAULT_INTERACTION_VALUES.platform,
        screenSize: `${innerWidth}x${innerHeight}`,
        dataArray: this.eventBuffer,
        language: (userProfile?.language || '').toLowerCase(),
        location: (userProfile?.country_code || '').toLowerCase()
      };
      const sendingBuffer = this.eventBuffer;
      this.eventBuffer = [];
      if (newData) {
        this.eventBuffer.push(newData);
        localStorage.setItem('event_buffer', JSON.stringify(this.eventBuffer));
      }
      this.pushInteractionEvents(data).subscribe((res: any) => {
        if (!(res && res.success)) {
          this.eventBuffer = this.eventBuffer.concat(sendingBuffer);
          localStorage.setItem('event_buffer', JSON.stringify(this.eventBuffer));
        }
      });
    }
  }

  queryPostsInViewPort(postChildren: QueryList<PostComponent>): void {
    postChildren.forEach(postElement => {
      if (!postElement.lockFocusSubject) {
        const element = postElement.elementRef.nativeElement as HTMLElement;
        const rect = element.getBoundingClientRect();
        const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
        if (rect.top >= -rect.height && rect.top <= viewportHeight) {
          const visibleHeight = Math.max(0, Math.min(rect.bottom, viewportHeight) - Math.max(rect.top, 0));
          if (
            visibleHeight / rect.height >= ELEMENT_TRACKING_RATIO.visibleHeight ||
            visibleHeight / viewportHeight >= ELEMENT_TRACKING_RATIO.visibleInViewport
          ) {
            postElement.focusSubject.next('');
          } else {
            postElement.lockFocusSubject = false;
          }
        } else {
          postElement.lockFocusSubject = false;
        }
      }
    });
  }

  pushInteractionEvents(params: any) {
    return this.api.feedsEngine.postInteractionHub(params);
  }
}
