import { Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { FriendService } from '@app/core/services/friend.service';
import { HashtagService } from '@app/core/services/hashtag.service';
import { Hashtag } from '@app/lib/api/hashtag/api.hashtag.models';
import { BREAKPOINTS, KEYBOARD_EVENT_CODES, PAGE_NUM_DEFAULT, PAGE_SIZE_DEFAULT } from '@app/shared/constant';
import { FriendList } from '@app/shared/models/friend';
import { MessageRanges } from '@app/shared/models/post';
import { UserInfo } from '@app/shared/models/user';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Subject, debounceTime } from 'rxjs';

interface MentionOverlayPosition {
  top: number;
  left: number;
  contentBeforeMention?: string;
}

@Component({
  selector: 'textarea-post',
  templateUrl: './textarea-post.component.html',
  styleUrls: ['./textarea-post.component.scss']
})
export class TextareaPostComponent implements ControlValueAccessor {
  @ViewChild('backdrop') $backdrop: ElementRef;
  @ViewChild('divEl') $divEl: ElementRef;
  @ViewChild('textarea') $textarea: ElementRef<HTMLTextAreaElement>;

  tagCount = 0;
  themeBg: any;
  textValue = '';

  //Mention variables
  showMentionOverlay = false;
  mentionStartPosition = 0;
  overlayPosition: MentionOverlayPosition = { top: 0, left: 0 };
  highlightedIndex = 0;
  searchKey = '';
  searchMentionSubject = new Subject<any>();
  allFriendList: FriendList[] = [];
  mentionList: FriendList[] = [];
  mentionedList: { fullName: string; mentionStartPosition: number }[] = [];
  pageNum = PAGE_NUM_DEFAULT;
  pageNumAllFriends = PAGE_NUM_DEFAULT;
  isLastPage = false;
  isLastPageAllFriends = false;
  isLoadingMentionList = false;
  isLoadingMoreMentionList = false;
  hashtagList: Hashtag[] = [];
  isLoadingHashTagList = false;
  searchHashtagKey = '';
  showHashtagOverlay = false;
  @ViewChild('mentionOverlayPanel') mentionOverlayPanel!: OverlayPanel;
  @ViewChild('mentionListContainer') mentionListContainer!: ElementRef<HTMLDivElement>;
  @ViewChild('hashtagOverlayPanel') hashtagOverlayPanel!: OverlayPanel;
  @ViewChild('hashtagListContainer') hashtagListContainer!: ElementRef<HTMLDivElement>;

  @Input() contentComment: string;
  @Input() theme: any;
  @Input() content: string;
  @Input() friendList: FriendList[];
  @Input() isEditPost = false;
  @Input() messageRanges: MessageRanges[] = [];
  @Input() userInfo: UserInfo;
  @Input() fanpageInfo: any;
  @Input() isGroupPost = false;
  @Input() isFanpagePost = false;
  @Input() selectedProfile: any;
  @Output() onChangeMention: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChangeContent: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChangeCurrentCharacter: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('textarea') textarea!: ElementRef<HTMLTextAreaElement>;

  constructor(
    private el: ElementRef,
    private friendService: FriendService,
    private hashtagService: HashtagService
  ) {
    this.searchMentionSubject.pipe(debounceTime(300)).subscribe(data => {
      this.searchMention(data);
    });
  }

  get highlightedText() {
    if (this.textValue) {
      return this.applyHighlights(this.textValue);
    }
    return '';
  }

  get profileName(): string {
    if (this.isFanpagePost) {
      return this.fanpageInfo?.page_name;
    } else {
      return this.selectedProfile && !this.isUserProfileSelected()
        ? this.selectedProfile?.page_name
        : this.userInfo.first_name;
    }
  }

  ngOnInit(): void {
    this.textValue = this.content;
  }

  isUserProfileSelected(): boolean {
    return this.selectedProfile?.id === this.userInfo?.id;
  }

  applyHighlights(text: string): string {
    if (typeof text !== 'string') {
      return '';
    }

    const highlightedText = text.replace(/(^|[^<])(#[a-zA-ZÀ-Ỹà-ỹ0-9_]+)/g, (match, prefix, word) => {
      return `${prefix}<mark class="bg-branding-primary-200">${word}</mark>`;
    });

    const markedNames = this.messageRanges.map(x => `@${x.full_name || ''}`);
    const finalText = markedNames.reduce((text, name) => {
      const regex = new RegExp(name, 'g');
      return text.replace(regex, `<mark class="bg-branding-primary-200">$&</mark>`);
    }, highlightedText);

    return finalText;
  }
  handleScroll() {
    var scrollTop = this.$textarea.nativeElement.scrollTop;
    this.$backdrop.nativeElement.scrollTop = scrollTop;

    var scrollLeft = this.$textarea.nativeElement.scrollLeft;
    this.$backdrop.nativeElement.scrollLeft = scrollLeft;
  }

  getCaretPosition(element: Element): number {
    let caretPos = 0;
    let sel, range;

    if (window.getSelection) {
      sel = window.getSelection();
      if (sel && sel.rangeCount) {
        range = sel.getRangeAt(0);
        let preCaretRange = document.createRange();
        preCaretRange.setStart(element, 0);
        preCaretRange.setEnd(range.endContainer, range.endOffset);
        let content = preCaretRange.cloneContents();
        let tempDiv = document.createElement('div');
        tempDiv.appendChild(content);
        caretPos = tempDiv.innerHTML.length;
      }
    }
    return caretPos;
  }
  tagFriend(event: any): void {
    setTimeout(() => {
      const textareaEl = this.$divEl.nativeElement;

      if (!textareaEl) {
        return;
      }

      const tagString = `@${event.full_name}`;
      const tagIndex = textareaEl.innerHTML.indexOf(tagString);

      if (tagIndex === -1) {
        return;
      }

      const offset = textareaEl.textContent.substring(0, tagIndex);

      const firstContent = textareaEl.innerHTML.substring(0, tagIndex);
      const lastContent = textareaEl.innerHTML.substring(tagIndex + tagString.length);

      textareaEl.innerHTML =
        firstContent +
        `<span id="tag-${this.tagCount}" class="bg-branding-primary-200">${tagString}</span>` +
        lastContent;

      this.moveCursorToEnd(textareaEl);

      textareaEl.insertAdjacentHTML('beforeend', lastContent);

      this.adjustMessageRanges(offset, event);

      this.tagCount++;
    });
  }

  private adjustMessageRanges(offset: string, event: any): void {
    this.messageRanges.forEach(range => {
      if (range.offset > offset.length - 1) {
        range.offset += event.full_name.length;
      }
    });

    this.messageRanges.push({
      id: `tag-${this.tagCount}`,
      entity_id: event.id,
      full_name: event.full_name,
      entity_type: 'USER',
      length: event.full_name.length + 1,
      offset: 0
    });

    this.onChangeMention.emit(this.messageRanges);
  }

  onKeyDown(event: KeyboardEvent): void {
    if (
      (this.showMentionOverlay || this.showHashtagOverlay) &&
      [KEYBOARD_EVENT_CODES.ArrowUp, KEYBOARD_EVENT_CODES.ArrowDown, KEYBOARD_EVENT_CODES.Enter].includes(event.key)
    ) {
      event.preventDefault();
      const count = this.showMentionOverlay ? this.mentionList.length : this.hashtagList.length;
      switch (event.key) {
        case KEYBOARD_EVENT_CODES.ArrowDown:
          this.highlightedIndex = (this.highlightedIndex + 1) % count;
          this.scrollIntoHighlightedMention(
            this.highlightedIndex,
            this.showMentionOverlay ? this.mentionListContainer : this.hashtagListContainer
          );
          break;
        case KEYBOARD_EVENT_CODES.ArrowUp:
          this.highlightedIndex = (this.highlightedIndex - 1 + count) % count;
          this.scrollIntoHighlightedMention(
            this.highlightedIndex,
            this.showMentionOverlay ? this.mentionListContainer : this.hashtagListContainer
          );
          break;
        case KEYBOARD_EVENT_CODES.Enter:
          if (this.showMentionOverlay) {
            this.onSelectMentionFriend(this.mentionList[this.highlightedIndex]);
          } else {
            this.onSelectHashTag(this.hashtagList[this.highlightedIndex]);
          }
          break;
        default:
          break;
      }
    }

    this.emitContentChange();

    const taggedFriends = this.el.nativeElement.querySelectorAll('span.text-branding-primary-700');
    taggedFriends.forEach((friendElement: HTMLElement) => {
      this.handleTaggedFriend(friendElement);
    });
  }

  private emitContentChange(): void {
    this.onChangeContent.emit(this.textValue);
  }

  private handleTaggedFriend(element: HTMLElement): void {
    const tagObj = this.getTagObject(element);
    if (tagObj) {
      this.updateMessageRanges(element, tagObj);
    }
  }

  private getTagObject(element: HTMLElement): string | undefined {
    return this.messageRanges.find(range => range.id === element.id)?.full_name;
  }

  private updateMessageRanges(element: HTMLElement, tagObj: string): void {
    if (element.textContent !== tagObj) {
      this.messageRanges = this.messageRanges.filter(range => range.id !== element.id);
      this.emitMentionChange();
      this.clearTaggedFriend(element);
    }
  }

  private emitMentionChange(): void {
    this.onChangeMention.emit(this.messageRanges);
  }

  private clearTaggedFriend(element: HTMLElement): void {
    element.classList.remove('text-branding-primary-700');
    element.removeAttribute('id');
  }

  countNumOfLine(): number {
    const editableDiv = this.$backdrop.nativeElement;
    const lineHeight = getComputedStyle(editableDiv).lineHeight;
    const height = editableDiv.clientHeight;
    const lineCount = Math.round(height / parseFloat(lineHeight));
    return lineCount;
  }

  applyThemeStyles(theme: any) {
    if (theme) {
      return `placeholder:text-[#${theme.font_color}] text-[#${theme.font_color}]`;
    }
    return ``;
  }

  onChanges: ($value: any) => void;
  onTouched: () => void;
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['theme']) {
      // Do something when the theme input changes
      // For example, you might want to update the component's styling based on the new theme
      this.themeBg = changes['theme'].currentValue;
    }
    if (changes['isEditPost'] && changes['isEditPost'].currentValue) {
      setTimeout(() => {
        const textareaEl = this.$backdrop.nativeElement;
        textareaEl.innerHTML = this.contentComment;
        this.moveCursorToEnd(textareaEl);
      });
    }
    if (changes['friendList']) {
      this.friendList = changes['friendList'].currentValue || [];
      if (this.isEditPost) {
        this.messageRanges = this.messageRanges.map((i: MessageRanges, idx: number) => {
          const found = this.friendList.find(friend => friend.id === i.entity_id);
          this.tagCount++;
          return {
            ...i,
            id: `tag-${idx}`,
            full_name: found?.full_name
          };
        });
      }
    }
    if (changes['content']) {
      let textChange = changes['content'].currentValue;
      if (typeof textChange == 'string') {
        this.textValue = changes['content'].currentValue;
      }
    }
  }
  writeValue(value: any): void {
    if (value !== undefined) {
      this.textValue = value;
    }
  }
  registerOnChange(fn: any): void {
    this.onChanges = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  moveCursorToEnd(el: HTMLElement): void {
    const range = document.createRange();
    const sel = window.getSelection();
    range.selectNodeContents(el);
    range.collapse(false);
    sel?.removeAllRanges();
    sel?.addRange(range);
  }

  updateCurrentCharacter() {
    this.onChangeCurrentCharacter.emit(this.textValue.length);
  }

  scrollTextareaToBottom() {
    const textarea = this.textarea.nativeElement;
    textarea.scrollTop = textarea.scrollHeight;
  }

  onPaste(event: ClipboardEvent) {
    event.preventDefault();
    const pastedContent = event.clipboardData?.getData('text/plain');
    if (!pastedContent) {
      return;
    }
    const textarea = this.textarea.nativeElement;
    const startPos = textarea.selectionStart;
    const endPos = textarea.selectionEnd;
    const currentContent = textarea.value;
    const newContent = currentContent.substring(0, startPos) + pastedContent + currentContent.substring(endPos);
    textarea.value = newContent;
    const newPosition = startPos + pastedContent.length;
    textarea.setSelectionRange(newPosition, newPosition);
    this.onChangeContent.emit(textarea.value);
    setTimeout(() => {
      this.updateCurrentCharacter();
      this.scrollTextareaToBottom();
    }, 0);
  }

  onKeyUp(event: KeyboardEvent): void {
    this.mentionOverlayPanel.hide();
    if (
      ![KEYBOARD_EVENT_CODES.ArrowUp, KEYBOARD_EVENT_CODES.ArrowDown, KEYBOARD_EVENT_CODES.Enter].includes(event.key)
    ) {
      const cursorPosition = this.textarea.nativeElement.selectionStart;
      const textBeforeCursor = this.textarea.nativeElement.value.slice(0, cursorPosition);
      const mentionMatches = [...textBeforeCursor.matchAll(/@[^@#]*$\S*(?: \S*){0,2}$/gu)];
      const mentionTrigger = mentionMatches.length > 0 ? mentionMatches[mentionMatches.length - 1] : null;
      const keyMatches = [...textBeforeCursor.matchAll(/#[^#@]*$\S*(?: \S*){0,2}$/gu)];
      const hashtagTrigger = keyMatches.length > 0 ? keyMatches[keyMatches.length - 1] : null;
      if (mentionTrigger) {
        this.showHashtagOverlay = false;
        this.hashtagOverlayPanel.hide();
        this.handleMention(event, cursorPosition, mentionTrigger);
      } else if (hashtagTrigger) {
        this.showMentionOverlay = false;
        this.mentionOverlayPanel.hide();
        this.handleHashtag(event, cursorPosition, hashtagTrigger);
      }
    }
  }

  handleMention(event: KeyboardEvent, cursorPosition: number, mentionTrigger: RegExpMatchArray): void {
    const whiteSpaceCount = mentionTrigger ? (mentionTrigger[0].match(/ /g) || []).length : 0;
    const isExistedMention = this.mentionedList.find(
      item => mentionTrigger && item.fullName === mentionTrigger[0].substring(1)
    );
    if (mentionTrigger && whiteSpaceCount < 2 && !isExistedMention) {
      this.mentionStartPosition = cursorPosition;
      this.searchMentionSubject.next({ event, searchKey: mentionTrigger[0].substring(1) });
    } else {
      this.showMentionOverlay = false;
      this.mentionOverlayPanel.hide();
    }
  }

  handleHashtag(event: KeyboardEvent, cursorPosition: number, hashtagTrigger: RegExpMatchArray): void {
    const whiteSpaceCount = hashtagTrigger ? (hashtagTrigger[0].match(/ /g) || []).length : 0;
    if (hashtagTrigger && whiteSpaceCount < 1 && hashtagTrigger[0].substring(1)) {
      this.searchHashtagKey = hashtagTrigger[0] || '';
      this.handleMentionOverlayPosition(this.searchHashtagKey, true);
      this.mentionStartPosition = cursorPosition;
      this.getHashtag(hashtagTrigger[0].substring(1));
      this.showHashtagOverlay = true;
      this.hashtagOverlayPanel.show(event, this.textarea.nativeElement);
    } else {
      this.showHashtagOverlay = false;
      this.hashtagOverlayPanel.hide();
    }
  }

  searchMention(data: { event: any; searchKey: string }): void {
    this.mentionList = [];
    this.searchKey = data.searchKey;
    this.pageNum = PAGE_NUM_DEFAULT;
    this.isLoadingMentionList = true;
    this.handleMentionOverlayPosition(data.searchKey);
    this.showMentionOverlay = true;
    this.mentionOverlayPanel.show(data.event, this.textarea.nativeElement);
    if (!data.searchKey.length && this.allFriendList.length) {
      this.isLoadingMentionList = false;
      this.mentionList = this.allFriendList;
      this.highlightedIndex = 0;
      this.pageNum = this.pageNumAllFriends;
    } else {
      const isMentionEnabled = true;
      this.friendService
        .getOrSearchFriendV2(data.searchKey, PAGE_SIZE_DEFAULT, PAGE_NUM_DEFAULT, isMentionEnabled)
        .subscribe({
          next: (res: any) => {
            if (res && res.success && res.data) {
              this.mentionList = res.data.data;
              this.highlightedIndex = 0;
              this.isLastPage = PAGE_SIZE_DEFAULT * (res.data.page + 1) > res.data.totalElement;

              if (!data.searchKey.length) {
                this.pageNumAllFriends = PAGE_NUM_DEFAULT;
                this.allFriendList = res.data.data;
                this.isLastPageAllFriends = PAGE_SIZE_DEFAULT * (res.data.page + 1) >= res.data.totalElement;
              }
              if (!this.mentionList.length) {
                this.showMentionOverlay = false;
                this.mentionOverlayPanel.hide();
              }
            }
            setTimeout(() => {
              this.isLoadingMentionList = false;
            }, 300);
          },
          error: () => {
            this.showMentionOverlay = false;
            this.mentionOverlayPanel.hide();
            this.isLoadingMentionList = false;
          }
        });
    }
  }

  handleMentionOverlayPosition(searchText: string, isHashtag = false): void {
    const textarea = this.textarea.nativeElement;
    const contentBeforeMention = textarea.value.slice(0, textarea.selectionStart - searchText.length - 1);
    if (
      this.overlayPosition.contentBeforeMention === undefined ||
      this.overlayPosition.contentBeforeMention !== contentBeforeMention
    ) {
      const textareaPadding = 10;
      const textareaMaxHeight = 132;
      const span = document.createElement('span');
      span.textContent = contentBeforeMention;
      span.style.whiteSpace = 'pre-wrap';
      const spanMention = document.createElement('span');
      spanMention.textContent = isHashtag ? '#' : '@';
      const div = document.createElement('div');
      div.appendChild(span);
      span.appendChild(spanMention);
      const style = getComputedStyle(textarea);
      Object.assign(div.style, {
        ...Array.from(style).reduce((acc, key) => ({ ...acc, [key]: style.getPropertyValue(key) }), {}),
        position: 'absolute',
        visibility: 'hidden',
        whiteSpace: 'pre-wrap',
        width: `${textarea.clientWidth}px`,
        height: `${textarea.clientHeight}px`,
        overflow: 'hidden'
      });
      document.body.appendChild(div);
      const spanRect = span.getBoundingClientRect();
      const spanMentionRect = spanMention.getBoundingClientRect();
      const top = Math.min(
        spanRect.height - textarea.scrollTop + textareaPadding + (this.theme ? 45 : 0),
        textareaMaxHeight - textareaPadding
      );
      const left =
        window.innerWidth > BREAKPOINTS.md ? spanMentionRect.left - textarea.scrollLeft + textareaPadding : 0;
      this.overlayPosition = { top, left, contentBeforeMention };
      document.body.removeChild(div);
    }
  }

  loadMoreMentionFriends(): void {
    if (
      ((this.searchKey.length && !this.isLastPage) || (!this.searchKey.length && !this.isLastPageAllFriends)) &&
      !this.isLoadingMentionList &&
      !this.isLoadingMoreMentionList
    ) {
      this.isLoadingMoreMentionList = true;
      const isMentionEnabled = true;
      this.friendService
        .getOrSearchFriendV2(
          this.searchKey,
          PAGE_SIZE_DEFAULT,
          (this.searchKey.length ? this.pageNum : this.pageNumAllFriends) + 1,
          isMentionEnabled
        )
        .subscribe((res: any) => {
          if (res && res.success && res.data && res.data.data) {
            this.mentionList = [...this.mentionList, ...res.data.data];
            if (this.searchKey.length) {
              this.isLastPage = PAGE_SIZE_DEFAULT * (res.data.page + 1) > res.data.totalElement;
              this.pageNum++;
            } else {
              this.pageNumAllFriends++;
              this.isLastPageAllFriends = PAGE_SIZE_DEFAULT * (this.pageNumAllFriends + 1) > res.data.totalElement;
              this.allFriendList = [...this.allFriendList, ...res.data.data];
            }
          }
          this.isLoadingMoreMentionList = false;
        });
    }
  }

  onSelectMentionFriend(friend: FriendList): void {
    const textBeforeMention = this.textValue.substring(0, this.mentionStartPosition - this.searchKey.length);
    const textAfterMention = this.textValue.substring(this.mentionStartPosition);
    this.mentionedList.push({ fullName: friend.full_name, mentionStartPosition: this.mentionStartPosition });
    this.textValue = `${textBeforeMention}${friend.full_name}${textAfterMention}`;
    const mentionedCursor = `${textBeforeMention}${friend.full_name}`.length;
    this.emitContentChange();
    this.tagFriend(friend);
    this.showMentionOverlay = false;
    this.mentionOverlayPanel.hide();
    setTimeout(() => {
      this.$textarea.nativeElement.focus();
      this.$textarea.nativeElement.setSelectionRange(mentionedCursor, mentionedCursor);
    });
  }

  scrollIntoHighlightedMention(index: number, listContainer: ElementRef<HTMLDivElement>): void {
    if (!listContainer || !listContainer.nativeElement) return;
    const element = listContainer.nativeElement.querySelector(
      `${this.showMentionOverlay ? '#mention-item-' : '#hashtag-item-'}${index}`
    );
    if (!element) return;
    const elementRect = element.getBoundingClientRect();
    const containerRect = listContainer.nativeElement.getBoundingClientRect();
    if (elementRect.top < containerRect.top || elementRect.bottom > containerRect.bottom) {
      element.scrollIntoView({ behavior: 'smooth', block: elementRect.top < containerRect.top ? 'start' : 'end' });
    }
  }

  onSelectHashTag(item: any) {
    const textBeforeMention = this.textValue.substring(0, this.mentionStartPosition - this.searchHashtagKey.length);
    const textAfterMention = this.textValue.substring(this.mentionStartPosition).trim();
    this.textValue = `${textBeforeMention}${item.hashtag} ${textAfterMention}`;
    const mentionedCursor = `${textBeforeMention}${item.hashtag} `.length;
    this.emitContentChange();
    this.showHashtagOverlay = false;
    this.hashtagOverlayPanel.hide();
    setTimeout(() => {
      this.$textarea.nativeElement.focus();
      this.$textarea.nativeElement.setSelectionRange(mentionedCursor, mentionedCursor);
    });
  }

  getHashtag(keySearch = '') {
    this.isLoadingHashTagList = true;
    this.hashtagService.getSuggestHashTag(keySearch).subscribe(res => {
      if (res.data.length) {
        this.hashtagList = res.data;
      } else {
        this.hashtagOverlayPanel.hide();
      }
      this.isLoadingHashTagList = false;
    });
  }
}
