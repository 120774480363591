<div [ngClass]="{ 'mb-3 ml-px': isPostView }">
  <comment-form
    *ngIf="!hideCommentBox && isAllowComment"
    [loginUser]="userInfo$ | async"
    [showSwitchProfileOption]="showSwitchProfileOption"
    (valueComment)="addComment($event)"
    (switchProfileEvent)="onSwitchProfile($event)"></comment-form>
  <div
    #commentContainer
    class="heightComments overflow-y-auto"
    [ngClass]="!isPostView ? 'max-h-[450px]' : ''"
    (scroll)="!isGettingComment && comments.length > 3 ? showMoreComments() : null">
    <div *ngFor="let comment of comments; let i = index">
      <comment-tree
        *ngIf="!isHideComment(comment.id)"
        [hideReply]="hideCommentBox"
        [loginUser]="userInfo$ | async"
        [comment]="comment"
        [post]="post"
        [postOwnerId]="postOwnerId"
        [showSwitchProfileOption]="showSwitchProfileOption"
        [selectedProfile]="selectedProfile"
        [pageReactionInfo]="pageReactionInfo"
        (increaseTotal)="onIncreaseTotal($event)"
        (deletedCommentId)="deleteComment($event)"
        (createdComment)="createdComment.emit()"
        [isPostView]="isPostView"></comment-tree>
    </div>
    <ng-container *ngIf="isLoading" [ngTemplateOutlet]="skeleton"></ng-container>
    <ng-template #skeleton>
      <div class="py-4 px-0 rounded-lg bg-palette-base-white border-palette-gray-200 gap-4 mb-2">
        <loading-skeleton rounded></loading-skeleton>
      </div>
    </ng-template>
    <div
      class="cursor-pointer text-sm font-semibold text-branding-primary-700 pt-2 hover:underline"
      *ngIf="comments && totalComments > 3 && comments.length <= 3"
      (click)="!isGettingComment ? showMoreComments() : null">
      {{ 'COMMENT.VIEW_MORE_COMMENTS' | translate }}
      <div [ngClass]="isGettingComment ? 'pi-spin pi sctr-icon-loading-02' : ''"></div>
    </div>
  </div>
</div>
