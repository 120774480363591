import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AnalyticsService } from '@app/core/services/analytics.service';
import { LivestreamService } from '@app/core/services/livestream.service';
import { PostsService } from '@app/core/services/posts.service';
import {
  ADS_ACTION,
  ADS_CATEGORY,
  ELEMENT_TRACKING_RATIO,
  FOCUS_DEBOUNCE_TIMES
} from '@app/lib/api/client/api.client.constant';
import { selectMyCoin } from '@app/modules/main/personal-profile/components/personal-profile-coin/store/selector';
import {
  selectGroup,
  selectGroupAllToJoin
} from '@app/modules/main/personal-profile/components/personal-profile-groups/store/selectors';
import { selectHidePostId } from '@app/modules/main/states/detail-post/detail-post.selectors';
import { selectIsUpdatedPost } from '@app/modules/main/states/user-posts/user-posts.selectors';
import { selectUserInfo } from '@app/modules/main/states/users/users.selectors';
import { LIVESTREAM_STATUS, POST_TYPE } from '@app/shared/constant';
import { FriendList } from '@app/shared/models/friend';
import { DROPDOWN_POST, HeaderPost, Post, SHARE_TYPE } from '@app/shared/models/post';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { Subject, Subscription, debounceTime } from 'rxjs';
import { PostFooterComponent } from './post-footer/post-footer.component';
@Component({
  selector: 'post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss']
})
export class PostComponent implements OnInit, OnDestroy {
  @Input() post: Post;
  @Input() isPinnedPost: boolean = false;
  @Input() isSharedPost = false;
  @Input() isDisabledPost = false;
  @Input() isLoggedUser: boolean;
  @Input() configType: number = DROPDOWN_POST.default;
  @Input() isPostGroup: boolean = false;
  @Input() isFanpagePost = false;
  @Input() fanpageInfo: any;
  @Input() isPostReview = false;
  @Input() groupId: string;
  @Input() isPostInProfile = false;
  @Input() isPostView = false;
  @Input() isShowComment = false;
  @Input() isShowCreateAdsBtn = false;
  @Input() isShowHideBtn = true;
  @Input() isShowActualPoster = false;
  @Input() groupPrivacy: string;
  @Input() isShowView = false;
  @Input() isPostDetail = false;
  @Input() isPostSearching = false;
  @Input() isWatchScreen = false;
  @Input() friendList: FriendList[];
  @Input() isNewsfeed = false;
  @Input() isPostViewComment = false;
  @Input() parentPostId: string;
  @Input() showSwitchProfileOption = false;
  @Output() deletePost = new EventEmitter();
  @Output() pinPostEmit = new EventEmitter();
  @Output() unPinPostEmit = new EventEmitter();
  @Output() archivePost = new EventEmitter();
  @Output() download_image_or_video = new EventEmitter();
  @Output() hidePostEvent = new EventEmitter();
  @ViewChild(PostFooterComponent) postFooter: PostFooterComponent;

  userInfo$ = this.store.select(selectUserInfo);
  myGroup$ = this.store.select(selectGroup);
  groupsJoined$ = this.store.select(selectGroupAllToJoin);
  myCoin$ = this.store.select(selectMyCoin);
  selectIsUpdatedPost$ = this.store.select(selectIsUpdatedPost);
  headerPost: HeaderPost;
  shareType: number;
  visibleCreatePostDialog = false;
  visibleShareToGroupPopup = false;
  isHidePost = false;
  sub: Subscription;
  isShowGiftCoin = false;
  lockFocusSubject = false;
  focusSubject = new Subject<any>();
  focusShortSubject = new Subject<any>();
  focusLongSubject = new Subject<any>();
  focusVeryLongSubject = new Subject<any>();
  isLoading = false;
  isModerate = false;
  isShowHideMessage = false;
  isAdsDistributed = false;
  showManageCoinDialog = false;
  selectedProfile: any;

  constructor(
    private store: Store,
    private cdr: ChangeDetectorRef,
    private postsService: PostsService,
    private analyticsService: AnalyticsService,
    public elementRef: ElementRef,
    private livestreamService: LivestreamService,
    private route: ActivatedRoute
  ) {
    this.focusSubject.pipe(debounceTime(FOCUS_DEBOUNCE_TIMES.immediate.time)).subscribe(() => {
      !this.lockFocusSubject && this.onFocusPost(FOCUS_DEBOUNCE_TIMES.immediate);
    });
    this.focusShortSubject.pipe(debounceTime(FOCUS_DEBOUNCE_TIMES.short.time)).subscribe(() => {
      this.lockFocusSubject && this.onFocusPost(FOCUS_DEBOUNCE_TIMES.short);
    });
    this.focusLongSubject.pipe(debounceTime(FOCUS_DEBOUNCE_TIMES.long.time)).subscribe(() => {
      this.lockFocusSubject && this.onFocusPost(FOCUS_DEBOUNCE_TIMES.long);
    });
    this.focusVeryLongSubject.pipe(debounceTime(FOCUS_DEBOUNCE_TIMES.veryLong.time)).subscribe(() => {
      this.lockFocusSubject && this.onFocusPost(FOCUS_DEBOUNCE_TIMES.veryLong);
    });
  }

  ngOnInit(): void {
    this.isAdsDistributed = !this.post?.ads_id;
    this.isModerate = this.route.snapshot.queryParams['admin'] === 'true';
    if (this.post.parent && this.isSharedPost) {
      this.post = this.post.parent;
    }
    this.hidePost();
    if (!this.isPostView) {
      this.sub = this.store.select(selectHidePostId).subscribe(id => {
        if (id && id === this.post.id) {
          this.hidePost(this.post.id, true);
        }
      });
    }
    this.focusSubject.next('');
  }

  hidePost(id = this.post.id, isRequestFromPhotoView = false) {
    const arr = localStorage.getItem('hiddenPosts');
    if (arr) {
      const existHideItem = JSON.parse(arr).filter((item: any) => item.id === id);
      if (existHideItem.length) {
        this.isShowHideMessage = isRequestFromPhotoView && existHideItem[0].isReport;
        if ((existHideItem[0].isReport && this.isNewsfeed) || !existHideItem[0].isReport) {
          this.isHidePost = true;
        }
      }
    }
  }

  hidePostLive(post: Post) {
    if (post.livestream_info) {
      this.isLoading = true;
      if (!environment.URL_WHITELIST.some((str: string) => post.livestream_info?.url.startsWith(str))) {
        this.isHidePost = true;
        this.hidePostEvent.emit(post.id);
        this.isLoading = false;
        return;
      }
      this.livestreamService.getLiveById(post.livestream_info.id).subscribe(item => {
        if (!item || item?.status !== LIVESTREAM_STATUS.onGoing) {
          this.isHidePost = true;
          this.hidePostEvent.emit(post.id);
        }
        this.isLoading = false;
      });
    }
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['post']) {
      this.hidePostLive(changes['post'].currentValue);
      this.initData();
    }
  }

  onFocusPost(focusDebounceTime: { totalTime: number; time: number; label: string }): void {
    const element = this.elementRef.nativeElement as HTMLElement;
    const rect = element.getBoundingClientRect();
    const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
    if (rect.top >= -rect.height && rect.top <= viewportHeight) {
      const visibleHeight = Math.max(0, Math.min(rect.bottom, viewportHeight) - Math.max(rect.top, 0));
      if (
        visibleHeight / rect.height >= ELEMENT_TRACKING_RATIO.visibleHeight ||
        visibleHeight / viewportHeight >= ELEMENT_TRACKING_RATIO.visibleInViewport
      ) {
        switch (focusDebounceTime) {
          case FOCUS_DEBOUNCE_TIMES.immediate:
            this.lockFocusSubject = true;
            this.focusShortSubject.next('');
            break;
          case FOCUS_DEBOUNCE_TIMES.short:
            this.focusLongSubject.next('');
            break;
          case FOCUS_DEBOUNCE_TIMES.long:
            this.focusVeryLongSubject.next('');
            break;
          default:
            break;
        }
        this.analyticsService.pushPostAction(this.post, ADS_ACTION.view, this.isPostSearching, focusDebounceTime.label);
        if (!this.isAdsDistributed && this.post.ads_id) {
          this.analyticsService.pushToBuffer(
            this.post.ads_id,
            this.isPostSearching ? ADS_CATEGORY.adsSearch : ADS_CATEGORY.adsFeed,
            ADS_ACTION.distribute
          );
          this.isAdsDistributed = true;
        }
      } else {
        this.lockFocusSubject = false;
      }
    } else {
      this.lockFocusSubject = false;
    }
  }

  initData() {
    this.headerPost = { ...this.post };
    if (this.post.post_pattern === POST_TYPE.share) {
      if (this.post.group_object) {
        this.headerPost = {
          ...this.post,
          shareToGroupInfo: this.post.group_object,
          shareType: SHARE_TYPE.group
        };
      } else {
        if (this.post.parent) {
          this.headerPost = {
            ...this.post,
            shareToFeedInfo: {
              id: this.post.parent.id,
              full_name: this.post.parent.user_object.full_name
            },
            shareType: SHARE_TYPE.feed
          };
        }
      }
    }
  }

  handleEditPost(event: Post) {
    this.post = event;
    this.initData();

    if (!this.post.friend_tags) {
      this.headerPost.friend_tags = undefined;
    }
  }

  executeShare(event: number) {
    this.shareType = event;
    if (event === SHARE_TYPE.group) {
      this.visibleShareToGroupPopup = true;
    } else {
      this.visibleCreatePostDialog = true;
      this.cdr.detectChanges();
    }
  }

  deleteElement(event: any) {
    this.deletePost.emit(event);
  }

  archiveElement(event: any) {
    this.archivePost.emit(event);
  }

  unPinEmit() {
    this.unPinPostEmit.emit(this.post.id);
  }

  handleClickPost(postData: Post) {
    !this.isPostReview && this.analyticsService.pushPostAction(postData, ADS_ACTION.click);
  }

  handleHidePost(e: { id: string; isReport: boolean }) {
    const arr = localStorage.getItem('hiddenPosts');
    let hideArr = [];
    if (arr) {
      hideArr = JSON.parse(arr);
    }
    const hiddenIndex = hideArr.findIndex((item: any) => item.id === e.id);
    if (hiddenIndex === -1) {
      hideArr.push(e);
    } else if (e.isReport !== hideArr[hiddenIndex].isReport) {
      hideArr[hiddenIndex].isReport = e.isReport;
    }
    this.isHidePost = e.isReport && !this.isNewsfeed ? false : true;
    localStorage.setItem('hiddenPosts', JSON.stringify(hideArr));
    this.isShowHideMessage = e.isReport;
    if (e.isReport) {
      const body = document.getElementsByTagName('body')[0];
      body.classList.add('overflow-hidden');
    }
    if (this.isPostView || this.isWatchScreen) {
      this.hidePostEvent.emit(e.id);
    }
  }

  showGiftCoin(event: any) {
    this.showManageCoinDialog = event;
  }

  onHideDialog() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('overflow-hidden');
  }

  handleUpdateStatusGiftCoin(valueCoin: number) {
    this.postFooter.totalCoins += valueCoin;
    this.postFooter.hasGiftedCoin = true;
  }

  onSwitchProfile(profile: any): void {
    this.selectedProfile = profile;
  }

  coinRecipientDataObject() {
    return !this.isPostView ? this.post : {...this.post, id: this.parentPostId};
  }
}
