import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiClient } from '@app/lib/api/api-client';
import { PollAPI, PollDataResponseModel, VoteUnVoteRequest } from '@app/lib/api/poll/api.poll.model';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PollService {
  private api: ApiClient;
  constructor(public readonly httpClient: HttpClient) {
    this.api = new ApiClient(this.httpClient, { responseTimeout: environment.API_TIMEOUT });
  }

  createPoll(params: any): Observable<any> {
    return this.api.poll.createPoll(params);
  }

  editPoll(params: any, poll_id: string): Observable<any> {
    return this.api.poll.editPoll(params, poll_id);
  }

  getPollData(pollId: string): Observable<PollDataResponseModel> {
    return this.api.poll.getPollData(pollId);
  }

  getPollDataToUpdate(pollId: string): Observable<PollDataResponseModel> {
    return this.api.poll.getPollDataToUpdate(pollId);
  }

  endPoll(pollId: string) {
    return this.api.poll.endPoll(pollId);
  }

  votePoll(body: VoteUnVoteRequest) {
    return this.api.poll.votePoll(body);
  }

  unVotePoll(body: VoteUnVoteRequest) {
    return this.api.poll.unVotePoll(body);
  }

  getPollOverview(pollId: string): Observable<PollAPI.PollOverviewResponse> {
    return this.api.poll.getPollOverview(pollId);
  }

  getPollActivities(pollId: string, answerId: string, params: any): Observable<PollAPI.PollActivitiesResponse> {
    return this.api.poll.getPollActivities(pollId, answerId, params);
  }

  voteByCoin(payload: PollAPI.VoteByCoinPayload): Observable<any> {
    return this.api.poll.voteByCoin(payload);
  }

  updatePriorityAnswer(pollId: string, answerId: string): Observable<any> {
    return this.api.poll.updatePriorityAnswer(pollId, answerId);
  }

  startPoll(pollId: string): Observable<any> {
    return this.api.poll.startPoll(pollId);
  }
}
