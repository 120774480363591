import { GetFanpagePinPostParams, PageAPI } from '@app/lib/api/page/api.page.model';
import { PostListPaging } from '@app/lib/api/post/api.post.model';
import { Post } from '@app/shared/models/post';
import { createAction, emptyProps, props } from '@ngrx/store';

// get post of fanpage
export enum fanpagePostTypes {
  getFanpagePost = '[Fanpage Post] get fanpage posts',
  getFanpagePollPost = '[Fanpage Post] get fanpage poll posts',
  getFanpagePostSuccess = '[Fanpage Post] get fanpage posts success',
  getFanpagePostFailed = '[Fanpage Post] get fanpage posts failed',
  updateNewFanpagePost = '[Fanpage Post] update new fanpage post',
  updateNewFanpagePollPost = '[Fanpage Post] update new fanpage poll post',
  getMoreFanpagePosts = '[Fanpage Post] get more fanpage posts',
  getMoreFanpagePollPosts = '[Fanpage Post] get more fanpage poll posts',
  getMoreFanpagePostsSuccess = '[Fanpage Post] get more fanpage posts success',
  getMoreFanpagePostsFailed = '[Fanpage Post] get more fanpage posts failed',
  getFanpagePendingPosts = '[Fanpage Post] get fanpage pending posts',
  getFanpagePendingPostsSuccess = '[Fanpage Post] get fanpage pending posts success',
  getFanpagePendingPostsFailed = '[Fanpage Post] get fanpage pending posts failed',

  createFanpagePost = '[Fanpage Post] create fanpage posts',
  createFanpagePostSuccess = '[Fanpage Post] create fanpage posts success',
  createFanpagePostFailed = '[Fanpage Post] create fanpage posts failed',

  // Delete post of fanpage
  deleteFanpagePost = '[Fanpage Post] delete a post on the fanpage',
  deleteFanpagePostSuccess = '[Fanpage Post] delete a post on the fanpage success',
  deleteFanpagePostFailed = '[Fanpage Post] delete a post on the fanpage failed',
  deleteFanpagePinnedPost = '[Fanpage Post] delete a pinned on the fanpage',

  // pin post of fanpage
  getPinnedPost = '[Fanpage Post] get pined post on the fanpage',
  getPinnedPostSuccess = '[Fanpage Post] get pined post on the fanpage success',
  unpinPost = '[Fanpage Post] unpin a post on the fanpage',
  unpinPostSuccess = '[Fanpage Post] unpin a post on the fanpage success',
  pinPost = '[Fanpage Post] pin a post on the fanpage',
  pinPostSuccess = '[Fanpage Post] pin a post on the fanpage success',
  unpinPollPost = '[Fanpage Post] unpin a poll post on the fanpage',
  unpinPollPostSuccess = '[Fanpage Post] unpin a poll post on the fanpage success',
  pinPollPost = '[Fanpage Post] pin a poll post on the fanpage',
  pinPollPostSuccess = '[Fanpage Post] pin a poll post on the fanpage success'
}

export const getFanpagePost = createAction(fanpagePostTypes.getFanpagePost, props<{ fanpageId: string }>());
export const getFanpagePollPost = createAction(fanpagePostTypes.getFanpagePollPost, props<{ fanpageId: string }>());

export const getFanpagePostSuccess = createAction(
  fanpagePostTypes.getFanpagePostSuccess,
  props<{ fanpagePostsPaging: PostListPaging; postType: string }>()
);
export const getFanpagePostFailed = createAction(fanpagePostTypes.getFanpagePostFailed, props<{ message: string }>());

export const updateNewFanpagePost = createAction(fanpagePostTypes.updateNewFanpagePost, props<{ post: Post }>());

export const updateNewFanpagePollPost = createAction(
  fanpagePostTypes.updateNewFanpagePollPost,
  props<{ post: Post }>()
);

export const getMoreFanpagePosts = createAction(
  fanpagePostTypes.getMoreFanpagePosts,
  props<{ fanpageId: string; pageNum: number }>()
);

export const getMoreFanpagePollPosts = createAction(
  fanpagePostTypes.getMoreFanpagePollPosts,
  props<{ fanpageId: string; pageNum: number }>()
);

export const getMoreFanpagePostsSuccess = createAction(
  fanpagePostTypes.getMoreFanpagePostsSuccess,
  props<{ fanpagePostsPaging: PostListPaging }>()
);
export const getMoreFanpagePostsFailed = createAction(
  fanpagePostTypes.getMoreFanpagePostsFailed,
  props<{ message: string }>()
);

export const getFanpagePendingPosts = createAction(
  fanpagePostTypes.getFanpagePendingPosts,
  props<{ fanpageId: string }>()
);
export const getFanpagePendingPostsSuccess = createAction(
  fanpagePostTypes.getFanpagePendingPostsSuccess,
  props<{ fanpagePendingPostsPaging: PostListPaging }>()
);
export const getFanpagePendingPostsFailed = createAction(
  fanpagePostTypes.getFanpagePendingPostsFailed,
  props<{ message: string }>()
);

//create post for fanpage
export const createFanpagePost = createAction(fanpagePostTypes.createFanpagePost, props<{ post: any }>());
export const createFanpagePostSuccess = createAction(
  fanpagePostTypes.createFanpagePostSuccess,
  props<{ postResponse: any }>()
);
export const createFanpagePostFailed = createAction(
  fanpagePostTypes.createFanpagePostFailed,
  props<{ message: string }>()
);

// Delete post for fanpage
export const deleteFanpagePost = createAction(
  fanpagePostTypes.deleteFanpagePost,
  props<{ id: string; isPinned: boolean }>()
);
export const deleteFanpagePostSuccess = createAction(
  fanpagePostTypes.deleteFanpagePostSuccess,
  props<{ id: string }>()
);
export const deleteFanpagePostFailed = createAction(
  fanpagePostTypes.deleteFanpagePostFailed,
  props<{ message: string }>()
);
export const deleteFanpagePinnedPost = createAction(fanpagePostTypes.deleteFanpagePinnedPost, emptyProps);

// Pin post for fanpage
export const getFanpagePinedPost = createAction(
  fanpagePostTypes.getPinnedPost,
  props<{ params: GetFanpagePinPostParams }>()
);
export const getFanpagePinedPostSuccess = createAction(
  fanpagePostTypes.getPinnedPostSuccess,
  props<{ fanpagePostsPaging: PostListPaging }>()
);

export const unpinPost = createAction(
  fanpagePostTypes.unpinPost,
  props<{ isPinned: boolean; postId: string; objectType: string }>()
);
export const unpinPostSuccess = createAction(fanpagePostTypes.unpinPostSuccess, emptyProps);

export const pinPost = createAction(
  fanpagePostTypes.pinPost,
  props<{ postId: string; isPinned: true; post: Post; index: number; objectType: string }>()
);
export const pinPostSuccess = createAction(fanpagePostTypes.pinPostSuccess, props<{ index: number; post: Post }>());

export const unpinPollPost = createAction(
  fanpagePostTypes.unpinPollPost,
  props<{ id: string; isPin: boolean; index: number }>()
);
export const unpinPollPostSuccess = createAction(fanpagePostTypes.unpinPollPostSuccess, props<{ index: number }>());

export const pinPollPost = createAction(
  fanpagePostTypes.pinPollPost,
  props<{
    id: string;
    isPin: boolean;
    post: Post;
    index: number;
  }>()
);
export const pinPollPostSuccess = createAction(
  fanpagePostTypes.pinPollPostSuccess,
  props<{ index: number; post: Post }>()
);

// fanpage follower
export enum fanpageFollowerTypes {
  getFanpageFollower = '[Fanpage Follower] get fanpage followers',
  getFanpageFollowerSuccess = '[Fanpage Follower] get fanpage follower success',
  getFanpageFollowerFailed = '[Fanpage Follower] get fanpage follower failed',
  filterBlockedFollower = '[Fanpage Follower] Filter Blocked Follower'
}

export const getFanpageFollower = createAction(
  fanpageFollowerTypes.getFanpageFollower,
  props<{
    typeService?: string;
    typeId: string | null;
    pageNum?: number;
    textSearch?: string | null;
  }>()
);

export const getFanpageFollowerSuccess = createAction(
  fanpageFollowerTypes.getFanpageFollowerSuccess,
  props<{ fanpageFollower: any }>()
);

export const getFanpageFollowerFailed = createAction(
  fanpageFollowerTypes.getFanpageFollowerFailed,
  props<{ message: string }>()
);

export const filterBlockedFollower = createAction(
  fanpageFollowerTypes.filterBlockedFollower,
  props<{ blockedUserId: string }>()
);

export enum fanpageOverviewTypes {
  getFanpageOverview = '[Fanpage] overview info detail fanpage',
  getFanpageOverviewSuccess = '[Fanpage] overview info detail fanpage success',
  getFanpageOverviewFailure = '[Fanpage] overview info detail fanpage failure'
}

export const getFanpageOverview = createAction(fanpageOverviewTypes.getFanpageOverview, props<{ fanPageId: string }>());
export const getFanpageOverviewSuccess = createAction(
  fanpageOverviewTypes.getFanpageOverviewSuccess,
  props<{ fanpageOverview: PageAPI.PageOverview }>()
);
export const getFanpageOverviewFailure = createAction(
  fanpageOverviewTypes.getFanpageOverviewFailure,
  props<{ message: string }>()
);

export enum fanpageContactInfoTypes {
  getFanpageContactInfo = '[Fanpage] contact info detail fanpage',
  getFanpageContactInfoSuccess = '[Fanpage] contact info detail fanpage success',
  getFanpageContactInfoFailure = '[Fanpage] contact info detail fanpage failure'
}

export const getFanpageContactInfo = createAction(
  fanpageContactInfoTypes.getFanpageContactInfo,
  props<{ fanPageId: string }>()
);
export const getFanpageContactInfoSuccess = createAction(
  fanpageContactInfoTypes.getFanpageContactInfoSuccess,
  props<{ fanpageContactInfo: PageAPI.PageContactInfo }>()
);
export const getFanpageContactInfoFailure = createAction(
  fanpageContactInfoTypes.getFanpageContactInfoFailure,
  props<{ message: string }>()
);

export enum fanpageInfoTypes {
  getFanpageInfo = '[Fanpage Info] get fanpage info',
  getFanpageInfoSuccess = '[Fanpage Info] get fanpage info success',
  getFanpageInfoFailed = '[Fanpage Info] get fanpage info failed'
}

export const getFanpageInfo = createAction(
  fanpageInfoTypes.getFanpageInfo,
  props<{ fanpageId: string | null; fanpageName?: string | null }>()
);

export const getFanpageInfoSuccess = createAction(
  fanpageInfoTypes.getFanpageInfoSuccess,
  props<{ fanpageInfo: PageAPI.PageInfo | null }>()
);

export const getFanpageInfoFailed = createAction(fanpageInfoTypes.getFanpageInfoFailed, props<{ message: string }>());

//fanpage block list
export enum fanpageBlockListTypes {
  getBlockList = '[Fanpage Block List] get fanpage block list',
  getBlockListSuccess = '[Fanpage Block List] get fanpage block list success',
  getBlockListFailed = '[Fanpage Block List] get fanpage block list failed',

  filterUnblockedUser = '[Fanpage Block List] Filter Unblocked User'
}

export const getBlockList = createAction(
  fanpageBlockListTypes.getBlockList,
  props<{ fanpageId: string | null; textSearch?: string | null; pageNum?: number; pageSize?: number }>()
);

export const getBlockListSuccess = createAction(fanpageBlockListTypes.getBlockListSuccess, props<{ blockList: any }>());

export const getBlockListFailed = createAction(fanpageBlockListTypes.getBlockListFailed, props<{ message: string }>());

export const filterUnblockedUser = createAction(
  fanpageBlockListTypes.filterUnblockedUser,
  props<{ unblockedUsers: string[] }>()
);

//fanpage follower roles
export enum fanpageFollowerRolesTypes {
  getFollowerRoles = '[Fanpage Follower Roles] get fanpage follower roles',
  getFollowerRolesSuccess = '[Fanpage Follower Roles] get fanpage follower roles success',
  getFollowerRolesFailed = '[FFanpage Follower Roles] get fanpage follower roles failed'
}

export const getFollowerRoles = createAction(
  fanpageFollowerRolesTypes.getFollowerRoles,
  props<{ fanpageId: string | null }>()
);

export const getFollowerRolesSuccess = createAction(
  fanpageFollowerRolesTypes.getFollowerRolesSuccess,
  props<{ followerRoles: any }>()
);

export const getFollowerRolesFailed = createAction(
  fanpageFollowerRolesTypes.getFollowerRolesFailed,
  props<{ message: string }>()
);

export enum fanPageProfileTypes {
  getCategory = '[Category] fetch',
  getCategorySuccess = '[Category] fetch success',
  fetchCategoryFailure = '[Category] fetch failure',

  getPageProfiles = '[Page Profiles] get page profiles',
  getPageProfilesSuccess = '[Page Profiles] get page profiles success',
  getPageProfilesFailed = '[Page Profiles] get page profiles failed'
}

export const getPageProfiles = createAction(
  fanPageProfileTypes.getPageProfiles,
  props<{ pageNum: number; pageSize: number; searchText?: any; isSearching?: boolean; showError?: boolean }>()
);

export const getPageProfilesSuccess = createAction(
  fanPageProfileTypes.getPageProfilesSuccess,
  props<{ pageProfiles: any; isSearching?: boolean }>()
);

export const getPageProfilesFailed = createAction(fanPageProfileTypes.getPageProfilesFailed, props<{ message: any }>());

export const getCategory = createAction(fanPageProfileTypes.getCategory, emptyProps);

export const getCategorySuccess = createAction(
  fanPageProfileTypes.getCategorySuccess,
  props<{ getAllCategory: any }>()
);

export const getCategoryFailure = createAction(fanPageProfileTypes.fetchCategoryFailure, props<{ message: string }>());

export enum fanpageRoleTypes {
  getFanpageRole = '[Fanpage Role] get fanpage role',
  getFanpageRoleSuccess = '[Fanpage Role] get fanpage role success',
  getFanpageRoleFailed = '[Fanpage Role] get fanpage role failed',
  updateIsFollowFanpage = '[Fanpage Role] Update Is Follow Fanpage'
}

export const getFanpageRole = createAction(fanpageRoleTypes.getFanpageRole, props<{ fanpageId: string | null }>());

export const getFanpageRoleSuccess = createAction(
  fanpageRoleTypes.getFanpageRoleSuccess,
  props<{ fanpageRole: any }>()
);

export const getFanpageRoleFailed = createAction(fanpageRoleTypes.getFanpageRoleFailed, props<{ message: string }>());

export const updateIsFollowFanpage = createAction(
  fanpageRoleTypes.updateIsFollowFanpage,
  props<{ isFollower: boolean }>()
);

export enum fanpageCategories {
  getAllCategories = '[Fanpage Categories] get all fanpage categories',
  getAllCategoriesSuccess = '[Fanpage Categories] get all fanpage categories success',
  getAllCategoriesFailed = '[Fanpage Categories] get all fanpage categories failed'
}

export const getAllCategories = createAction(fanpageCategories.getAllCategories, emptyProps);

export const getAllCategoriesSuccess = createAction(
  fanpageCategories.getAllCategoriesSuccess,
  props<{ allCategories: any }>()
);

export const getAllCategoriesFailed = createAction(
  fanpageCategories.getAllCategoriesFailed,
  props<{ message: string }>()
);
