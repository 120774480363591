<div *ngIf="visible" class="fixed inset-0 flex justify-center items-center">
  <div
    class="bg-white text-black p-0.5 w-[400px] rounded-xl shadow-lg flex flex-col justify-between relative max-w-[85vw]">
    <img src="/assets/images/banner-anonymus.png" alt="Banner" class="w-full h-auto rounded-xl" />

    <button (click)="closePopup()" class="absolute top-[-12px] right-[-12px] bg-slate-200 rounded-full p-2 shadow-lg">
      <img src="/assets/images/btn-close.svg" alt="Close" class="w-6 h-6" />
    </button>

    <div class="text-center mt-4">
      <h3 class="heading text-xl font-semibold">{{ title }}</h3>
    </div>
    <div class="text-center mt-2">
      <p class="text-gray-600">{{ description }}</p>
    </div>

    <div class="flex justify-center m-6">
      <button
        class="bg-blue-500 text-white py-2 px-4 w-full h-[48px] rounded-lg hover:bg-blue-600"
        routerLink="/sign-up">
        {{ 'SIGNIN_SIGNUP.SIGN_UP_NOW' | translate }}
      </button>
    </div>
  </div>
</div>
