<div class="rounded-full ring-2 ring-white" [ngStyle]="{ 'width': avatarSize + 'px', 'height': avatarSize + 'px' }">
  <div
    class="rounded-full p-[2px] ring-2"
    [style]="{
      '--tw-ring-color': isUseLogoSoctrip
        ? '#3b82f6'
        : colorRank && isLoyalty && !isSelectedPageProfile()
        ? colorRank
        : '#FF7041'
    }">
    <div
      (click)="isSelectedPageProfile() ? navigateToPage() : navigateToProfile()"
      class="relative cursor-pointer"
      [ngStyle]="{ 'width': avatarSize - 4 + 'px', 'height': avatarSize - 4 + 'px' }">
      <div
        class="rounded-full ring-2 ring-transparent"
        [ngStyle]="{ 'width': avatarSize - 4 + 'px', 'height': avatarSize - 4 + 'px' }">
        <p-skeleton
          shape="circle"
          [styleClass]="'w-full h-full'"
          *ngIf="userAvatarUrl === '' && !showPageAvatar"></p-skeleton>
        <img
          *ngIf="userAvatarUrl"
          class="rounded-full w-full h-full"
          onerror="this.src='assets/images/default_user_avatar.png'"
          [src]="userAvatarUrl" />
        <div *ngIf="!isUseLogoSoctrip && isLoyalty && !isSelectedPageProfile()">
          <img
            class="-top-2 -right-2 w-4 h-4 absolute rounded-full bg-palette-base-white"
            [src]="getImageUrl(iconRank)" />
        </div>
        <p-avatar
          *ngIf="showPageAvatar"
          styleClass="rounded-full w-full h-full bg-palette-blue-50 text-palette-blue-600 font-bold text-xs"
          [label]="selectedProfile?.page_name | abbreviate: 'fanpage'"></p-avatar>
        <!-- Only display the avatar frame if it is a user profile -->
        <img
          onerror="this.style.display = 'none'"
          *ngIf="userFrameUrl && !isSelectedPageProfile()"
          class="rounded-full absolute top-0"
          [src]="userFrameUrl" />
      </div>
    </div>
    <div
      *ngIf="(hasPageProfiles$ | async) && showSwitchProfileOption"
      id="switch-profile-container"
      class="absolute rounded-full bg-white p-[2px] cursor-pointer z-10"
      [ngStyle]="{
        'width': avatarSize * avatarToProfileRatio + 'px',
        'height': avatarSize * avatarToProfileRatio + 'px',
        'right': avatarSize === avatarDefaultSize ? 0 + 'px' : -2 + 'px',
        'bottom': avatarSize === avatarDefaultSize ? 2 + 'px' : -2 + 'px'
      }">
      <div
        #switchProfileOption
        id="switch-profile-option"
        class="bg-palette-gray-300 rounded-full flex justify-center items-center"
        [ngStyle]="{
          'width': avatarSize * avatarToProfileRatio - 4 + 'px',
          'height': avatarSize * avatarToProfileRatio - 4 + 'px'
        }"
        (click)="onChangeShowProfileMenu()">
        <i
          class="sctr-icon-chevron-down text-palette-gray-900 font-bold"
          [ngStyle]="{
            'font-size': avatarSize === avatarDefaultSize ? 10 + 'px' : 7 + 'px'
          }"></i>
      </div>
    </div>
  </div>
  <ng-container
    *ngIf="showSwitchProfileMenu"
    [ngTemplateOutlet]="switchProfileMenu"
    [ngTemplateOutletContext]="{
      profiles: (pageProfile$ | async)?.data
    }"></ng-container>
</div>

<ng-template #switchProfileMenu let-profiles="profiles">
  <div #profileMenu class="switch-profile-menu" id="switch-profile-menu">
    <div id="switch-profile-header" class="flex gap-2 flex-col">
      <span class="font-semibold text-base text-palette-gray-700">
        {{ 'FANPAGE.SWITCH_PROFILE' | translate }}
      </span>
      <span class="font-normal text-palette-gray-600 text-xs">{{
        'FANPAGE.SWITCH_PROFILE_DESCRIPTION' | translate
      }}</span>
    </div>

    <span class="p-input-icon-left h-8 w-full grow">
      <i class="sctr-icon-search-lg"></i>
      <input
        type="text"
        pInputText
        [formControl]="profileSearchText"
        placeholder="{{ 'FANPAGE.SEARCH' | translate }}"
        class="w-full h-8 text-sm font-normal rounded-lg text-placeholder" />
    </span>

    <div id="switch-profile-content" class="flex flex-col gap-2">
      <div
        id="user-info-option"
        class="w-full flex items-center justify-between cursor-pointer hover:bg-palette-gray-200 hover:rounded-lg"
        (click)="onChangeProfile(userInfo)">
        <div class="flex items-center gap-3">
          <img
            *ngIf="userInfo?.avatar_thumbnail_url"
            class="rounded-full w-8 h-8"
            onerror="this.src='assets/images/default_user_avatar.png'"
            [src]="getImageUrl(userInfo?.avatar_thumbnail_url)" />

          <div id="user-profile-name" class="flex items-center gap-2">
            <span
              title="{{ userInfo?.full_name }}"
              class="font-medium text-sm text-palette-gray-900 whitespace-nowrap max-w-[280px] overflow-hidden text-ellipsis"
              >{{ userInfo?.full_name }}</span
            >
            <ng-container
              [ngTemplateOutlet]="verifiedBadge"
              [ngTemplateOutletContext]="{
                profile: userInfo
              }"></ng-container>
          </div>
        </div>
        <ng-container
          [ngTemplateOutlet]="switchProfileActions"
          [ngTemplateOutletContext]="{
            profile: userInfo
          }">
        </ng-container>
      </div>
      <ng-container *ngFor="let profile of profiles">
        <div
          class="w-full flex items-center justify-between cursor-pointer hover:bg-palette-gray-200 hover:rounded-lg"
          (click)="onChangeProfile(profile)">
          <div class="flex items-center gap-3">
            <img
              *ngIf="profile?.avatar; else showAvatarName"
              class="rounded-full w-8 h-8"
              onerror="this.src='assets/images/default_user_avatar.png'"
              [src]="getImageUrl(profile?.avatar)" />
            <ng-template #showAvatarName>
              <p-avatar
                styleClass="rounded-full w-8 h-8 bg-palette-blue-50 text-palette-blue-600 font-bold text-xs"
                [label]="profile?.page_name | abbreviate: 'fanpage'"></p-avatar>
            </ng-template>

            <div id="switch-profile-profile-name" class="flex items-center gap-2">
              <span
                title="{{ profile?.page_name }}"
                class="font-medium text-sm text-palette-gray-900 whitespace-nowrap max-w-[280px] overflow-hidden text-ellipsis"
                >{{ profile?.page_name }}</span
              >
              <ng-container
                [ngTemplateOutlet]="verifiedBadge"
                [ngTemplateOutletContext]="{
                  profile
                }"></ng-container>
            </div>
          </div>
          <ng-container
            [ngTemplateOutlet]="switchProfileActions"
            [ngTemplateOutletContext]="{
              profile
            }">
          </ng-container>
        </div>
      </ng-container>
      <span
        id="switch-profile-view-more-button"
        #viewMoreButton
        *ngIf="(pageProfile$ | async)?.totalElement > profiles?.length && !(isLoadingProfile$ | async)"
        class="text-sm text-palette-blue-600 font-semibold cursor-pointer"
        (click)="onViewMorePage()">
        {{ 'COMMON.VIEW_MORE' | translate }}</span
      >
      <div *ngIf="isLoadingProfile$ | async" id="spinning-icon">
        <i class="pi pi-spin sctr-icon-loading-02 text-palette-gray-600 text-lg ml-1"></i>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #switchProfileActions let-profile="profile">
  <i
    *ngIf="
      isSelectedPageProfile() ? profile?.page_id === selectedProfile?.page_id : profile?.id === selectedProfile?.id;
      else changeProfileIcon
    "
    class="sctr-icon-check text-sm text-palette-blue-600 mr-1"></i>
  <ng-template #changeProfileIcon>
    <i class="sctr-icon-refresh-cw-05 text-sm text-palette-gray-500 mr-1"></i>
  </ng-template>
</ng-template>

<ng-template #verifiedBadge let-profile="profile">
  <div *ngIf="showVerifiedBadge(profile)" class="flex justify-center items-center relative w-2 h-2">
    <i class="text-palette-base-white sctr-icon-solid-shield-01 absolute text-sm"></i>
    <i class="text-branding-primary-600 sctr-icon-solid-shield-tick absolute text-sm"></i>
  </div>
</ng-template>
