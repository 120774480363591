import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { catchError, map, Observable, timeout } from 'rxjs';
import { ApiClientConfig } from '../api-client.config';
import {
  AddAudienceEventModel,
  AddLookalikeAudienceBody,
  AddSavedAudienceBody,
  EditLookalikeAudienceBody,
  GetAudienceDetailResponse,
  GetAudienceEventsResponse,
  GetAudienceParams,
  GetAudienceResponsePaging,
  GetEstimateReachBody,
  GetEstimateReachResponse,
  GetGroupAdAudienceParams,
  GetGroupAdAudienceResponse,
  GetRegionalCountryParams,
  GetRegionalCountryResponse
} from './api.audience.models';

export class AudienceApi {
  private apiUrl: string = environment.baseURL;

  PIXEL = '/pixel';
  AUDIENCE = '/audiences';
  REGIONAL = '/regional';

  constructor(
    public readonly http: HttpClient,
    public config: ApiClientConfig
  ) {}

  getEventsAudience(): Observable<GetAudienceEventsResponse> {
    return this.http.get<GetAudienceEventsResponse>(`${this.apiUrl}${this.PIXEL}${this.AUDIENCE}/event-rules`).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  addAudience(body: AddAudienceEventModel): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}${this.PIXEL}${this.AUDIENCE}`, body).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  editAudience(body: AddAudienceEventModel, audienceId: string): Observable<any> {
    return this.http.put<any>(`${this.apiUrl}${this.PIXEL}${this.AUDIENCE}/custom-audience/${audienceId}`, body).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getAudiences(params: GetAudienceParams): Observable<GetAudienceResponsePaging> {
    return this.http
      .get<GetAudienceResponsePaging>(`${this.apiUrl}${this.PIXEL}${this.AUDIENCE}`, { params: { ...params } })
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getAudienceDetail(audienceId: string): Observable<GetAudienceDetailResponse> {
    return this.http.get<GetAudienceDetailResponse>(`${this.apiUrl}${this.PIXEL}${this.AUDIENCE}/${audienceId}`).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getEstimateReach(body: GetEstimateReachBody): Observable<GetEstimateReachResponse> {
    return this.http
      .post<GetEstimateReachResponse>(`${this.apiUrl}${this.PIXEL}${this.AUDIENCE}/lookalike-estimated-reach`, body)
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getRegionalCountry(params: GetRegionalCountryParams): Observable<GetRegionalCountryResponse> {
    return this.http
      .get<GetRegionalCountryResponse>(`${this.apiUrl}${this.REGIONAL}${this.REGIONAL}/country`, {
        params: { ...params }
      })
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  addLookalikeAudience(body: AddLookalikeAudienceBody): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}${this.PIXEL}${this.AUDIENCE}/lookalike-audience`, body).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  editLookalikeAudience(body: EditLookalikeAudienceBody, audienceId: string): Observable<any> {
    return this.http
      .put<any>(`${this.apiUrl}${this.PIXEL}${this.AUDIENCE}/lookalike-audience/${audienceId}`, body)
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  deleteAudience(audienceId: string): Observable<any> {
    return this.http.delete<any>(`${this.apiUrl}${this.PIXEL}${this.AUDIENCE}/${audienceId}`).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  addSavedAudience(body: AddSavedAudienceBody): Observable<GetAudienceDetailResponse> {
    return this.http
      .post<GetAudienceDetailResponse>(`${this.apiUrl}${this.PIXEL}${this.AUDIENCE}/saved-audience`, body)
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  editSavedAudience(body: AddSavedAudienceBody, audienceId: string): Observable<GetAudienceDetailResponse> {
    return this.http
      .put<GetAudienceDetailResponse>(`${this.apiUrl}${this.PIXEL}${this.AUDIENCE}/saved-audience/${audienceId}`, body)
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getGroupAdAudience(params: GetGroupAdAudienceParams, audienceId: string): Observable<GetGroupAdAudienceResponse> {
    return this.http
      .get<GetGroupAdAudienceResponse>(`${this.apiUrl}${this.PIXEL}${this.AUDIENCE}/${audienceId}/group-ads`, {
        params: { ...params }
      })
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }
}
