import { Location } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommentsService } from '@app/core/services/comments.service';
import { ReactionsService } from '@app/core/services/reactions.service';
import { ToastMessageService } from '@app/core/services/toast-message.service';
import { TranslationService } from '@app/core/services/translation.service';
import { Comment } from '@app/lib/api/comments/api.comments.model';
import { TranslateParamModel } from '@app/lib/api/translate/api.translate.model';
import { DetailPostActions } from '@app/modules/main/states/detail-post/detail-post.actions';
import { selectReactionComment } from '@app/modules/main/states/detail-post/detail-post.selectors';
import { BlockUserActions, UserInfoActions } from '@app/modules/main/states/users/users.actions';
import {
  selectBlockUserData,
  selectBlockedUserBloomString,
  selectBlockingUserBloomString
} from '@app/modules/main/states/users/users.selectors';
import {
  FILE_EXTENSION,
  MAX_QTY_REACTIONS_STORAGE,
  MAX_TRUNCATED_TEXT_HEIGHT_COMMENT,
  MEDIA_TYPE,
  OWNER_TYPE,
  POST_TYPE,
  PRIVACY,
  TOAST_MESSAGE_LIFETIME_DURATIONS,
  TOAST_MESSAGE_SEVERITY_LEVELS
} from '@app/shared/constant';
import {
  ENTITY_TYPE,
  EmojiList,
  FILE_TYPE_URL,
  Media,
  MediaComment,
  MyReaction,
  Post,
  Reaction,
  ReactionInfo,
  ReactionType,
  ReactionZIndex,
  checkUrl
} from '@app/shared/models/post';
import { UserInfo } from '@app/shared/models/user';
import { GetDatePipe } from '@app/shared/pipes/getdatetime.pipe';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { MenuItem } from 'primeng/api';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'comment-content',
  templateUrl: './comment-content.component.html',
  styleUrls: ['./comment-content.component.scss']
})
export class CommentContentComponent implements OnInit, AfterViewInit {
  @Input() commentData: Comment;
  @Input() isChild = false;
  @Input() loginUser: UserInfo;
  @Input() postOwnerId: string;
  @Input() isLivestream = false;
  @Input() isLiveView = false;
  @Input() isWidthFull = false;
  @Input() isStory = false;
  @Input() isPostView = false;
  @Input() hideReply = false;
  @Input() selectedProfile: any;
  @Input() pageReactionInfo = [];
  @Output() isShowCommentForm = new EventEmitter();
  @Output() deletedCommentId = new EventEmitter<string>();
  @Output() showReportPopup = new EventEmitter();
  @Output() hideComment = new EventEmitter();

  selectedEmoji: any;
  timeOutData: any;
  overlayVisible = false;
  emojiList = EmojiList;
  reactedInfo: ReactionInfo;
  totalReactions = 0;
  commentOptions: MenuItem[] = [
    {
      id: 'report',
      label: 'COMMON.REPORT',
      icon: 'sctr-icon-alert-circle',
      iconClass: 'text-palette-gray-700',
      visible: false,
      command: () => {
        this.showReportPopup.emit();
      }
    },
    {
      id: 'edit',
      label: 'COMMON.BUTTON_ACTION.EDIT',
      icon: 'sctr-icon-edit-05',
      iconClass: 'text-palette-gray-700',
      visible: false,
      command: () => {
        this.goToCommentForm();
      }
    },
    {
      id: 'block',
      label: 'COMMON.BLOCK',
      icon: 'sctr-icon-slash-circle-01',
      iconClass: 'text-palette-gray-700',
      visible: false,
      command: () => {
        this.isShowBlockUser = true;
      }
    },
    {
      id: 'hide',
      label: 'COMMON.HIDE',
      escape: false,
      icon: 'sctr-icon-x-square',
      iconClass: 'text-palette-gray-700',
      visible: false,
      command: () => {
        this.hideComment.emit();
      }
    },
    {
      id: 'delete',
      label: 'COMMON.BUTTON_ACTION.DELETE',
      escape: false,
      icon: 'sctr-icon-trash-02',
      iconClass: 'text-palette-red-500',
      visible: false,
      command: () => {
        this.deleteComment();
      }
    }
  ];
  selectBlockUserData$ = this.store.select(selectBlockUserData);
  isEdit = false;
  reactionList: Reaction[] = [];
  reactId: string;
  reactionUser: { [key: string]: { id: string; full_name: string }[] };
  isShowAllContent = false;
  MAX_TRUNCATED_TEXT_HEIGHT_COMMENT = MAX_TRUNCATED_TEXT_HEIGHT_COMMENT;
  contentHeight = 0;
  FILE_TYPE_URL = FILE_TYPE_URL;
  displayMedia = false;
  activeIndex = 0;
  clonedMediaArr: MediaComment[] = [];
  timeToGetfromNow = '';
  mediaList: Media[] = [];
  postComment: Post;
  appendTo: ElementRef;
  isReferCommentId = false;
  isShowImage = true;
  retryCount: number = 0;
  maxRetries: number = 5;
  retryInterval: number = 5000;
  isRetry: boolean = false;
  isModerateStory = false;
  isTranslate: boolean = false;
  textTranslated: string;
  isOpenPopupLogin = false;
  isLoggedIn = false;
  isShowBlockUser = false;
  messageConfirm = '';
  showIconPlay = false;
  allowNavigation = true;
  isDisableEditComment = false;

  constructor(
    private reactionsService: ReactionsService,
    private commentService: CommentsService,
    private translationService: TranslationService,
    private el: ElementRef,
    private cdr: ChangeDetectorRef,
    private getDatePipe: GetDatePipe,
    private route: ActivatedRoute,
    private location: Location,
    private toastMessageService: ToastMessageService,
    private store: Store
  ) {}

  ngAfterViewInit(): void {
    this.contentHeight = this.el.nativeElement.querySelector('.comment-content')?.offsetHeight | 0;
    if (this.commentData.medias) {
      this.clonedMediaArr = [...this.commentData.medias];
    }
    if (!this.isPostView) {
      this.store.select(selectReactionComment).subscribe(data => {
        if (data.id && data.id === this.commentData.id) {
          this.commentData.reactions = data.reactions;
          this.ngOnInit();
          this.store.dispatch(DetailPostActions.onUpdateReactionComment({ info: {} }));
        }
      });
    }
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    const authStatus = JSON.parse(localStorage.getItem('auth_status') || '[]');
    this.isLoggedIn = authStatus.isLoggedIn;
    this.timeToGetfromNow = this.commentData.created_at;
    if (this.commentData.medias) {
      this.setMediaList(this.commentData.medias);
    }
    this.convertData();
    this.getReactionList();
    // Reaction data for the profile page will be retrieved via API calls
    // instead of being cached like the user profile.
    if (this.isSelectedPageProfile()) {
      this.getPageCurrentReactions();
    } else {
      this.getCurrentReaction();
    }
    this.commentOptions.forEach((item, index) => {
      if (item.label) {
        item.label = this.translationService.getTranslation(item.label);
        if (index === this.commentOptions.length - 1) {
          item.label = `<span class="text-palette-red-500"">${this.translationService.getTranslation(
            item.label || ''
          )}</span>`;
        }
      }
    });
    if (this.route.snapshot.queryParams['comment-id']) {
      const commentId = this.route.snapshot.queryParams['comment-id'];
      if (this.commentData.id === commentId) {
        this.isReferCommentId = true;
      }
    }
    this.isModerateStory = this.route.snapshot.queryParams['admin'] === 'true';
    if (this.isModerateStory) this.isShowAllContent = true;

    combineLatest([
      this.store.select(selectBlockedUserBloomString),
      this.store.select(selectBlockingUserBloomString)
    ]).subscribe(([blockedString, blockingString]) => {
      this.allowNavigation = !(
        this.handleBloomFilter(
          blockedString,
          this.isPageComment ? this.commentData?.page_object?.page_id : this.commentData.created_by.id
        ) ||
        this.handleBloomFilter(
          blockingString,
          this.isPageComment ? this.commentData?.page_object?.page_id : this.commentData.created_by.id
        )
      );
    });
  }

  handleBloomFilter(bloomString: string, userId: string | any) {
    if (bloomString === '') return false;
    const { BloomFilter } = require('node_modules/soctrip-algorithm/dist/api.js');
    const bloomFilter = BloomFilter.load(bloomString);
    return bloomFilter.has(userId);
  }

  handleVideo(item: any) {
    return {
      original: item.id,
      thumbnail: item.id,
      type: MEDIA_TYPE.video,
      file: '',
      upload: false
    };
  }

  confirmBlockUser() {
    this.store.dispatch(
      BlockUserActions.onBlockUser({
        userId: this.commentData.created_by.id,
        fullName: this.commentData.created_by.full_name
      })
    );
    this.selectBlockUserData$.subscribe(data => {
      if (data.userBlockedId === this.commentData.created_by.id) {
        window.location.reload();
      }
    });
    this.isShowBlockUser = false;
  }

  cancelBlockUser() {
    this.isShowBlockUser = false;
  }

  deleteComment() {
    this.commentService.deleteComment(this.commentData.id).subscribe(res => {
      if (res) {
        if (this.loginUser?.id === this.commentData?.created_by?.id) {
          this.store.dispatch(UserInfoActions.onGetCommentBloomFilter());
        }
        this.deletedCommentId.emit(this.commentData.id);
        this.toastMessageService.addToastMessage(
          TOAST_MESSAGE_SEVERITY_LEVELS.success,
          this.translationService.getTranslation('ERROR.COMMENT.DELETE_SUCCESSFUL'),
          TOAST_MESSAGE_LIFETIME_DURATIONS.medium
        );
      } else {
        this.toastMessageService.addToastMessage(
          TOAST_MESSAGE_SEVERITY_LEVELS.error,
          this.translationService.getTranslation('ERROR.COMMENT.DELETE_FAILED'),
          TOAST_MESSAGE_LIFETIME_DURATIONS.medium
        );
      }
    });
  }

  editComment(event: any) {
    this.isDisableEditComment = true;
    if (event) {
      const data = {
        ...this.commentData,
        content: event.content,
        message_ranges: event.messageRanges,
        medias: event.medias,
        preview_data: event.preview_data
      };
      this.commentService.putComment(data).subscribe(
        res => {
          if (res) {
            this.commentData = res;
            if (event.mediaLocal !== '') {
              res.medias[0].id = event.mediaLocal;
            }
            if (this.commentData.medias) {
              this.setMediaList(this.commentData.medias);
            }
            this.convertData();
            this.timeToGetfromNow = res.created_at;
            this.isEdit = false;
            this.isTranslate = false;
            this.toastMessageService.addToastMessage(
              TOAST_MESSAGE_SEVERITY_LEVELS.success,
              this.translationService.getTranslation('ERROR.COMMENT.EDIT_SUCCESSFUL'),
              TOAST_MESSAGE_LIFETIME_DURATIONS.medium
            );
            if (this.isPostView) {
              this.store.dispatch(
                DetailPostActions.onUpdateComments({
                  data: { postId: this.commentData.object_id, total_comments: 0, isReload: false }
                })
              );
            }
          } else {
            this.toastMessageService.addToastMessage(
              TOAST_MESSAGE_SEVERITY_LEVELS.error,
              this.translationService.getTranslation('ERROR.COMMENT.EDIT_FAILED'),
              TOAST_MESSAGE_LIFETIME_DURATIONS.medium
            );
          }
          this.isDisableEditComment = false;
        },
        error => {
          this.toastMessageService.addToastMessage(
            TOAST_MESSAGE_SEVERITY_LEVELS.error,
            this.translationService.getTranslation('ERROR.COMMENT.EDIT_FAILED'),
            TOAST_MESSAGE_LIFETIME_DURATIONS.medium
          );
        }
      );
    }
  }

  showHideOverlayIcons(flag: boolean) {
    if (this.isLoggedIn) {
      if (!flag) {
        this.timeOutData = setTimeout(() => {
          this.overlayVisible = flag;
        }, 500);
      } else {
        clearTimeout(this.timeOutData);
        this.overlayVisible = flag;
      }
    }
  }

  getSelectedEmoji(type: string) {
    this.selectedEmoji = this.emojiList.find(el => el.type === type) ?? null;
  }

  showCommentForm(): void {
    if (this.isOpenPopupLogin) return;

    let replyTarget = null;
    const isDifferentPage = this.commentData?.page_object?.page_id !== this.selectedProfile?.page_id;
    const isDifferentUser = this.commentData?.created_by?.id !== this.loginUser.id;

    if (this.isPageComment) {
      // When the selected comment is from a different page,
      // and it differs from the profile currently selected for commenting.
      if (!this.isSelectedPageProfile() || isDifferentPage) {
        replyTarget = {
          id: this.commentData?.page_object?.page_id,
          full_name: this.commentData?.page_object?.page_name
        };
      }
    } else if (isDifferentUser || this.isSelectedPageProfile()) {
      // When the selected comment is from a different user,
      // and it differs from the profile selected for commenting.
      replyTarget = {
        id: this.commentData.created_by.id,
        full_name: this.commentData.created_by.full_name
      };
    }

    this.isShowCommentForm.emit(replyTarget);
  }

  formattedContent() {
    let content = this.commentData.content;
    content = this.replaceLinkTag(content);
    content = this.translationService.urlify(content).trim();
    return content.replace(/\n/g, '<br>');
  }

  replaceLinkTag(content: string) {
    let tempConent = content;
    if (this.commentData.message_ranges) {
      for (let i = this.commentData.message_ranges.length - 1; i >= 0; i--) {
        const element = this.commentData.message_ranges[i];
        const offset = element.offset;
        const length = element.length;
        const firstPart = tempConent.slice(0, offset);
        const secondPart = tempConent.slice(offset + length);

        let contentReplace = `<a href="/personal-profile/${
          element.entity_id
        }" class="text-sm font-semibold text-branding-primary-700 hover:cursor-pointer hover:underline">${tempConent.slice(
          offset,
          offset + length
        )}</a>`;
        if (this.isEdit) {
          contentReplace = `<span class="text-branding-primary-700">${tempConent.slice(
            offset,
            offset + length
          )}</span>`;
        }

        tempConent = firstPart + contentReplace + secondPart;
      }
    }
    return tempConent;
  }

  getZIndexReaction(i: number) {
    return ReactionZIndex(i);
  }

  showItemMenuComment(): void {
    const idToCheck = this.isSelectedPageProfile() ? this.selectedProfile?.page_id : this.loginUser.id;
    const ownerId =
      this.commentData?.owner_type === OWNER_TYPE.page
        ? this.commentData.page_object?.page_id
        : this.commentData.created_by.id;

    if (idToCheck === ownerId) {
      this.checkItemLabel(['edit', 'delete']);
    } else if (idToCheck === this.postOwnerId) {
      // Hide the "block" option for comments from pages or when a page profile is selected
      this.isPageComment || this.isSelectedPageProfile()
        ? this.checkItemLabel(['report', 'delete'])
        : this.checkItemLabel(['report', 'block', 'delete']);
    } else {
      this.checkItemLabel(['report', 'hide']);
    }
  }

  checkItemLabel(arrayLabel: string[]) {
    this.commentOptions.map((item: any) => {
      if (arrayLabel.includes(item.id)) {
        item.visible = true;
      }
    });
  }

  goToCommentForm() {
    this.isEdit = true;
    if (this.commentData.message_ranges) {
      this.commentData.message_ranges.forEach(el => {
        el.full_name = this.commentData.content.slice(el.offset, el.offset + el.length);
      });
    }
  }

  getReactionList() {
    this.reactionList = [];
    this.totalReactions = 0;
    if (this.commentData.reactions) {
      this.totalReactions = this.commentData.reactions.reduce((accumulator, object) => {
        return accumulator + object.count;
      }, 0);
      this.commentData.reactions.forEach((el: Reaction) => {
        if (el.count > 0) {
          this.reactionList.push({
            icon: this.emojiList.find(ele => ele.type === el.type)?.icon || '',
            type: el.type,
            count: el.count
          });
        }
      });
    }
  }

  getCurrentReaction() {
    const myReactionsStorage = localStorage.getItem('myReactions');
    if (myReactionsStorage) {
      const myReactionsInfo = JSON.parse(myReactionsStorage);
      const oldestTime = new Date(myReactionsInfo.oldestTime);
      const postedDate = new Date(this.commentData.updated_at);
      // Check if postedDate < oldestTime and if localStorage contain all reactions
      if (postedDate < oldestTime && myReactionsInfo.reactionList.length >= MAX_QTY_REACTIONS_STORAGE) {
        this.reactionsService.getMyReactionsByEntityId(this.commentData.id).subscribe(res => {
          this.getSelectedEmoji(res.reaction_type);
        });
      } else {
        const reactData = myReactionsInfo.reactionList.find(
          (el: MyReaction) => el.entity_id === this.commentData.id && el.owner_id === this.loginUser.id
        );
        if (reactData) {
          this.reactId = reactData.id;
          this.getSelectedEmoji(reactData.reaction_type);
        } else {
          this.selectedEmoji = null;
        }
      }
    }
  }

  getPageCurrentReactions(): void {
    const reactData = this.pageReactionInfo.find((el: MyReaction) => el.entity_id === this.commentData.id) as any;
    this.reactId = reactData?.id;
    this.getSelectedEmoji(reactData?.reaction_type);
  }

  deletePreviousEmoji(previousEmoji?: any) {
    this.reactionList.map((ele, index) => {
      if (ele.type === previousEmoji.type) {
        ele.count === 1 ? this.reactionList.splice(index, 1) : (ele.count -= 1);
      }
    });
  }

  addNewEmoji() {
    const existReact = this.reactionList.find(el => el.type === this.selectedEmoji.type);
    if (existReact) {
      this.reactionList.map(el => {
        el.count += 1;
      });
    } else {
      this.reactionList.push({
        icon: this.emojiList.find(el => el.type === this.selectedEmoji.type)?.icon || '',
        type: this.selectedEmoji.type,
        count: 1
      });
    }
  }

  executeReactionList(type?: string, previousEmoji?: any) {
    let arrDelHandle: string[] = [];
    let arrAddHandle: string[] = [];
    switch (type) {
      case 'delete':
        this.deletePreviousEmoji(previousEmoji);
        [previousEmoji.type, ReactionType.all].forEach((key: string) => {
          if (this.checkExistKey(key)) {
            arrDelHandle.push(key);
          }
        });
        break;
      case 'put':
        this.deletePreviousEmoji(previousEmoji);
        this.addNewEmoji();
        if (this.checkExistKey(previousEmoji.type)) {
          arrDelHandle = [previousEmoji.type];
        }
        if (this.checkExistKey(this.selectedEmoji.type)) {
          arrAddHandle = [this.selectedEmoji.type];
        }
        break;
      default:
        this.addNewEmoji();
        [this.selectedEmoji.type, ReactionType.all].forEach((key: string) => {
          if (this.checkExistKey(key)) {
            arrAddHandle.push(key);
          }
        });
        break;
    }
    if (arrDelHandle.length > 0) {
      arrDelHandle.forEach(itemDel => {
        const idToCompare = this.isSelectedPageProfile() ? this.selectedProfile?.page_id : this.loginUser.id;
        const index = this.reactionUser[itemDel].findIndex((res: any) => res.id === idToCompare);
        if (index !== -1) {
          this.reactionUser[itemDel].splice(index, 1);
        }
      });
    }
    if (arrAddHandle.length > 0) {
      arrAddHandle.forEach(itemAdd => {
        let data = {
          id: this.loginUser.id,
          full_name: this.loginUser.full_name
        };
        // Update data if it's page profile
        if (this.isSelectedPageProfile()) {
          data = {
            id: this.selectedProfile?.page_id,
            full_name: this.selectedProfile?.page_name
          };
        }
        this.reactionUser[itemAdd].unshift(data);
      });
    }
    if (this.isPostView) {
      this.store.dispatch(
        DetailPostActions.onUpdateComments({
          data: { postId: this.commentData.object_id, total_comments: 0, isReload: false }
        })
      );
    }
  }

  executedEmojiChanged(type?: string) {
    const myReactionsStorage = localStorage.getItem('myReactions');
    if (myReactionsStorage) {
      const myReactionsInfo = JSON.parse(myReactionsStorage);
      const previousEmoji = { ...this.selectedEmoji };
      this.overlayVisible = false;
      // Delete reaction
      if (!type) {
        this.reactionsService.deleteReactions(this.reactId).subscribe(() => {
          this.totalReactions -= 1;
          this.selectedEmoji = null;
          this.executeReactionList('delete', previousEmoji);
          myReactionsInfo.reactionList = myReactionsInfo.reactionList.filter(
            (el: MyReaction) => el.id !== this.reactId
          );
          localStorage.removeItem('myReactions');
          localStorage.setItem('myReactions', JSON.stringify(myReactionsInfo));
        });
        return;
      }

      let param = {
        entity_id: this.commentData.id,
        entity_type: ENTITY_TYPE.comment,
        reaction_type: type,
        owner_type: OWNER_TYPE.user,
        owner_id: this.loginUser.id
      };

      if (this.isSelectedPageProfile()) {
        param = { ...param, owner_type: OWNER_TYPE.page, owner_id: this.selectedProfile?.page_id };
      }
      this.reactionsService.postReactions(param).subscribe((res: ReactionInfo) => {
        if (res) {
          if (!this.selectedEmoji) {
            // Add new reaction
            this.totalReactions += 1;
            myReactionsInfo.reactionList.push({
              id: res.id,
              entity_id: this.commentData.id,
              reaction_type: type,
              updated_at: res.updated_at,
              owner_id: res?.owner_id,
              owner_type: res?.owner_type
            });
            this.getSelectedEmoji(res.reaction_type);
            this.executeReactionList();
          } else {
            // Update reaction
            myReactionsInfo.reactionList.map((el: MyReaction) => {
              if (el.id === res.id) {
                el.reaction_type = type;
              }
            });
            this.getSelectedEmoji(res.reaction_type);
            this.executeReactionList('put', previousEmoji);
          }
          this.reactId = res.id;
          localStorage.removeItem('myReactions');
          localStorage.setItem('myReactions', JSON.stringify(myReactionsInfo));
        }
      });
    }
  }

  onExpandText() {
    this.isShowAllContent = !this.isShowAllContent;
  }

  getReactedUsers(type: string = ReactionType.all) {
    let userList = '';
    if (this.checkExistKey(type)) {
      const label = type !== ReactionType.all ? EmojiList.find(el => el.type === type)?.label : '';
      const labelTranlate = label ? this.translationService.getTranslation(label) : '';
      userList = type !== ReactionType.all ? `<p class="text-base mb-2">${labelTranlate}</p>` : '';
      const reaction = this.reactionList.filter(item => item.type === type);
      for (let i = 0; i < this.reactionUser[type].length; i++) {
        const el = this.reactionUser[type][i];
        // Check and show max 9 reacted users in list
        if (i <= 8) {
          userList = userList + '<p class="text-sm">' + el.full_name + '</p>';
        }
      }
      if ((type !== ReactionType.all ? reaction[0].count : this.totalReactions) > 10) {
        const total = ((type !== ReactionType.all ? reaction[0].count : this.totalReactions) - 9).toString();
        userList =
          userList +
          `<p class="text-sm">${this.translationService
            .getTranslation('COMMON.AND')
            .toLowerCase()} ${total} ${this.translationService.getTranslation('POST.OTHER_PEOPLE')}...</p>`;
      }
    }
    return userList;
  }

  getReactionsByEntityId(type: string = ReactionType.all) {
    if (!this.checkExistKey(type)) {
      this.reactionsService
        .getReactionsByEntityId(this.commentData.id, type === ReactionType.all ? '' : type)
        .subscribe(res => {
          const data = res.map(el => {
            if (el?.owner_type === OWNER_TYPE.page) {
              // Update the reaction user list based on the owner_object if it belongs to a fanpage reaction.
              return {
                id: el?.owner_object?.page_id ?? '',
                full_name: el?.owner_object?.page_name ?? '',
                reaction_type: el.reaction_type,
                created_by: el.owner_object
              };
            }
            return {
              id: el.created_by.id,
              full_name: el.created_by.full_name,
              reaction_type: el.reaction_type,
              created_by: el.created_by
            };
          });
          this.reactionUser = { ...this.reactionUser, [type]: data };
        });
    }
  }

  checkExistKey(key: string) {
    return this.reactionUser?.hasOwnProperty(key);
  }

  createMediaUrl(urlString: string, style = FILE_TYPE_URL.thumbnail) {
    if (style === FILE_TYPE_URL.thumbnail) {
      return urlString.includes('blob:') ? urlString : checkUrl(urlString, style) + FILE_EXTENSION.image;
    } else {
      return urlString.includes('blob:') ? urlString : checkUrl(urlString, style) + FILE_EXTENSION.video;
    }
  }

  showImage(index: number) {
    this.activeIndex = index;
    this.displayMedia = true;
    this.location.go(`/photo?commentId=${this.postComment.id}&activeIndex=${this.activeIndex}`);
  }

  addOriginalPropertyToMedia(medias: MediaComment) {
    return { ...medias, original: medias.id };
  }

  isEditCommentChange(event: boolean) {
    this.isEdit = event;
    this.commentData.medias = [...this.clonedMediaArr];
  }

  getFromNow() {
    const commentUpdatedAt = moment(this.timeToGetfromNow);
    const now = moment();
    const timeDifferenceInSeconds = now.diff(commentUpdatedAt, 'seconds');
    if (timeDifferenceInSeconds <= 60) {
      return this.translationService.getTranslation('COMMENT.JUST_NOW');
    } else {
      return this.getDatePipe.transform(commentUpdatedAt, timeDifferenceInSeconds);
    }
  }

  setMediaList(medias: MediaComment[]) {
    this.mediaList = medias.map(el => {
      const type = el.type.startsWith('image/') ? MEDIA_TYPE.image : MEDIA_TYPE.video;
      const thumbnail = el.id.includes('blob:') ? el.id : el.id + el.extension;
      const original = el.id.includes('blob:') ? el.id : el.id + el.extension;
      return { type, thumbnail, original };
    });
  }

  convertData() {
    this.postComment = {
      ...this.commentData,
      user_object: this.commentData.created_by,
      post_privacy: PRIVACY.public,
      post_pattern: POST_TYPE.normal,
      post_type: POST_TYPE.user,
      posted_date: this.commentData.updated_at,
      status: '0',
      total_comments: this.commentData.total_replies
    } as any;
  }

  handleShowReportPopup(menu: any, event: any) {
    menu.toggle(event);
    this.showReportPopup.emit();
  }

  onTranslateText() {
    this.textTranslated = '';
    const selectedLangCode = this.translationService.getKeyLocalUserProfile('language')?.toLowerCase();
    this.isTranslate = !this.isTranslate;
    this.getTranslateText(selectedLangCode?.toLowerCase() || 'en');
    if (this.contentHeight > MAX_TRUNCATED_TEXT_HEIGHT_COMMENT) {
      this.isShowAllContent = true;
    }
  }

  getTranslateText(target: string) {
    let content = this.commentData.content;
    content = this.markHashtagsForTranslation(content);
    const params: TranslateParamModel = {
      q: content.trim(),
      source: 'auto', // TODO: Implement dectect language when `comment.language` is implemented
      target: target,
      format: 'html'
    };
    this.translationService.translateText(params).subscribe((res: any) => {
      if (res && res.success) {
        this.textTranslated = res?.data.translatedText;
      } else {
        this.isTranslate = false;
        this.toastMessageService.addToastMessage(
          TOAST_MESSAGE_SEVERITY_LEVELS.error,
          this.translationService.getTranslation('TRANSLATION.TRANSLATE_FAILED'),
          TOAST_MESSAGE_LIFETIME_DURATIONS.medium
        );
      }
    });
  }

  isShowTranslateButton(commentData: any) {
    if (!this.formattedContent()) return false;
    return this.translationService.checkLanguage(commentData?.content, commentData?.language);
  }

  translateContent() {
    return this.formatContentTranslation(this.textTranslated);
  }

  openPopup(event: any) {
    this.isOpenPopupLogin = event;
  }

  markHashtagsForTranslation(content: string) {
    let tempConent = content;
    if (this.commentData.message_ranges) {
      for (let i = this.commentData.message_ranges.length - 1; i >= 0; i--) {
        const element = this.commentData.message_ranges[i];
        const offset = element.offset;
        const length = element.length;
        const firstPart = tempConent.slice(0, offset);
        const secondPart = tempConent.slice(offset + length);
        const name = tempConent.slice(offset, offset + length)?.replace(' ', '_');

        let contentReplace = `<${name}_name></${name}_name>`;

        tempConent = firstPart + contentReplace + secondPart;
      }
    }
    tempConent = tempConent?.replace(/\n/g, '<br />');
    return tempConent;
  }

  formatContentTranslation(content: string) {
    let outputString = this.translationService.replaceHashtagTranslation(content);
    return this.replaceLinkTag(outputString);
  }

  isSelectedPageProfile(): boolean {
    // Check if the selected profile is a page profile.
    return !!this.selectedProfile?.page_id;
  }

  showVerifiedBadge(): boolean {
    return this.commentData?.owner_type === OWNER_TYPE.user
      ? !!this.commentData?.created_by?.is_verified_user
      : !!this.commentData?.page_object?.is_verified;
  }

  get isPageComment(): boolean {
    return this.commentData?.owner_type === OWNER_TYPE.page;
  }
}
