import { HttpClient } from "@angular/common/http";
import { environment } from "@env/environment";
import { ApiClientConfig } from "../api-client.config";
import { catchError, map, Observable, retry, timeout } from "rxjs";
import { ListRankModel } from './api.loyalty.models';
export class LoyaltyAPI {
    private apiUrl: string = environment.baseURL;
    PROFILE_PATH = '/loyalty-hub/';

    constructor(
        public readonly http: HttpClient,
        public config: ApiClientConfig
    ) {}

    getProfileRank(): Observable<any[]> {
      const url = `${this.apiUrl}${this.PROFILE_PATH}members/profile`;
      return this.http.get<any[]>(url).pipe(
        timeout(this.config.responseTimeout),
        retry(1),
        map(res => {
          return res;
        }),
        catchError(error => {
          throw error.error;
        })
      );
    }

    getListRank(): Observable<ListRankModel[]> {
      const url = `${this.apiUrl}${this.PROFILE_PATH}ranks/list/program/default`;
      return this.http.get<any[]>(url).pipe(
        timeout(this.config.responseTimeout),
        retry(1),
        map(res => {
          return res;
        }),
        catchError(error => {
          throw error.error;
        })
      );
    }
}  