import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '@app/core/services/common.service';
import { LoyaltyService } from '@app/core/services/loyalty.service';
import { ToastMessageService } from '@app/core/services/toast-message.service';
import { TranslationService } from '@app/core/services/translation.service';
import { getPageProfiles } from '@app/modules/main/fanpage/store/actions';
import {
  selectHasPageProfiles,
  selectLoadingProfiles,
  selectPageProfiles
} from '@app/modules/main/fanpage/store/selector';
import {
  AVATAR_DEFAULT_SIZE,
  AVATAR_TO_PROFILE_RATIO,
  FANPAGES,
  PAGE_NUM_DEFAULT,
  PAGE_SIZE_DEFAULT,
  PROFILE_PAGE_SIZE_DEFAULT,
  TOAST_MESSAGE_SEVERITY_LEVELS
} from '@app/shared/constant';
import { UserInfo } from '@app/shared/models/user';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { isEqual } from 'lodash-es';
import { debounceTime, Subject, Subscription, take, takeUntil } from 'rxjs';

@Component({
  selector: 'avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnChanges {
  hasPageProfiles$ = this.store.select(selectHasPageProfiles);
  pageProfile$ = this.store.select(selectPageProfiles);
  isLoadingProfile$ = this.store.select(selectLoadingProfiles);

  @Input() userInfo?: UserInfo | any;
  @Input() isSmallThumbnail = false;
  @Input() isDisableNavigate = false;
  @Input() avatarSize = AVATAR_DEFAULT_SIZE;
  @Input() isUseLogoSoctrip = false;
  @Input() showSwitchProfileOption = false;
  @Input() selectedProfile: any;
  @Output() switchProfileEvent = new EventEmitter();
  @ViewChild('switchProfileOption') switchProfileOptionRef: ElementRef;
  @ViewChild('viewMoreButton') viewMoreButtonRef: ElementRef;

  private destroy$ = new Subject<void>();
  scrollSub: Subscription;
  userAvatarUrl: string = '';
  userFrameUrl: string = '';
  baseUrl: string = environment.baseURL;
  isModerate = false;
  avatarToProfileRatio = AVATAR_TO_PROFILE_RATIO;
  avatarDefaultSize = AVATAR_DEFAULT_SIZE;
  showSwitchProfileMenu = false;

  profileSearchText = new FormControl('');
  viewMoreBtnRef: any;
  colorRank: string;
  iconRank: string;
  isLoyalty = true;
  constructor(
    private router: Router,
    private commonService: CommonService,
    private route: ActivatedRoute,
    private loyaltyService: LoyaltyService,
    private store: Store,
    private toastMessageService: ToastMessageService,
    private translateService: TranslationService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['userInfo'] && changes['userInfo'].currentValue) {
      setTimeout(() => {
        const userInfo = changes['userInfo'].currentValue;
        const loyaltyUser = this.loyaltyService.getPrimaryRingColor(userInfo?.loyalty_id);
        this.colorRank = loyaltyUser?.avatar_outline_stroke;
        this.iconRank = loyaltyUser?.image;
        if (!this.selectedProfile) {
          this.getAvatar(userInfo);
        }
        this.getFrame(userInfo);
      }, 500);
    }
  }

  ngOnInit(): void {
    this.isModerate = this.route.snapshot.queryParams['admin'] === 'true';
    // Update the correct avatar URL corresponding to the selected profile.
    if (this.selectedProfile) {
      const imgUrl = this.isSelectedPageProfile()
        ? this.selectedProfile?.avatar
        : this.selectedProfile?.avatar_thumbnail_url;
      this.userAvatarUrl = this.getImageUrl(imgUrl);
    } else {
      this.getAvatar(this.userInfo);
    }
    this.getFrame(this.userInfo);
    if (this.isUseLogoSoctrip) {
      this.userAvatarUrl = 'assets/images/soctrip-branding/soctrip-logo-blue.svg';
    }
    this.profileSearchText.valueChanges.pipe(debounceTime(300), takeUntil(this.destroy$)).subscribe(searchText => {
      this.store.dispatch(
        getPageProfiles({
          pageNum: PAGE_NUM_DEFAULT,
          pageSize: searchText ? PAGE_SIZE_DEFAULT : PROFILE_PAGE_SIZE_DEFAULT,
          searchText,
          isSearching: true
        })
      );
    });
  }

  navigateToProfile(): void {
    if (this.isModerate) return;
    if (!this.isDisableNavigate) {
      if (this.isUseLogoSoctrip) {
        window.open(environment.ECOMMERCE_URL, '_blank');
      } else {
        this.router.navigate(['/personal-profile', this.userInfo?.username || this.userInfo?.id]);
      }
    }
  }

  navigateToPage(): void {
    // Navigate to the fanpage instead of the user profile.
    localStorage.setItem('fanpageSelectedType', FANPAGES.switchIntoPageRole);
    this.router.navigate(['/fanpage', this.selectedProfile?.page_id]);
  }

  getAvatar(userInfo: any) {
    this.userAvatarUrl = this.commonService.getImageUrl(userInfo && userInfo.avatar_thumbnail_url);
  }

  getFrame(userInfo: any) {
    this.userFrameUrl = this.commonService.getImageUrl(userInfo && userInfo.frame_url);
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    event.stopPropagation();
    const profileMenu = document.getElementById('switch-profile-menu') as any;
    // Ensure that clicking outside the switch profile menu hides the menu.
    if (
      !profileMenu?.contains(event.target) &&
      !this.switchProfileOptionRef?.nativeElement?.contains(event.target) &&
      !isEqual(event.target, this.viewMoreBtnRef)
    ) {
      this.showSwitchProfileMenu = false;
      // Due to HostListener limitations, multiple events may be triggered at once.
      // A condition is added to prevent multiple API calls from being made simultaneously.
      if (this.profileSearchText.value) {
        this.profileSearchText.setValue('');
      }
    }
  }

  get showPageAvatar(): boolean {
    // Display the page avatar as initials when the selected profile is not a user profile
    // and the page has no avatar.
    return !this.userAvatarUrl && this.isSelectedPageProfile();
  }

  onChangeShowProfileMenu(): void {
    if (!this.selectedProfile) {
      this.selectedProfile = this.userInfo;
    }
    this.showSwitchProfileMenu = !this.showSwitchProfileMenu;
  }

  isSelectedPageProfile(): boolean {
    return !!this.selectedProfile?.page_id;
  }

  getImageUrl(url: string | undefined): string {
    return this.commonService.getImageUrl(url);
  }

  onChangeProfile(profile: any): void {
    this.selectedProfile = profile;
    const imgUrl = this.isSelectedPageProfile() ? profile?.avatar : profile?.avatar_thumbnail_url;
    const profileName = this.isSelectedPageProfile()
      ? this.selectedProfile?.page_name
      : this.selectedProfile?.full_name;
    const profileType = this.isSelectedPageProfile()
      ? this.translateService.getTranslation('LIVESTREAM.PAGE')
      : this.translateService.getTranslation('ROLES.USER');
    this.userAvatarUrl = this.getImageUrl(imgUrl);
    this.toastMessageService.addMessageWithTranslateParams(
      TOAST_MESSAGE_SEVERITY_LEVELS.success,
      'FANPAGE.SWITCH_PROFILE_SUCCESSFULLY',
      {
        profileName,
        profileType: (profileType || '').toLowerCase()
      }
    );
    // Emit the selected profile for the create post dialog whenever this value changes.
    this.switchProfileEvent.emit(this.selectedProfile);
    this.showSwitchProfileMenu = false;
  }

  onViewMorePage(): void {
    // Since the view more button reference template may sometimes be null, a temporary variable is created
    // to accurately detect if a click occurs outside the menu. This step also ensures that the viewMoreButtonRef
    // will have a valid value.
    this.viewMoreBtnRef = this.viewMoreButtonRef.nativeElement;
    this.pageProfile$.pipe(take(1)).subscribe(res => {
      this.store.dispatch(
        getPageProfiles({
          pageNum: res?.data?.length <= PROFILE_PAGE_SIZE_DEFAULT ? PAGE_NUM_DEFAULT : res?.page + 1,
          pageSize: PAGE_SIZE_DEFAULT,
          searchText: this.profileSearchText.value
        })
      );
    });
  }

  showVerifiedBadge(profile: any): boolean {
    return profile?.id === this.userInfo?.id ? profile?.is_verified_user : profile?.is_verified;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
