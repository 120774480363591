import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AnalyticsService } from '@app/core/services/analytics.service';
import { CommentsService } from '@app/core/services/comments.service';
import { ReportService } from '@app/core/services/report.service';
import { ToastMessageService } from '@app/core/services/toast-message.service';
import { TranslationService } from '@app/core/services/translation.service';
import { ADS_ACTION } from '@app/lib/api/client/api.client.constant';
import { Comment } from '@app/lib/api/comments/api.comments.model';
import { selectReportCategories } from '@app/modules/main/states/report/report.selectors';
import { OBJECT_TYPE, OWNER_TYPE, TOAST_MESSAGE_SEVERITY_LEVELS } from '@app/shared/constant';
import { ENTITY_TYPE, Post } from '@app/shared/models/post';
import { UserInfo } from '@app/shared/models/user';
import { environment } from '@env/environment';
import { Store, select } from '@ngrx/store';

@Component({
  selector: 'comment-tree',
  templateUrl: './comment-tree.component.html',
  styleUrls: ['./comment-tree.component.scss']
})
export class CommentTreeComponent implements OnInit {
  @Input() comment: Comment;
  @Input() post: Post;
  @Input() loginUser: UserInfo;
  @Input() postOwnerId: string;
  @Input() groupId: string;
  @Input() isWidthFull = false;
  @Input() isPostView = false;
  @Input() isStory = false;
  @Input() storyId: string;
  @Input() hideReply = false;
  @Input() showSwitchProfileOption = false;
  @Input() selectedProfile: any;
  @Input() pageReactionInfo = [];
  @Output() increaseTotal = new EventEmitter();
  @Output() deletedCommentId = new EventEmitter();
  @Output() createdComment = new EventEmitter();

  reportCategories$ = this.store.pipe(select(selectReportCategories));

  totalComment = 0;
  childrenComment: Comment[] = [];
  isShowCommentForm = false;
  isFirstExpand = true;
  pageNum = 0;
  replyTarget: { id: string; full_name: string };
  // report variables
  commentId: string;
  isShowReport: boolean = false;
  baseUrl = environment.SOCIAL_APP_URL;
  isGettingCommentReplies = false;
  isLoadingReport = false;
  isLoadingComments = false;

  constructor(
    private commentsService: CommentsService,
    private reportService: ReportService,
    private toastMessageService: ToastMessageService,
    private translateService: TranslationService,
    private analyticsService: AnalyticsService,
    private cdRef: ChangeDetectorRef,
    private store: Store
  ) {}

  ngOnInit(): void {
    if (this.comment.total_replies > 0) {
      this.isLoadingComments = true;
      const ownerId = this.isSelectedPageProfile() ? this.selectedProfile?.page_id : '';
      // Retrieve the list of replies for the displayed comment corresponding to the selected page profile.
      this.commentsService.getCommentsByParentId(this.comment.id, this.pageNum, 3, ownerId).subscribe(res => {
        if (res) {
          this.totalComment = res.totalElement;
          this.childrenComment = res.data.filter(item => item.created_by || item?.page_object);
          this.isLoadingComments = false;
        }
      });
    }
  }
  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  addComment(data: any) {
    let param = {
      object_id: this.comment.object_id,
      object_type: this.isStory ? ENTITY_TYPE.story : this.isPostView ? ENTITY_TYPE.media : ENTITY_TYPE.post,
      parent_id: this.comment.id,
      content: data.content,
      message_ranges: data.messageRanges,
      medias: data.medias,
      preview_data: data.preview_data,
      owner_id: this.loginUser?.id,
      owner_type: OWNER_TYPE.user
    };

    if (this.isSelectedPageProfile()) {
      param = { ...param, owner_id: this.selectedProfile?.page_id, owner_type: OWNER_TYPE.page };
    }
    // Reply một comment có sẵn.
    this.commentsService.postComment(param).subscribe(res => {
      if (res) {
        this.post && this.analyticsService.pushPostAction(this.post, ADS_ACTION.comment);
        this.createdComment.emit();
        this.increaseTotal.emit(this.comment.id);
        this.childrenComment.push(res);
      }
    });
  }

  showCommentForm(event: { id: string; full_name: string }) {
    if (event) {
      this.replyTarget = event;
    }
    this.isShowCommentForm = true;
  }

  expandReplies() {
    this.isGettingCommentReplies = true;
    this.commentsService.getCommentsByParentId(this.comment.id, this.pageNum, 10).subscribe(res => {
      if (res) {
        this.isGettingCommentReplies = false;
      }
      if (this.isFirstExpand) {
        this.childrenComment = [
          ...this.childrenComment,
          ...res.data.slice(this.childrenComment.length).filter(item => item.created_by || item?.page_object)
        ];
        this.isFirstExpand = false;
      } else {
        this.childrenComment = [
          ...this.childrenComment,
          ...res.data.filter(item => item.created_by || item?.page_object)
        ];
      }
      this.pageNum += 1;
    });
  }

  deleteComment(id: string) {
    if (this.comment.id !== id) {
      let indexToRemove = this.childrenComment.findIndex(item => {
        return item.id === id;
      });
      if (indexToRemove !== -1) {
        this.childrenComment.splice(indexToRemove, 1);
        this.comment.total_replies -= 1;
      }
    }
    this.deletedCommentId.emit(id);
  }

  handleShowReportPopup(commentId: string) {
    this.commentId = commentId;
    this.isShowReport = true;
  }

  handleCommentReport(event: any) {
    let param = {
      object_id: this.commentId,
      object_type: OBJECT_TYPE.comment,
      category_type: event.category_type,
      report_reason: event.report_reason,
      object_parent_id: this.comment?.created_by?.id,
      object_parent_type: 'USER',
      owner_id: this.comment?.created_by?.id,
      key_search: this.comment?.created_by?.username,
      url_detail: this.isStory ? `${this.baseUrl}?storyId=${this.storyId}&comment-id=${this.commentId}&admin=true` : '',
      city_code: this.comment?.created_by?.city_code
    };

    if (this.comment?.owner_type === OWNER_TYPE.page) {
      param = {
        ...param,
        object_parent_id: this.comment?.page_object?.page_id,
        object_parent_type: 'FANPAGE',
        owner_id: this.comment?.page_object?.page_id,
        key_search: this.comment?.page_object?.page_name,
        city_code: ''
      };
    }

    this.reportService.createReportPost(param).subscribe(res => {
      if (res.success) {
        this.isShowReport = false;
        this.toastMessageService.addToastMessage(
          TOAST_MESSAGE_SEVERITY_LEVELS.success,
          this.translateService.getTranslation('ERROR.COMMENT.REPORT_SUCCESSFULLY')
        );
        this.handleHideComment(this.commentId, true);
      } else {
        this.toastMessageService.addToastMessage(
          TOAST_MESSAGE_SEVERITY_LEVELS.error,
          this.translateService.getTranslation('ERROR.COMMENT.REPORT_FAILED')
        );
      }
      this.isLoadingReport = false;
    });
  }

  getReportTarget(): string {
    return this.translateService.getTranslation('ENTITY.COMMENT').toLowerCase();
  }

  handleHideComment(commentId: string, isReport = false) {
    let arrHideCommentId = localStorage.getItem('arrHideCommentId');
    let newArrHideCommentId = JSON.parse(arrHideCommentId || '[]');
    newArrHideCommentId.push(commentId);
    localStorage.setItem('arrHideCommentId', JSON.stringify(newArrHideCommentId));
    this.commentId = '';
    if (!isReport) {
      this.toastMessageService.addToastMessage(
        TOAST_MESSAGE_SEVERITY_LEVELS.success,
        this.translateService.getTranslation('ERROR.COMMENT.HIDE_SUCCESSFULLY')
      );
    }
  }

  isHideComment(commentId: string): boolean {
    let arrHideCommentId = localStorage.getItem('arrHideCommentId');
    return JSON.parse(arrHideCommentId || '[]').includes(commentId);
  }

  isSelectedPageProfile(): boolean {
    // Check if the selected profile is a page profile.
    return !!this.selectedProfile?.page_id;
  }
}
