import { HttpClient } from '@angular/common/http';
import { MyReaction, ReactionInfo } from '@app/shared/models/post';
import { environment } from '@env/environment';
import { Observable, catchError, map, timeout } from 'rxjs';
import { ApiClientConfig } from '../api-client.config';
import {
  MyReactionsByEntityIdResponse,
  MyReactionsResponse,
  ReactionArrayResponse,
  ReactionResponse,
  Viewer,
  ViewerResponse
} from './api.reactions.model';

export class ReactionsApi {
  private apiUrl: string = environment.baseURL;
  REACTIONS = '/reaction/reactions';
  constructor(
    public readonly httpClient: HttpClient,
    public config: ApiClientConfig
  ) {}

  getMyReactions(): Observable<MyReaction[]> {
    return this.httpClient.get<MyReactionsResponse>(`${this.apiUrl}/reaction/views/my-views`).pipe(
      timeout(this.config.responseTimeout),
      map((res: MyReactionsResponse) => {
        return res.data;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getMyReactionsByEntityId(entityId: string): Observable<MyReaction> {
    return this.httpClient
      .get<MyReactionsByEntityIdResponse>(`${this.apiUrl}${this.REACTIONS}/${entityId}/my-reaction`)
      .pipe(
        timeout(this.config.responseTimeout),
        map((res: MyReactionsByEntityIdResponse) => {
          return res.data;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getReactionsByEntityId(
    entityId: string,
    reactionType: string,
    pageNum: number,
    pageSize: number
  ): Observable<ReactionInfo[]> {
    let url = `${this.REACTIONS}/${entityId}?pageNum=${pageNum}&pageSize=${pageSize}`;
    if (reactionType !== '') {
      url += `&reactionType=${reactionType}`;
    }
    return this.httpClient.get<ReactionArrayResponse>(`${this.apiUrl}${url}`).pipe(
      timeout(this.config.responseTimeout),
      map((res: ReactionArrayResponse) => {
        return res.data;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  postReactions(body: any): Observable<ReactionInfo> {
    return this.httpClient.post<ReactionResponse>(`${this.apiUrl}${this.REACTIONS}`, body).pipe(
      timeout(this.config.responseTimeout),
      map((res: ReactionResponse) => {
        return res.data;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  deleteReactions(reactionId: string) {
    return this.httpClient.delete<ReactionResponse>(`${this.apiUrl}${this.REACTIONS}/${reactionId}`).pipe(
      catchError(error => {
        throw error.error;
      })
    );
  }

  getViewer(storyId: string): Observable<Viewer[]> {
    return this.httpClient.get<ViewerResponse>(`${this.apiUrl}/reaction/views/${storyId}`).pipe(
      timeout(this.config.responseTimeout),
      map((res: ViewerResponse) => {
        return res.data;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getPageReaction(pageId: string): Observable<any> {
    return this.httpClient.get<ReactionResponse>(`${this.apiUrl}${this.REACTIONS}/${pageId}/page-reactions`).pipe(
      timeout(this.config.responseTimeout),
      map((res: any) => {
        return res;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }
}
