import { Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FollowerService } from '@app/core/services/follower.service';
import { ReactionsService } from '@app/core/services/reactions.service';
import { isCurrentUserFriendLimitReached } from '@app/modules/main/states/users/users.selectors';
import { OWNER_TYPE, PAGE_NUM_DEFAULT, PAGE_SIZE_DEFAULT } from '@app/shared/constant';
import { EmojiList, Post, ReactionType } from '@app/shared/models/post';
import { UserInfo } from '@app/shared/models/user';
import { Store } from '@ngrx/store';
import { includes, remove } from 'lodash-es';
import { MenuItem } from 'primeng/api';

interface ListReaction {
  reactionType: string;
  pageNum: number;
  pageSize: number;
}

@Component({
  selector: 'dialog-list-reaction',
  templateUrl: './dialog-list-reaction.component.html',
  styleUrls: ['./dialog-list-reaction.component.scss']
})
export class DialogListReactionComponent {
  @Input() visible: boolean;
  @Input() itemsTabMenu: MenuItem[] = [];
  @Input() activeItemTabMenu: MenuItem;
  @Input() userInfo: UserInfo;
  @Input() data: Post;
  @Input() selectedEmoji: any;
  @Input() selectedProfile: any;
  @Input() pageBloomFilter: any;
  @Output() visibleChange = new EventEmitter<boolean>();
  @ViewChild('scrollContainer') scrollContainer: ElementRef;

  isFriendLimit$ = this.store.select(isCurrentUserFriendLimitReached);

  reactionUser: {
    [key: string]: {
      id: string;
      full_name: string;
      reaction_type?: string;
      created_by?: any;
      isFriend?: boolean;
      owner_type?: string;
      loading?: boolean;
    }[];
  };

  listReaction: ListReaction[] = [
    { reactionType: ReactionType.all, pageNum: PAGE_NUM_DEFAULT, pageSize: PAGE_SIZE_DEFAULT },
    { reactionType: ReactionType.love, pageNum: PAGE_NUM_DEFAULT, pageSize: PAGE_SIZE_DEFAULT },
    { reactionType: ReactionType.haha, pageNum: PAGE_NUM_DEFAULT, pageSize: PAGE_SIZE_DEFAULT },
    { reactionType: ReactionType.wow, pageNum: PAGE_NUM_DEFAULT, pageSize: PAGE_SIZE_DEFAULT },
    { reactionType: ReactionType.sad, pageNum: PAGE_NUM_DEFAULT, pageSize: PAGE_SIZE_DEFAULT },
    { reactionType: ReactionType.angry, pageNum: PAGE_NUM_DEFAULT, pageSize: PAGE_SIZE_DEFAULT }
  ];

  followedPageIds: any = [];

  constructor(
    private reactionsService: ReactionsService,
    private store: Store,
    private followerService: FollowerService
  ) {}

  get idToCompare(): string {
    return this.isSelectedPageProfile() ? this.selectedProfile?.page_id : this.userInfo.id;
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedEmoji'] && this.reactionUser) {
      // Check if the selected profile has reacted to the post
      const isExitingUser = this.reactionUser[ReactionType.all].some(user => user.id === this.idToCompare);
      //Add
      if (changes['selectedEmoji'].currentValue && changes['selectedEmoji'].previousValue === null) {
        // This condition prevents adding a new reaction when switching from a profile
        // without a reaction to one that already has a reaction.
        if (isExitingUser) {
          return;
        }
        const selectedEmoji = changes['selectedEmoji'].currentValue;
        let data = {
          id: this.userInfo.id,
          full_name: this.userInfo.full_name,
          reaction_type: selectedEmoji.type,
          created_by: this.userInfo,
          owner_type: OWNER_TYPE.user
        };
        // Update info if the selected profile is a page profile.
        if (this.isSelectedPageProfile()) {
          data = {
            id: this.selectedProfile?.page_id,
            full_name: this.selectedProfile?.page_name ?? '',
            reaction_type: selectedEmoji.type,
            created_by: this.selectedProfile,
            owner_type: OWNER_TYPE.page
          };
        }
        if (this.reactionUser[ReactionType.all]) this.reactionUser[ReactionType.all].unshift(data);
        if (this.reactionUser[selectedEmoji.type]) this.reactionUser[selectedEmoji.type].unshift(data);
        return;
      }
      //Remove
      if (changes['selectedEmoji'].currentValue === null && changes['selectedEmoji'].previousValue && isExitingUser) {
        const selectedEmoji = changes['selectedEmoji'].previousValue;
        if (this.reactionUser[ReactionType.all]) {
          this.reactionUser[ReactionType.all] = this.reactionUser[ReactionType.all].filter(
            user => user.id !== this.idToCompare
          );
        }
        if (this.reactionUser[selectedEmoji.type]) {
          this.reactionUser[selectedEmoji.type] = this.reactionUser[selectedEmoji.type].filter(
            user => user.id !== this.idToCompare
          );
        }
        return;
      }
      //Edit
      if (
        changes['selectedEmoji'].currentValue &&
        changes['selectedEmoji'].previousValue &&
        changes['selectedEmoji'].currentValue !== changes['selectedEmoji'].previousValue &&
        isExitingUser
      ) {
        // This condition prevents editing an existing reaction when switching
        // between two profiles with different reactions.
        if (changes['selectedProfile']?.currentValue?.id !== changes['selectedProfile']?.previousValue?.id) {
          return;
        }
        const selectedEmojiCurrent = changes['selectedEmoji'].currentValue;
        const selectedEmojiPrevious = changes['selectedEmoji'].previousValue;
        if (this.reactionUser[ReactionType.all]) {
          this.reactionUser[ReactionType.all] = this.reactionUser[ReactionType.all].map(user => {
            if (user.id === this.idToCompare) {
              return { ...user, reaction_type: selectedEmojiCurrent.type };
            }
            return user;
          });
        }
        if (this.reactionUser[selectedEmojiCurrent.type]) {
          let data = {
            id: this.userInfo.id,
            full_name: this.userInfo.full_name,
            reaction_type: selectedEmojiCurrent.type,
            created_by: this.userInfo,
            owner_type: OWNER_TYPE.user
          };
          // Update info if the selected profile is a page profile.
          if (this.isSelectedPageProfile()) {
            data = {
              id: this.selectedProfile?.page_id,
              full_name: this.selectedProfile?.page_name ?? '',
              reaction_type: selectedEmojiCurrent.type,
              created_by: this.selectedProfile,
              owner_type: OWNER_TYPE.page
            };
          }
          this.reactionUser[selectedEmojiCurrent.type].unshift(data);
        }
        if (this.reactionUser[selectedEmojiPrevious.type]) {
          this.reactionUser[selectedEmojiPrevious.type] = this.reactionUser[selectedEmojiPrevious.type].filter(
            user => user.id !== this.idToCompare
          );
        }
      }
    }
  }

  onActiveItemChange(event: MenuItem) {
    this.activeItemTabMenu = event;
    let pageSize = this.listReaction.find(s => event.id === s.reactionType)?.pageSize;
    if (pageSize === PAGE_SIZE_DEFAULT && event.id !== ReactionType.all) {
      this.getReactionsByEntityId(this.activeItemTabMenu.id);
    }
  }

  filterItemToShow(): MenuItem[] {
    return this.itemsTabMenu.filter(item => parseInt(item.label || '0') > 0);
  }

  getIconReaction(type: string) {
    return EmojiList.find(el => el.type === type)?.icon;
  }

  getReactionsByEntityId(type: string = ReactionType.all) {
    let pageNum = this.listReaction.find(s => type === s.reactionType)?.pageNum;
    let pageSize = this.listReaction.find(s => type === s.reactionType)?.pageSize;
    this.reactionsService
      .getReactionsByEntityId(this.data.id, type === ReactionType.all ? '' : type, pageNum, pageSize)
      .subscribe(reactions => {
        const data = reactions.map(el => {
          if (el?.owner_type === OWNER_TYPE.page) {
            // Update the reaction user list based on the owner_object if it belongs to a fanpage reaction.
            return {
              id: el?.owner_object?.page_id ?? '',
              full_name: el?.owner_object?.page_name ?? '',
              reaction_type: el.reaction_type,
              created_by: el.owner_object,
              owner_type: OWNER_TYPE.page
            };
          }
          return {
            id: el.created_by.id,
            full_name: el.created_by.full_name,
            reaction_type: el.reaction_type,
            created_by: el.created_by,
            isFriend: el.created_by.is_friend,
            owner_type: OWNER_TYPE.user
          };
        });
        const reactionType = type === '' ? ReactionType.all : type;

        if (this.reactionUser && this.reactionUser.hasOwnProperty(reactionType)) {
          this.reactionUser = {
            ...this.reactionUser,
            [reactionType]: (this.reactionUser[reactionType] || []).concat(data)
          };
        } else {
          this.reactionUser = { ...this.reactionUser, [type]: data };
        }

        this.listReaction.find(s => {
          if (type === s.reactionType) {
            s.pageSize = reactions.length;
          }
        });
      });
  }

  loadMoreReactions(type: string) {
    const container = this.scrollContainer.nativeElement;
    if (container) {
      const scrollHeight = container.scrollHeight;
      const offsetHeight = container.offsetHeight;
      const scrollTop = container.scrollTop;
      let pageSize = this.listReaction.find(s => type === s.reactionType)?.pageSize;

      if (scrollTop + offsetHeight >= scrollHeight - 10) {
        if (pageSize === PAGE_SIZE_DEFAULT) {
          this.listReaction.find(s => {
            if (type === s.reactionType) {
              s.pageNum++;
            }
          });
          this.getReactionsByEntityId(this.activeItemTabMenu.id);
        }
      }
    }
  }

  checkExistKey(key: string) {
    return this.reactionUser?.hasOwnProperty(key);
  }

  closePopupReaction() {
    this.visibleChange.emit(false);
  }

  showDialog() {
    if (!this.reactionUser) {
      this.getReactionsByEntityId();
    }
  }

  handleUpdateStatusFriend(FriendUpdated: any, keyObject: string, index: number) {
    const arrUpdate = [...this.reactionUser[keyObject]];
    arrUpdate[index].created_by = FriendUpdated;
  }

  isSelectedPageProfile(): boolean {
    // Check if the selected profile is a page profile.
    return !!this.selectedProfile?.page_id;
  }

  hasFollowedFanpage(pageInfo: any): boolean {
    if (!this.pageBloomFilter) return false;

    const pageId = pageInfo?.created_by?.page_id;
    // The bloom filter does not update immediately after the follow state changes,
    // so it will only be used during the first attempt.
    if (!pageInfo['has_first_check_flag']) {
      pageInfo['has_first_check_flag'] = true;
      const { BloomFilter } = require('soctrip-algorithm/dist/api.js');
      const bloomFilter = BloomFilter.load(this.pageBloomFilter);
      if (bloomFilter.has(pageId)) {
        this.followedPageIds.push(pageId);
        return true;
      }
      return false;
    }

    return this.followedPageIds.includes(pageId);
  }

  updatePageFollowState(pageInfo: any, isFollow: boolean): void {
    // Update the loading state individually for each button.
    pageInfo['loading'] = true;
    const pageId = pageInfo?.created_by?.page_id;

    const action$ = isFollow
      ? this.followerService.followFanpage('page', pageId)
      : this.followerService.unFollowFanpage('page', pageId);

    action$.subscribe({
      next: () => {
        pageInfo['loading'] = false;
        isFollow
          ? includes(this.followedPageIds, pageId) || this.followedPageIds.push(pageId)
          : remove(this.followedPageIds, followedId => followedId === pageId);
      },
      error: () => (pageInfo['loading'] = false)
    });
  }
}
