import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserProfileState } from './user-profiles.state';

export const userProfilesState = createFeatureSelector<UserProfileState>('profiles');

export const selectUserProfile = createSelector(userProfilesState, profilesState => profilesState.userProfile);

export const selectLoadingUserProfile = createSelector(
  userProfilesState,
  productState => productState.selectLoadingUserProfile
);

export const selectStatusEditUserProfileSuccess = createSelector(
  userProfilesState,
  profilesState => profilesState.editUserSuccess
);

export const selectHobbies = createSelector(userProfilesState, profilesState => profilesState.hobbies);

export const selectLocations = createSelector(userProfilesState, profilesState => profilesState.locations);

export const numberSentFriendAndReceivedFriend = createSelector(
  userProfilesState,
  profilesState =>
    profilesState.userProfile.user_object.number_of_sent_friend_invitation +
    profilesState.userProfile.user_object.number_of_received_friend_invitation
);

export const selectIsError = createSelector(userProfilesState, profilesState => profilesState.isError);

export const selectIsUpdatingProfile = createSelector(
  userProfilesState,
  profilesState => profilesState.isUpdatingProfile
);
