import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiClient } from '@app/lib/api/api-client';
import {
  AddPixelParams,
  GeneratePixelCodeResponse,
  GetEventParams,
  GetEventResponse,
  GetOverviewParams,
  GetOverviewResponse,
  GetPixelParams,
  GetPixelResponse,
  GetPixelResponsePaging,
  GetTestEvent,
  GetTestEventsResponse
} from '@app/lib/api/pixel/api.pixel.models';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PixelService {
  private api: ApiClient;

  constructor(private httpClient: HttpClient) {
    this.api = new ApiClient(this.httpClient, {
      responseTimeout: environment.API_TIMEOUT
    });
  }

  getPixels(params: GetPixelParams): Observable<GetPixelResponsePaging> {
    return this.api.pixel.getPixels(params);
  }

  getPixelDetail(pixelCode: string): Observable<GetPixelResponse> {
    return this.api.pixel.getPixelDetail(pixelCode);
  }

  createPixel(body: AddPixelParams): Observable<any> {
    return this.api.pixel.createPixel(body);
  }

  editPixel(body: AddPixelParams): Observable<any> {
    return this.api.pixel.editPixel(body);
  }

  deletePixel(pixelCode: string): Observable<any> {
    return this.api.pixel.deletePixel(pixelCode);
  }

  getPixelCode(): Observable<GeneratePixelCodeResponse> {
    return this.api.pixel.getPixelCode();
  }

  getEventStatistics(params: GetEventParams): Observable<GetEventResponse> {
    return this.api.pixel.getEventStatistics(params);
  }

  getOverview(params: GetOverviewParams): Observable<GetOverviewResponse> {
    return this.api.pixel.getOverview(params);
  }

  getTestEvent(params: GetTestEvent): Observable<GetTestEventsResponse> {
    return this.api.pixel.getTestEvent(params);
  }

  deleteTestEvent(pixel_code: string): Observable<any> {
    return this.api.pixel.deleteTestEvent(pixel_code);
  }
  downLoadPixel(textContent: string){
    const blob = new Blob([textContent], { type: 'text/plain' });
    const fileURL = URL.createObjectURL(blob);
    let link = document.createElement('a');
    link.href = fileURL;
    link.download = 'BaseCode.txt';
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(fileURL);
    document.body.removeChild(link);
  }

  addHttpToUrl(url: string): string {
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      return `http://${url}`;
    }
    return url;
  }

  openWebsiteSetupPixel(url: string): void {
    window.open(`${this.addHttpToUrl(url)}?mode=setup`, '_blank');
  }

  updatePxId(userProfile: any) {
    const ifr = document.createElement('iframe');
    ifr.classList.add('hidden');
    document.body.appendChild(ifr);
    ifr.src = `${environment.CONNECT_URL}/pixel-app-connect`;
    const connect = () => {
      const result = {
        type: 'updatePxId',
        profile: userProfile
      };
      ifr.contentWindow?.postMessage(result, { targetOrigin: environment.CONNECT_URL });
    };
    ifr.onload = connect;
  }

  openWebsiteTestPixel(url: string): void {
    window.open(`${this.addHttpToUrl(url)}?mode=test`, '_blank');
  }
}
