import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiClient } from "@app/lib/api/api-client";
import { environment } from "@env/environment";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class LoyaltyService {
    private api: ApiClient;
  
    constructor(private http: HttpClient) {
      this.api = new ApiClient(this.http, { responseTimeout: environment.API_TIMEOUT });
    }

    getPrimaryRingColor(loyalty_id: string | undefined){
      const listRank = localStorage.getItem('loyaltyId');
      if(!loyalty_id){
        return;
      }
      let loyaltyId = [];
      if (listRank) {
        loyaltyId = JSON.parse(listRank);
      }
      const loyaltyItem = loyaltyId.find((item: any) => item.id === loyalty_id);
      return loyaltyItem;
    }

    getProfileRank():Observable<any[]>{
        return this.api.loyaltyAPI.getProfileRank();
    }

    getListRank():Observable<any[]>{
        return this.api.loyaltyAPI.getListRank();
    }
}