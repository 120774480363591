import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { AnalyticsService } from '@app/core/services/analytics.service';
import { MediaService } from '@app/core/services/media.service';
import { ADS_CATEGORY, VIDEO_INTERACTION_TYPES } from '@app/lib/api/client/api.client.constant';
import { TypeCollectVideo } from '@app/lib/api/client/api.client.model';
import { FILE_EXTENSION, LAYOUT_TYPE, MEDIA_TYPE } from '@app/shared/constant';
import { FILE_TYPE_URL, Media, checkUrl } from '@app/shared/models/post';

export interface EventImg {
  i: number;
  media: string;
}

@Component({
  selector: 'media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss']
})
export class MediaComponent implements OnChanges, AfterViewInit, OnInit {
  @Input() layoutType: string;
  @Input() mediaList: Media[] = [];
  @Input() isPreview = false;
  @Input() isSmallPreview = false;
  @Input() isSharedPost = false;
  @Input() isAutoPlay = false;
  @Input() isScale = false;
  @Output() clickImage = new EventEmitter();
  @Output() scaleVideo = new EventEmitter();
  @Output() unScaleVideo = new EventEmitter();

  isLandscapeImg = true;
  isClickedPlay = false;
  LAYOUT_TYPE = LAYOUT_TYPE;
  MEDIA_TYPE = MEDIA_TYPE;
  FILE_EXTENSION = FILE_EXTENSION;
  FILE_TYPE_URL = FILE_TYPE_URL;
  isLoading = false;
  isShowThumbnailVideo = true;

  constructor(
    private cdRef: ChangeDetectorRef,
    private analyticsService: AnalyticsService,
    public mediaService: MediaService
  ) { }

  ngOnInit(): void {
    this.mediaList = this.mediaList.map(media => ({ ...media, is_loading: false }));
    this.mediaService.unScaleVideo.subscribe(item => {
      if (item && this.isScale && this.mediaList[0].original === item) {
        this.isScale = false;
      }
    });
  }

  ngAfterViewInit(): void {
    this.cdRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['mediaList']) {
      this.setLayoutTypeWhenNull();
    }
  }

  async setLayoutTypeWhenNull() {
    if (this.mediaList && this.mediaList.length > 0) {
      this.isLoading = true;
      await this.checkLandscapeImg(this.mediaList[0]).then((res: any) => {
        this.isLandscapeImg = res;
        this.isLoading = false;
      });
      this.layoutType =
        this.mediaList.length === 4
          ? LAYOUT_TYPE.central
          : this.isLandscapeImg
            ? LAYOUT_TYPE.vertical
            : LAYOUT_TYPE.horizontal;
    }
  }

  checkPlayClicked(e: boolean) {
    this.isClickedPlay = e;
    this.cdRef.detectChanges();
  }

  onClickImage(i: number) {
    const params: EventImg = {
      i: i,
      media: this.mediaList[0].thumbnail
    };
    this.clickImage.emit(params);
  }

  clickVideo(i: number) {
    if (!this.isClickedPlay) {
      if (this.isScale) {
        this.unScaleVideo.emit();
        return;
      }
      this.onClickImage(i);
    } else {
      this.isClickedPlay = false;
    }
  }

  validUrl(item: Media, style = FILE_TYPE_URL.thumbnail) {
    return checkUrl(item.original, style);
  }

  checkLandscapeImg(item: Media) {
    return new Promise(async resolve => {
      const img = new Image();
      img.src = this.handleGenerateUrl(item, FILE_TYPE_URL.thumbnail, this.FILE_EXTENSION.image);
      img.onload = () => resolve(img.width > img.height);
      img.onerror = () => resolve(false);
    });
  }

  handleVideoDimension(video: HTMLVideoElement, resolve: (result: boolean) => void) {
    const { videoWidth: width, videoHeight: height } = video;
    if (width > 0 && height > 0) {
      resolve(width > height);
    } else {
      resolve(false);
    }
  }

  handleGenerateUrl(item: Media, style = FILE_TYPE_URL.thumbnail, fileExtension: string) {
    if (this.isPreview) {
      return item.upload ? item.original : this.validUrl(item, style) + fileExtension;
    }
    return this.validUrl(item, style);
  }

  handleImageError(index: number) {
    this.mediaList[index].is_loading = true;
  }

  handleImageLoad(index: number) {
    this.mediaList[index].is_loading = false;
  }

  handleCollectVideo(event: TypeCollectVideo) {
    if (event && event.type && event.vf && Object.values(VIDEO_INTERACTION_TYPES).includes(event.type)) {
      this.analyticsService.pushToBuffer(event.vf, ADS_CATEGORY.video, event.type);
    }
  }

  handleScaleVideo(e: any) {
    this.scaleVideo.emit(e);
  }

  videoThumbnail(urlString: string) {
    return checkUrl(urlString, FILE_TYPE_URL.thumbnail).replace('.webm', '.webp');
  }
}
