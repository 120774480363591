import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable, catchError, map, timeout } from 'rxjs';
import { ApiClientConfig } from '../api-client.config';
import {
  AddPixelParams,
  GeneratePixelCodeResponse,
  GetEventParams,
  GetEventResponse,
  GetOverviewParams,
  GetOverviewResponse,
  GetPixelParams,
  GetPixelResponse,
  GetTestEvent,
  GetPixelResponsePaging,
  GetTestEventsResponse
} from './api.pixel.models';

export class PixelApi {
  private apiUrl: string = environment.baseURL;

  PIXEL = '/pixel';

  constructor(
    public readonly http: HttpClient,
    public config: ApiClientConfig
  ) {}

  getPixels(params: GetPixelParams): Observable<GetPixelResponsePaging> {
    return this.http
      .get<GetPixelResponsePaging>(`${this.apiUrl}${this.PIXEL}/search`, {
        params: { ...params }
      })
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getPixelDetail(pixelCode: string): Observable<GetPixelResponse> {
    return this.http.get<GetPixelResponse>(`${this.apiUrl}${this.PIXEL}/${pixelCode}`).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  createPixel(body: AddPixelParams): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}${this.PIXEL}${this.PIXEL}`, body).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  editPixel(body: AddPixelParams): Observable<any> {
    return this.http.put<any>(`${this.apiUrl}${this.PIXEL}${this.PIXEL}`, body).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  deletePixel(pixelCode: string): Observable<any> {
    return this.http.delete<any>(`${this.apiUrl}${this.PIXEL}/${pixelCode}`).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getPixelCode(): Observable<GeneratePixelCodeResponse> {
    return this.http.get<GeneratePixelCodeResponse>(`${this.apiUrl}${this.PIXEL}/generate-pixel-code`).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getEventStatistics(params: GetEventParams): Observable<GetEventResponse> {
    return this.http
      .get<GetEventResponse>(`${this.apiUrl}${this.PIXEL}/${params.pixelCode}/statistics`, {
        params: {
          startTime: params.startTime,
          endTime: params.endTime
        }
      })
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getTestEvent(params: GetTestEvent): Observable<GetTestEventsResponse> {
    return this.http
      .get<GetTestEventsResponse>(`${this.apiUrl}${this.PIXEL}/events/pixel/${params.pixelCode}/tracking?pageNum=${params.pageNum}&pageSize=10`, {
        params: {
          pixelCode: params.pixelCode,
          page: params.pageSize,
          pageNum: params.pageNum
        }
      })
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  deleteTestEvent(pixel_code: string): Observable<GetTestEventsResponse> {
    return this.http
      .delete<GetTestEventsResponse>(`${this.apiUrl}${this.PIXEL}/events/pixel/${pixel_code}/tracking`, {
        params: {
          pixelCode: pixel_code,
          isTest: true
        }
      })
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getOverview(params: GetOverviewParams): Observable<GetOverviewResponse> {
    return this.http
      .get<GetOverviewResponse>(`${this.apiUrl}${this.PIXEL}/${params.pixelCode}/overview`, {
        params: {
          startTime: params.startTime,
          endTime: params.endTime,
          amountToAdd: params.amountToAdd
        }
      })
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }
}
